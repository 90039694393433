import React, { useContext, useState, useCallback, useEffect } from 'react';
import { PreRegisterContext as Context } from '../../../../context/PreRegisterContext';
import { Container, Row } from './styles';
import Button from '../../../../components/Button';
import GoBackButton from '../../../../components/GoBackButton';
import Title from '../../../../components/Title';
import UploadBox from '../../../../components/UploadBox';
import ContainerPage from '../../../../components/ContainerPage';
import Modal from '../../../../components/Modal';
import ErrorMessage from '../../../../components/ErrorMessage';
import api from '../../../../services/api';
import arrowRight  from '../../../../assets/arrow-right.svg'

const BankStatement: React.FC = () => {
  const {
    setBankStatementDoc,
    cpf,
    nextStep,
    previousStep,
    bankStatementName,
    setBankStatementName,
    bankStatementType,
    setBankStatementType,
  } = useContext(Context) || {};
  const [loading, setLoading] = useState<number>(0);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const removeFile = useCallback(() => {
    localStorage.removeItem('bankStatementName');
    localStorage.removeItem('bankStatementType');
    setBankStatementName();
    setBankStatementType();
    setBankStatementDoc([]);
  }, [setBankStatementDoc, setBankStatementName, setBankStatementType]);

  const getData = useCallback(
    async (file) => {
      if (
        (file.type === 'image/png' && file.size < 10000000) ||
        (file.type === 'image/jpg' && file.size < 10000000) ||
        (file.type === 'image/jpeg' && file.size < 10000000) ||
        (file.type === 'application/pdf' && file.size < 10000000)
      ) {
        setLoading(0);
      } else {
        setLoading(0);
        setErrorMsg(
          'Por favor, confira se seu arquivo é do tipo .jpeg, .jpg, .png, .pdf e se ele possui menos de 10MB de tamanho.',
        );
        return;
      }

      setLoading(1);
      const formData = new FormData();
      formData.append('file_to_upload', file);
      formData.append('document_type', 'EMPRESA_EXTRATO');
      formData.append('document', cpf);

      try {
        const res = await api.post('register/upload-documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setBankStatementName(res.data.fileName);
        setBankStatementType(res.data.fileType);
        setBankStatementDoc([res.data.path]);
        setErrorMsg('')
        setLoading(0);
      } catch (error) {
        setLoading(0);        
        setErrorMsg(error.response.data.errors.file_to_upload);
      }
    },
    [cpf, setBankStatementDoc, setBankStatementName, setBankStatementType],
  );

  const validateBankStatement = useCallback(() => {
    if (bankStatementName) {
      nextStep();
    } else {
      setErrorMsg(
        'Precisamos do seu extrato bancário para completar o cadastro.',
      );
    }
  }, [bankStatementName, nextStep]);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const bankAccountStatement = (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      closeModal={toggleModal}
      headerLabel="Comprovante bancário"
    >
      <p>
        Pode ser um PRINT do app do banco onde consta o nome da empresa ou nome
        do empresário seguido do CPF/CNPJ, Banco, Agência e Conta.
      </p>
    </Modal>
  );

  const [ disableProgress, setDisableProgress ] = useState<boolean>(true);

  useEffect(() => {
    if(bankStatementName){
      setDisableProgress(false)
    }else{
      setDisableProgress(true)
    }
    
  }, [ setDisableProgress, bankStatementName ])

  return (
    <ContainerPage showLogo bankStatementBkg loading={loading}>
      {bankAccountStatement}
      <Container>
        <Title primary>Estamos quase lá!</Title>
        <p className="subtitle">Envie um comprovante de conta bancária</p>
        <p>
          Para finalizar, precisamos de um print do seu internet banking ou
          extrato da sua conta corrente, onde consta o nome da empresa ou nome
          do empresário seguido do CPF/CNPJ, Banco, Agência e Conta.
        </p>
        {/* <Row className="help-row">
          <Button
            primary
            primaryOutline
            onClick={toggleModal}
            className="help-btn"
            icon={helpIcon}
            data-for="help-btn"
            data-tip
          />
        </Row> */}
        <UploadBox
          wholeFile={getData}
          removeFile={removeFile}
          fileName={bankStatementName}
          fileType={bankStatementType}
        >
          <p>
            Arraste e solte o arquivo aqui ou clique para{' '}
            <span>fazer upload</span>
          </p>
        </UploadBox>
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        <Button
          primary
          type="button"
          className="btn-continue"
          textTransform
          onClick={validateBankStatement}
          disabled={disableProgress}
        >
          Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
        </Button>
        <Row>
          <GoBackButton className='goback-button' onClick={previousStep} />
          {/* <JumpStepLink>Pular esta etapa</JumpStepLink> */}
        </Row>
      </Container>
    </ContainerPage>
  );
};

export default BankStatement;
