/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useContext, useCallback, useEffect, useState } from 'react';
import { Container, Row } from './styles';
import TextInput from '../../SignupComponents/TextInput';
import SelectInput from '../../SignupComponents/SelectInput';
import Title from '../../../../components/Title';
import GoBackButton from '../../../../components/GoBackButton';
import Button from '../../../../components/Button';
import { PreRegisterContext as Context } from '../../../../context/PreRegisterContext';
import ContainerPage from '../../../../components/ContainerPage';
import api from '../../../../services/api';
import arrowRight  from '../../../../assets/arrow-right.svg'

interface BankInterface {
  name: string;
  code: string;
}

const BankInfo: React.FC = () => {
  const {
    bank,
    setBank,
    bankAgencyNumber,
    bankAgencyDigitNumber,
    setBankAgencyNumber,
    setBankAgencyDigitNumber,
    bankAccountNumber,
    setBankAccountNumber,
    bankAccountDigitNumber,
    setBankAccountDigitNumber,
    nextStep,
    previousStep,
  } = useContext(Context) || {};

  const [hasBank, setHasBank] = useState<string>('');
  const [hasBankAgency, setHasBankAgency] = useState<string>('');
  const [hasBankAccount, setHasBankAccount] = useState<string>('');
  const [bankList, setBankList] = useState([]);
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    async function getBanks() {
      const resBanks = await api.get('/register/get-banks');
      setBankList(resBanks.data.banks);

      bank && setBank(bank);
    }
    getBanks();
  }, [bank, options, setBank]);

  const list = bankList.map((s: BankInterface) => {
    return { value: s.code, label: s.name };
  });

  // const handleGoBack = useCallback(() => {
  //   isCompanyRegister === 'juridical person'
  //     ? previousStep()
  //     : setStep(step - 4);
  // }, [isCompanyRegister, previousStep, setStep, step]);

  const validateBankAccount = useCallback(() => {
    !bank || bank === 'Selecione um banco' ? setHasBank('Selecione um banco') : setHasBank('');
    !bankAgencyNumber ? setHasBankAgency('Digite o número da agência') : setHasBankAgency('');
    !bankAccountNumber ? setHasBankAccount('Digite o número da conta') : setHasBankAccount('');

    bank &&
      bank !== 'Selecione um banco' &&
      bankAgencyNumber &&
      bankAccountNumber &&
      nextStep();
  }, [bank, bankAgencyNumber, bankAccountNumber, nextStep]);

  const [ disableProgress, setDisableProgress ] = useState<boolean>(true);

  useEffect(() => {
    if(bank && bank !== 'Selecione um banco' &&  bankAgencyNumber && bankAccountNumber){
      setDisableProgress(false)
    }else{
      setDisableProgress(true)
    }
    
  }, [ setDisableProgress, bank, bankAgencyNumber, bankAccountNumber ])

  return (
    <ContainerPage showLogo bankInfoBkg>
      <Container>
        <Title primary>Precisamos dos dados da sua conta bancária:</Title>

        <Row>
          <SelectInput
            id="bank"
            name="bank"
            placeholder="Banco"
            value={list.find((v) => v.value === bank)}
            onChange={(e) => setBank(e.value)}
            error={hasBank}
            options={list}
          />

          {/*
          <SelectInput
            split
            id="account_type"
            name="account_type"
            placeholder="Tipo da conta"
            value={bankAccountType}
            onChange={(e) => setBankAccountType(e.currentTarget.value)}
          >
            <option key="CONTA_CORRENTE" value="CONTA_CORRENTE">
              Conta Corrente
            </option>
            <option key="CONTA_POUPANCA" value="CONTA_POUPANCA">
              Conta Poupança
            </option>
            <option key="CONTA_PAGAMENTO" value="CONTA_PAGAMENTO">
              Conta de Pagamento
            </option>
          </SelectInput> */}
        </Row>
        <Row>
          <TextInput
            split
            id="agency"
            name="bankAgencyNumber"
            mask=""
            type="text"
            placeholder="Agência"
            value={bankAgencyNumber}
            error={hasBankAgency}
            onChange={(e) => setBankAgencyNumber(e.currentTarget.value)}
          />
          <TextInput
            split
            id="agency-digit"
            name="bankAgencyDigitNumber"
            mask=""
            type="text"
            placeholder="Dígito"
            value={bankAgencyDigitNumber}
            onChange={(e) => setBankAgencyDigitNumber(e.currentTarget.value)}
          />
        </Row>
        <Row>
          <TextInput
            split
            id="account"
            name="bankAccountNumber"
            mask=""
            type="text"
            placeholder="Conta"
            value={bankAccountNumber}
            error={hasBankAccount}
            onChange={(e) => setBankAccountNumber(e.currentTarget.value)}
          />
          <TextInput
            split
            id="account-digit"
            name="bankAccountDigitNumber"
            mask=""
            type="text"
            placeholder="Dígito"
            value={bankAccountDigitNumber}
            onChange={(e) => setBankAccountDigitNumber(e.currentTarget.value)}
          />
        </Row>

        <Button
          primary
          type="button"
          className="btn-continue"
          textTransform
          onClick={validateBankAccount}
          disabled={disableProgress}
        >
          Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
        </Button>
        {/* <GoBackButton onClick={handleGoBack} /> */}
        <GoBackButton className='goback-button' onClick={() => previousStep()} />
      </Container>
    </ContainerPage>
  );
};

export default BankInfo;
