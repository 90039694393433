/* eslint-disable eqeqeq */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiX } from 'react-icons/fi';
import { Container, SideBarSticky } from './styles';
import cloudfoxLogo from '../../assets/logos/2021/svg/logo-secundary.svg';
import { menuList } from '../../data/menuList';

import Button from '../Button';
import { AccountsContext as Context } from '../../context/AccountsContext';

const Sidebar: React.FC = () => {
  const [isActive, setIsActive] = useState<number | null | undefined>(null);
  const { user, mobileMenuToggle, setMobileMenuToggle } = useContext(Context);
  const location = useLocation();

  const menuActive = useCallback((id) => {
    setIsActive(id);
  }, []);

  useEffect(() => {
    const path = location.pathname.replace(/(.*?\/.*?)\/.*/g, '$1');
    const number = menuList.find((x) => x.to === path);
    setIsActive(number?.id);
  }, [isActive, location]);

  return (
    <Container open={mobileMenuToggle}>
      <nav className="sidebar">
        <Link className="navbar-brand desktop-brand " to="/home">
          <img src={cloudfoxLogo} alt="" width={300}/>
        </Link>
        <Button
          transparent
          className="close-menu"
          onClick={() => setMobileMenuToggle(!mobileMenuToggle)}
        >
          <FiX size={24} />
        </Button>
        <SideBarSticky className="sidebar-sticky">
          <ul className="nav flex-column d-block">
            {menuList.map(
              (menu) =>
                (menu.role == 'all' ||
                  (user?.is_account_owner && menu.role == 'account-owner')) && (
                  <div
                    role="menuitem"
                    aria-hidden="true"
                    // tabIndex={0}
                    key={menu.id}
                    className={`nav-item ${
                      isActive === menu.id ? 'active' : ''
                    }`}
                    onClick={() => menuActive(menu.id)}
                  >
                    <Link
                      to={menu.to}
                      aria-expanded="false"
                      className="nav-link"
                    >
                      <img src={menu.icon} alt="" />
                      {menu.name}
                      {/* <span className="sr-only">(atual)</span> */}
                    </Link>
                  </div>
                ),
            )}
          </ul>
        </SideBarSticky>
      </nav>
    </Container>
  );
};

export default Sidebar;
