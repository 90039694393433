import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ContainerPage from '../../components/ContainerPage';
import TextInput from '../Signup/SignupComponents/TextInput';
import Title from '../../components/Title';
import Button from '../../components/Button';
import { Container } from './styles';
import api from '../../services/api';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';
import { AccountsContext as Context } from '../../context/AccountsContext';
import { login } from '../../services/auth.js';

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState<number>(0);
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { setUser } = useContext(Context);
  const history = useHistory();

  const [eightCharacters, setEightCharacters] = useState(false);
  const [oneLetter, setOneLetter] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);
  const token = useUrlSearchParams('token');
  const email = useUrlSearchParams('email');

  const hasEightChar = new RegExp('^(?=.{8,})');
  const hasOneLetter = new RegExp('^(?=.*[a-z])');
  const hasOneNumber = new RegExp('^(?=.*[0-9])');

  const passwordValidationCheck = useCallback(
    (e) => {
      const { value } = e.currentTarget;
      setPassword(value);
      hasOneLetter.test(value) ? setOneLetter(true) : setOneLetter(false);
      hasOneNumber.test(value) ? setOneNumber(true) : setOneNumber(false);
      hasEightChar.test(value)
        ? setEightCharacters(true)
        : setEightCharacters(false);
    },
    [hasEightChar, hasOneLetter, hasOneNumber, setPassword],
  );

  useEffect(() => {
    hasOneLetter.test(password) ? setOneLetter(true) : setOneLetter(false);
    hasOneNumber.test(password) ? setOneNumber(true) : setOneNumber(false);
    hasEightChar.test(password)
      ? setEightCharacters(true)
      : setEightCharacters(false);
  }, [hasEightChar, hasOneLetter, hasOneNumber, password, setPassword]);

  const sendEmail = useCallback(async () => {
    if (!oneLetter || !oneNumber || !eightCharacters) {
      setErrorMsg(
        'Por favor, verifique se sua senha contém os caracteres necessários para a criação de nova senha.',
      );
    }

    if (confirmPassword !== password) {
      setErrorMsg('Senhas não se coincidem');
    }

    if (
      oneLetter &&
      oneNumber &&
      eightCharacters &&
      confirmPassword === password
    ) {
      setLoading(1);
      const formData = new FormData();

      formData.append('password', password);
      formData.append('password_confirmation', confirmPassword);
      formData.append('email', email);
      formData.append('token', token);

      try {
        const res = await api.post('/password/reset', formData);
        setLoading(0);
        const userRes = res.data.user;
        localStorage.clear();
        setUser(userRes);
        login(res.data.token);
        history.push('/home');
      } catch (error) {
        setLoading(0);
      }
    }
  }, [
    confirmPassword,
    eightCharacters,
    email,
    history,
    oneLetter,
    oneNumber,
    password,
    setUser,
    token,
  ]);

  return (
    <ContainerPage emailBkg showLogo loading={loading}>
      <Container>
        <Title>Redefinir senha</Title>
        <p style={{ marginTop: '20px' }}>Sua nova senha deve conter:</p>
        <ul style={{ marginLeft: '30px', marginTop: '10px' }}>
          <li>8 ou mais caracteres</li>
          <li>Pelo menos um número</li>
          <li>Pelo menos uma letra</li>
        </ul>
        <TextInput
          id="password"
          name="password"
          mask=""
          type="password"
          placeholder="Nova senha"
          value={password}
          onChange={passwordValidationCheck}
          error={errorMsg}
        />
        <TextInput
          className="confirm-password"
          id="confirm-password"
          name="confirm-password"
          mask=""
          type="password"
          placeholder="Confirmar nova senha"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.currentTarget.value)}
          error={errorMsg}
        />
        <Button
          primary
          type="button"
          className="btn-reset-password"
          textTransform
          onClick={sendEmail}
        >
          Enviar
        </Button>
      </Container>
    </ContainerPage>
  );
};

export default ResetPassword;
