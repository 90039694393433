import React from 'react'
import pixLogo from '../../../assets/pix-logo.svg'
import { Container } from './style'
import Button from '../../Button';



interface ConfirmPixKeyContentProps {
  onConfirm?: any;
  onCancel?: any;
  companyName?: String;
} 

const ConfirmPixKeyContent: React.FC<ConfirmPixKeyContentProps> = ({onConfirm, onCancel, companyName}) => {
  return(
    <Container>
      <div>
        <img src={pixLogo} alt="Pix"></img>
        <h1>
          <b>
            Quer ativar sua chave PIX?
          </b>
        </h1>

        <p>Ao confirmar, todas as liquidações das vendas realizadas via PIX na empresa  <b>{companyName}</b> serão destinadas à conta referente a chave cadastrada mediante a solicitação do saque.</p>
    
        <Button 
        primaryPill
        onClick={onConfirm}
        >Estou ciente e confirmo</Button> 

        <p className="cancel" onClick={onCancel}>Cancelar ativação PIX</p>

      </div>
      

    </Container>
    
  )
}

export default ConfirmPixKeyContent;