import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ContainerPage from '../../components/ContainerPage';
import ErrorMessage from '../../components/ErrorMessage';
import ScrollContainer from '../../components/ScrollContainer';
import { Title } from '../../components/Title/styles';
import UploadBox from '../../components/UploadBox';
import useFileUpload from '../../hooks/useFileUpload';
import api from '../../services/api';
import emptyCache, { isValidFileType } from '../../utils/functions';
import { Container, FadeContainer, TextTitle, ContainerFinish } from './styles';

import cloudfoxLogo from '../../../src/assets/logos/2021/svg/logo-secundary.svg';
import finishIllustration from '../../../src/assets/allright-illustration.svg';
import facebookIcon from '../../../src/assets/social-media/facebook-orange.svg';
import instagramIcon from '../../../src/assets/social-media/instagram-orange.svg';
import youtubeIcon from '../../../src/assets/social-media/youtube-orange.svg';

const PendingDocuments:React.FC = () => {

  const { id }:any = useParams();

  const [loading, setLoading] = useState<number>(1);
  const [ userName, setUserName ] = useState<string>();
  const [ userDocuments, setUserDocuments ] = useState<any[]>([]);
  const [ companyDocuments, setCompanyDocuments ] = useState<any[]>([]);

  const errorFileTypeMessage = 'Por favor, confira se seu arquivo é do tipo .jpeg, .jpg, .png, .pdf e ele deve possuir no máximo 10MB de tamanho.';

  // eslint-disable-next-line
  const { uploadFile, uploadStatus, response } = useFileUpload();

  const [documentErrorMsg, setDocumentErrorMsg] = useState('');
  const [addressErrorMsg, setAddressErrorMsg] = useState('');
  const [ companyResidenceErrorMsg, setCompanyResidenceErrorMsg ] = useState('');
  const [ companyCCMEIErrorMsg, setCompanyCCMEIErrorMsg ] = useState('');
  const [ companyBankErrorMsg, setCompanyBankErrorMsg ] = useState('');
  const [ documentsCount, setDocumentsCount ] = useState(0);
  const [ countedCheck, setCountedCheck ] = useState<boolean>(false);

  useEffect( () => {
    if(response){
      const userDocumentsArray = response.user_documents
      const companyDocumentsArray = response.company_documents
      setUserDocuments(userDocumentsArray);
      setCompanyDocuments(companyDocumentsArray); 
    }
  }, [response])


  useEffect(() => {
    var count = 0;
    if(companyDocuments){
      companyDocuments.map((document:any) => {
        (document.status === 'Recusado' || document.status === 'Pendente') &&
          count++
          return count;
      })
    }
    
    if(userDocuments){
      userDocuments.map((document:any) => {
        (document.status === 'Recusado' || document.status === 'Pendente')&&
          count++;
          return count;
      })
    }
    
    setDocumentsCount(count);
  }, [ userDocuments, companyDocuments ])


  const getPendingDocuments = async () =>{
    try{
      const res =  await api.get(`/register/pending-documents/${id}`);
      
      setUserDocuments(res.data.data.user_documents);
      setCompanyDocuments(res.data.data.company_documents);
      setUserName(res.data.data.name);
      setCountedCheck(true)
      setLoading(0);
    }catch(e) {
      setCountedCheck(true)
      setLoading(0);
      console.log(e.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  useEffect( () => {
    getPendingDocuments()  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const uploadUserDocuments = (file:any, type:any) => {
      if (isValidFileType(file)) {
        uploadFile(file, type, `/register/upload-pending-documents/${id}`);
      } else {
        type === 'USUARIO_DOCUMENTO' &&
          setDocumentErrorMsg(errorFileTypeMessage);
        type === 'USUARIO_RESIDENCIA' &&
          setAddressErrorMsg(errorFileTypeMessage);
        type === 'EMPRESA_RESIDENCIA' &&
          setCompanyResidenceErrorMsg(errorFileTypeMessage)
        type === 'EMPRESA_CCMEI' &&
          setCompanyCCMEIErrorMsg(errorFileTypeMessage)
        type === 'EMPRESA_EXTRATO' &&
          setCompanyBankErrorMsg(errorFileTypeMessage)
      }
    }

  return(
    <div>
      {documentsCount > 0 && countedCheck !== false && (
        <FadeContainer show seconds='2.5'>
          <ContainerPage showLogo docBkg loading={loading}>
            <Container>
              {userName && (
                <TextTitle><b>{userName.split(' ')[0]}</b>, alguns de seus documentos não foram aceitos. Para prosseguir, faça o upload abaixo:</TextTitle>
              )}
              <ScrollContainer height={500}>
                {userDocuments && (
                  <div>
                    {userDocuments.map((document) => (
                      <div key={document.id_code}>
                        {document.type_str === 'USUARIO_RESIDENCIA' && (document.status === 'Recusado' || document.status === 'Recusado')  && (
                          <div className='upload-div'>
                            <Title primary className='title-upload'>
                              Comprovante de residência
                            </Title>
                            {document.refused_reason && (
                              <p className='reason'>Motivo de recusa: {document.refused_reason}</p>
                            )}
                            
                            <p>
                              O Comprovante de endereço deve ser no nome do usuário e estar
                              dentro do prazo de 90 dias de vencimento. São aceitos como
                              comprovantes de residência as vias digitais ou fotos das
                              contas de:
                            </p>
                            <ul style={{ marginLeft: '30px', marginBottom: '10px', marginTop: '10px' }}>
                                <li>Água</li>
                                <li>Luz</li>
                                <li>Internet</li>
                                <li>Telefone fixo ou móvel</li>
                                <li>Gás encanado</li>
                            </ul>
                            <p style={{ fontSize: '10px' }}>
                              OBS: Caso o comprovante esteja em nome de terceiros, é
                              necessário enviar uma declaração de residência assinada pelo
                              titular da conta e uma foto do mesmo.
                            </p>
                
                            <UploadBox
                              id="uploadResidenceProve"
                              className="uploadbox"
                              wholeFile={(file: File) =>
                                uploadUserDocuments(file, 'USUARIO_RESIDENCIA')}
                            />
                            
                            {addressErrorMsg && (
                              <ErrorMessage>{addressErrorMsg}</ErrorMessage>
                            )}
                          </div>
                        )}

                        {document.type_str === 'USUARIO_DOCUMENTO' && (document.status === 'Recusado' || document.status === 'Recusado')  && (
                          <div className='upload-div'>
                            <Title primary className='title-upload'>
                              Documento oficial com foto
                            </Title>
                            
                            {document.refused_reason && (
                              <p className='reason'>Motivo de recusa: {document.refused_reason}</p>
                            )}
                            
                            <p>
                              Qualquer documento no nome do usuário com foto, dentro da validade,
                              que também conste o CPF (pode ser via digital ou foto):
                            </p>
                            
                            <ul style={{ marginLeft: '30px', marginBottom: '10px', marginTop: '10px' }}>
                              <li>RG (Os 2 lados, FRENTE e VERSO)</li>
                              <li>CNH</li>
                              <li>Carteira de trabalho</li>
                              <li>Carteira funcional (OAB, CRF, CRC, CRM etc).</li>
                            </ul>
                                      
                            <UploadBox
                              id="uploadResidenceProve"
                              className="uploadbox"
                              wholeFile={(file: File) =>
                                uploadUserDocuments(file, 'USUARIO_DOCUMENTO')}
                            />

                            {documentErrorMsg && (
                              <ErrorMessage>{documentErrorMsg}</ErrorMessage>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {companyDocuments && (
                  <div>
                    {companyDocuments.map((document) => (
                      <div key={document.id_code}>
                        {document.type_str === 'EMPRESA_RESIDENCIA' && (document.status === 'Recusado' || document.status === 'Recusado')  && (
                          <div className='upload-div'>
                            <Title primary className='title-upload'>
                              Comprovante de endereço da empresa
                            </Title>
                            {document.refused_reason && (
                              <p className='reason'>Motivo de recusa: {document.refused_reason}</p>
                            )}
                            
                            <p>
                              O Comprovante de endereço precisa estar no nome do usuário ou da
                              empresa e precisa estar dentro do prazo de 90 dias de vencimento. São
                              aceitos como comprovante empresarial as contas de: água, luz,
                              internet, telefone fixo, telefone móvel, gás encanado. Pode ser via digital ou foto.
                            </p>
                            <ul style={{ marginLeft: '30px', marginBottom: '10px', marginTop: '10px' }}>
                                <li>Água</li>
                                <li>Luz</li>
                                <li>Internet</li>
                                <li>Telefone fixo ou móvel</li>
                                <li>Gás encanado</li>
                            </ul>
                            <p style={{ fontSize: '10px' }}>
                              OBS: Caso o comprovante esteja em nome de terceiros, é
                              necessário enviar uma declaração de residência assinada pelo
                              titular da conta e uma foto do mesmo.
                            </p>
                
                            <UploadBox
                              id="uploadCompanyProve"
                              className="uploadbox"
                              wholeFile={(file: File) =>
                                uploadUserDocuments(file, 'EMPRESA_RESIDENCIA')}
                            />

                            {companyResidenceErrorMsg && (
                              <ErrorMessage>{companyResidenceErrorMsg}</ErrorMessage>
                            )}
                            
                          </div>
                        )}

                        {document.type_str === 'EMPRESA_CCMEI' && (document.status === 'Recusado' || document.status === 'Recusado')  && (
                          <div className='upload-div'>
                            <Title primary className='title-upload'>
                            Certificado de cadastro de Micro Empresário Individual (CCMEI)
                            ou o contrato social
                            </Title>
                            {document.refused_reason && (
                              <p className='reason'>Motivo de recusa: {document.refused_reason}</p>
                            )}
                            
                            <p>
                              Se for MEI, o documento é o certificado de condição de MEI.
                              Se for ME é o requerimento de empresário ou documento
                              assinado pela junta comercial do estado.
                            </p>
                
                            <UploadBox
                              id="uploadCompanyProve"
                              className="uploadbox"
                              wholeFile={(file: File) =>
                                uploadUserDocuments(file, 'EMPRESA_CCMEI')}
                            />

                            {companyCCMEIErrorMsg && (
                              <ErrorMessage>{companyCCMEIErrorMsg}</ErrorMessage>
                            )}
                          </div>
                        )}


                        {document.type_str === 'EMPRESA_EXTRATO' && (document.status === 'Recusado' || document.status === 'Recusado')  && (
                          <div className='upload-div'>
                            <Title primary className='title-upload'>
                            Extrato bancário da sua conta
                            </Title>
                            {document.refused_reason && (
                              <p className='reason'>Motivo de recusa: {document.refused_reason}</p>
                            )}
                            
                            <p>
                            Para finalizar, precisamos de um print do seu internet banking
                            ou extrato da sua conta corrente, onde consta o nome da empresa 
                            ou nome do empresário seguido do CNPJ, Banco, Agência e  Conta.
                            </p>
                
                            <UploadBox
                              id="uploadCompanyProve"
                              className="uploadbox"
                              wholeFile={(file: File) =>
                                uploadUserDocuments(file, 'EMPRESA_EXTRATO')}
                            />

                            {companyBankErrorMsg && (
                              <ErrorMessage>{companyBankErrorMsg}</ErrorMessage>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                  
              </ScrollContainer>
            </Container>
          </ContainerPage>
        </FadeContainer>
      ) }

      { documentsCount <= 0 && countedCheck !== false && (

        <ContainerFinish>
          {emptyCache()}
          <img className="logo" src={cloudfoxLogo} alt="Cloudfox"/>

          <FadeContainer show seconds='2.5'>
            <div className="illustration-container">
              <img className="illustration" src={finishIllustration} alt="Ilustração Finalizado" />  
            </div>
          </FadeContainer>

          <div className="text">
            <h1>Você não possui documentos pendentes</h1>
          
            <p>
              A partir de agora é só aguardar a análise da sua
              documentação por parte de um de nossos especialistas e, 
              em breve, teremos um retorno para você via os contatos 
              cadastrados.
            </p>
          </div>

          <div className="social-media-container">
            <p><b>Acompanhe a CloudFox:</b></p>

            <div className="social-media-imgs">
              <a href='https://www.facebook.com/cloudfox.net/'><img className="social-media-icon" src={facebookIcon} alt="Facebook"/></a>
              <a href='https://www.instagram.com/_cloudfox/'><img className="social-media-icon" src={instagramIcon} alt="Instagram"/></a>
              <a href='https://www.youtube.com/channel/UChMVx_AqELWchnuv6Z2K2rQ'><img className="social-media-icon" src={youtubeIcon} alt="Youtube"/></a>
            </div>
          </div>

        </ContainerFinish>
      )}
    </div>
  )
};

export default PendingDocuments;