import React, { useContext, useCallback, useState, useEffect } from 'react';
import { Container, Row } from './styles';
import Button from '../../../components/Button';
import GoBackButton from '../../../components/GoBackButton';
import Title from '../../../components/Title';
import UploadBox from '../../../components/UploadBox';
import ErrorMessage from '../../../components/ErrorMessage';
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import ContainerPage from '../../../components/ContainerPage';
import api from '../../../services/api';
import arrowRight  from '../../../assets/arrow-right.svg'

const Document: React.FC = () => {
  const {
    cpf,
    setFrontsideDocument,
    nextStep,
    previousStep,
    userDocumentName,
    setUserDocumentName,
    userDocumentType,
    setUserDocumentType,
  } = useContext(Context) || {};

  const [errorMsg, setErrorMsg] = useState<string>('');
  const [loading, setLoading] = useState<number>(0);
  const [frontsideArray, setFrontsideArray] = useState<any[]>([]);

  const [ disableProgress, setDisableProgress ] = useState<boolean>(true);

  const removeFile = useCallback(() => {
    localStorage.removeItem('userDocumentName');
    localStorage.removeItem('userDocumentType');
    setUserDocumentName();
    setUserDocumentType();
    setFrontsideDocument([]);
    setFrontsideArray([]);
  }, [setFrontsideDocument, setUserDocumentName, setUserDocumentType]);

  const getDataFront = useCallback(
    async (file) => {
      if (
        (file.type === 'image/png' && file.size < 10000000) ||
        (file.type === 'image/jpeg' && file.size < 10000000) ||
        (file.type === 'application/pdf' && file.size < 10000000)
      ) {
        setLoading(0);
      } else {
        setLoading(0);
        setErrorMsg(
          'Por favor, confira se seu arquivo é do tipo .jpeg, .jpg, .png, .pdf e ele deve possuir no máximo 10MB de tamanho.',
        );
        return;
      }

      setLoading(1);
      const formData = new FormData();
      formData.append('file_to_upload', file);
      formData.append('document_type', 'USUARIO_DOCUMENTO');
      formData.append('document', cpf);

      try {
        const res = await api.post('register/upload-documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setUserDocumentName(res.data.fileName);
        setUserDocumentType(res.data.fileType);
        setErrorMsg('')
        setFrontsideArray([file]);
        setLoading(0);
      } catch (error) {
        setLoading(0);
        setErrorMsg(error.response.data.errors.file_to_upload);
      }
    },
    [cpf, setUserDocumentName, setUserDocumentType],
  );

  // const getDataBack = useCallback(
  //   async (file) => {
  //     setLoading(1);
  //     const formData = new FormData();
  //     formData.append('file_to_upload', file);
  //     formData.append('document_type', 'RG_VERSO');
  //     formData.append('document', cpf);

  //     const res = await api.post('register/upload-documents', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });
  //     setBacksideDocument([res.data.path]);
  //     setBacksideArray([file]);
  //     setLoading(0);
  //   },
  //   [cpf, setBacksideDocument],
  // );

  // const validateDocument = useCallback(() => {
  //   if (frontsideDocument.length && backsideDocument.length) {
  //     nextStep();
  //   } else {
  //     setErrorMsg(
  //       'Precisamos da sua identidade em frente e verso para completar o cadastro.',
  //     );
  //   }
  // }, [backsideDocument.length, frontsideDocument.length, nextStep]);

  const validateDocument = useCallback(() => {
    if (userDocumentName) {
      nextStep();
    } else {
      setErrorMsg('Precisamos do seu documento para completar o cadastro.');
    }
  }, [nextStep, userDocumentName]);

  useEffect(() => {
    if(userDocumentName){
      setDisableProgress(false)
    }else{
      setDisableProgress(true)
    }
    
  }, [ setDisableProgress, userDocumentName])


  useEffect(()=>{
    return window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [])


  return (
    <ContainerPage showLogo docBkg loading={loading}>
      <Container>
        <Title primary>
          Precisamos da foto de um documento que contenha seu CPF:
        </Title>
        <p style={{ marginBottom: '15px' }}>
          Qualquer documento no nome do usuário com foto, dentro da validade,
          que também conste o CPF (pode ser via digital ou foto):
        </p>
        <ul style={{ marginLeft: '30px' }}>
          <li>RG (Os 2 lados, FRENTE e VERSO)</li>
          <li>CNH</li>
          <li>Carteira de trabalho</li>
          <li>Carteira funcional (OAB, CRF, CRC, CRM etc).</li>
        </ul>
        {/* <Row className="help-row">
          <Button
            primary
            primaryOutline
            onClick={toggleModal}
            className="help-btn"
            icon={helpIcon}
            data-for="help-btn"
            data-tip
          />
        </Row> */}
        {/* <p>Frente:</p> */}
        <UploadBox
          wholeFile={getDataFront}
          fileArray={frontsideArray}
          removeFile={removeFile}
          fileName={userDocumentName}
          fileType={userDocumentType}
          id="userDocument"
        >
          <p>
            Arraste e solte o arquivo aqui ou clique para{' '}
            <span>fazer upload</span>
          </p>
        </UploadBox>
        {errorMsg && <ErrorMessage marginTop="8px">{errorMsg}</ErrorMessage>}
        {/* <p>Trás:</p>
        <UploadBox
          wholeFile={getDataBack}
          fileArray={backsideArray}
          removeFile={() => {
            setBacksideDocument([]);
            setBacksideArray([]);
          }}
        >
          <p>
            Arraste e solte o arquivo aqui ou clique para{' '}
            <span>fazer upload</span>
          </p>
        </UploadBox> */}
        <Button
          primary
          type="button"
          className="btn-continue"
          textTransform
          onClick={validateDocument}
          disabled={disableProgress}
        >
          Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
        </Button>
        <Row spaceAround>
          <GoBackButton className='goback-button' onClick={previousStep} />
          {/* <JumpStepLink>Pular esta etapa</JumpStepLink> */}
        </Row>
      </Container>
    </ContainerPage>
  );
};

export default Document;
