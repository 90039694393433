import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Signup from '../views/Signup';
import Signin from '../views/Signin';
import ForgotPassword from '../views/ForgotPassword';
import ResetPassword from '../views/ResetPassword';
import Home from '../views/Admin/Home';
import PersonalInfo from '../views/Admin/PersonalInfo';
import SecurityPage from '../views/Admin/SecurityPage';
import PeopleAndAccess from '../views/Admin/PeopleAndAccess';
import PeopleAndAccessEdit from '../views/Admin/PeopleAndAccessEdit';
import PeopleAndAccessView from '../views/Admin/PeopleAndAccessView';
import ServicesPage from '../views/Admin/ServicesPage';
import SiriusPage from '../views/Admin/ServicesPage/SiriusPage';
import Companies from '../views/Admin/Companies';
import CompanyDetail from '../views/Admin/Companies/CompanyDetail';
import CompanyCreateCNPJ from '../views/Admin/Companies/CompanyCreateCNPJ';
import CompanyCreateCPF from '../views/Admin/Companies/CompanyCreateCPF';
import InPage from '../components/InPage';
import Sidebar from '../components/Sidebar';
import Redirect from '../views/Redirect';
import { PreRegisterProvider } from '../context/PreRegisterContext';
import { AccountsProvider } from '../context/AccountsContext';

import PrivateRoute from './PrivateRoute';
import FinishRegister from '../views/Signup/FinishView';
import PendingDocuments from '../views/PendingDocuments';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <AccountsProvider>
        <Switch>
          <Route path="/" exact component={Signin} />
          {/* <PreRegisterProvider>
            <Route path="/signup" exact component={Signup} />
          </PreRegisterProvider> */}
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route path="/redirect/:user/:expiration/:urldata?" component={Redirect} />

          <Route path="/signup" exact render={() => (<PreRegisterProvider> <Signup/> </PreRegisterProvider>)}/>
          <Route path="/finish" exact component={FinishRegister} />

          <Route path="/pending-documents/:id" exact component={PendingDocuments} />

          <InPage>
            <Sidebar />
            <PrivateRoute exact path="/home" component={Home} />
            <PrivateRoute
              exact
              path="/personal-info"
              component={PersonalInfo}
            />
            <PrivateRoute exact path="/security" component={SecurityPage} />
            <PrivateRoute
              exact
              path="/people-and-access"
              component={PeopleAndAccess}
            />
            <PrivateRoute
              exact
              path="/people-and-access/create"
              component={PeopleAndAccessEdit}
            />
            <PrivateRoute
              exact
              path="/people-and-access/edit/:id"
              component={PeopleAndAccessEdit}
            />
            <PrivateRoute
              exact
              path="/people-and-access/view/:id"
              component={PeopleAndAccessView}
            />
            <PrivateRoute exact path="/companies" component={Companies} />
            <PrivateRoute
              exact
              path="/companies/company-detail/:id"
              component={CompanyDetail}
            />
            <PrivateRoute
              exact
              path="/companies/company-create-juridico"
              component={CompanyCreateCNPJ}
            />
            <PrivateRoute
              exact
              path="/companies/company-create-fisico"
              component={CompanyCreateCPF}
            />
            <PrivateRoute exact path="/services" component={ServicesPage} />
            <PrivateRoute
              exact
              path="/services/sirius"
              component={SiriusPage}
            />
          </InPage>
        </Switch>
      </AccountsProvider>
    </BrowserRouter>
  );
};

export default Routes;
