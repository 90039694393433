import React, { useContext } from 'react'
import { InlineWidget, CalendlyEventListener } from "react-calendly";
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import { Container } from './styles';
import api from '../../../services/api';
import cloudfoxLogo from '../../../assets/logos/2021/svg/logo-secundary.svg';
import { useHistory } from 'react-router';

const Calendry: React.FC = () => {

  const {
    email,
    fullname,
    cpf,
  } = useContext(Context);  

  const history = useHistory();

  const handleScheduled = async () => {

    const formDataAbandonedSignups = new FormData();
    formDataAbandonedSignups.append('document', cpf);
    formDataAbandonedSignups.append('email', email);
    formDataAbandonedSignups.append('status', '1');

    try {
      const res = await api.post(
        '/abandoned-signups',
        formDataAbandonedSignups,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      console.log(res);
      localStorage.clear();
      setTimeout(() => history.push('/finish'), 5000);
    } catch (error) {
      console.log(error.message)
    }
  };

  return(
      <Container>
        <img className="logo" src={cloudfoxLogo} alt="Cloudfox"/>
        
        <h1><b>{fullname.split(' ')[0]}, agende uma call</b> com nosso time</h1>
        <p>Te apresentaremos todas as funcionalidades da nossa plataforma</p>

        <InlineWidget 
          url="https://calendly.com/cloudfox-net/account-verification"
          prefill={{
            email: `${email}`,
            firstName: `${fullname.split(' ')[0]}`,
            lastName: `${fullname.substr(fullname.indexOf(" ") + 1)}`,
            name: `${fullname}`
          }}
          pageSettings={{
            backgroundColor: 'ffffff',
            primaryColor: '#FF4E05',
            textColor: '4d5055'
          }}
        />

      <CalendlyEventListener onEventScheduled={handleScheduled} />

      </Container>  
    
  )
}

export default Calendry;