import styled, { css } from 'styled-components';

interface BadgeProps {
  status?: string;
}

export const Container = styled.div<BadgeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 23px;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 5px;

  ${({ status }) =>
    (status === 'Pendente' || status === 'Recusado') &&
    css`
      border: 1px solid #fc0134;
      background: #ffe8ec;
      color: #fc0134;
    `}

  ${({ status }) =>
    status === 'Aprovado' &&
    css`
      border: 1px solid #00be9b;
      background: #d9f2ec;
      color: #00be9b;
    `}

  ${({ status }) =>
    status === 'Em análise' &&
    css`
      border: 1px solid #fcaf01;
      background: #fff7e8;
      color: #fcaf01;
    `}

  ${({ status }) =>
    status === 'Atendimento' &&
    css`
      border: 1px solid #428bca;
      background: #e2f1ff;
      color: #428bca;
    `}

  ${({ status }) =>
    status === 'Administrativo' &&
    css`
      border: 1px solid #ac5bde;
      background: #f7eaff;
      color: #ac5bde;
    `}
  ${({ status }) =>
    status === 'Financeiro' &&
    css`
      border: 1px solid #67BFA9;
      background: #D5F2EA;
      color: #67BFA9;
    `}
  ${({ status }) =>
    status === 'Personalizado' &&
    css`
      border: 1px solid #FF6D00;
      background: #FFEDDF;
      color: #FF6D00;
    `}
`;
