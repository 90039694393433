import styled from 'styled-components';
import * as color from '../../styles/colors';

export const Container = styled.div`
  position: relative;
  /* height: 154px;
  width: 203px; */
  border: 1px solid #c3c6d1;
  border-radius: 10px;
  padding: 10px;
`;

export const DropdownContainer = styled.div`
  position: absolute;
  right: 10px;

  .dropdown-button {
    padding-bottom: 20px;
  }

  .dropdown-menu {
    right: 0;
    bottom: -60px;
  }

  .card {
    padding: 20px;
    border-radius: 10px;
    z-index: 1;
    color: ${color.darkgray};

    ul {
      li:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
`;

export const VerticalThreeDots = styled.div`
  position: relative;
  width: 2px;
  height: 2px;
  background-color: ${color.darkgray};
  border-radius: 10px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 2px;
    right: 0;
    background-color: inherit;
    border-radius: inherit;
    border-radius: 10px;
  }

  &:before {
    top: 6px;
  }

  &:after {
    top: 13px;
  }
`;

export const MenuList = styled.li`
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  list-style: none;
  cursor: pointer;

  :hover {
    color: ${color.orange};
  }
`;
