import styled from 'styled-components';
import * as color from '../../../../../styles/colors';

export const Container = styled.div`
  .img-preview {
    overflow: hidden;
    width: 240px;
    height: 240px;
  }

  .image-uploader {
    display: none;
  }

  .uploader-container {
    margin-top: 30px;
  }

  .buttons-row {
    justify-content: flex-end;
    margin-top: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const CropperContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;

  .image-uploader {
    margin-top: 20px;
  }

  .cropper {
    border: 1px dashed #dedede;
  }
`;

export const UploaderButton = styled.label`
  display: inline-block;
  padding: 10px;
  margin-top: 15px;
  border: 1px solid ${color.orange};
  background: #fff;
  border-radius: 5px;
  color: ${color.orange};
  text-align: center;
  cursor: pointer;
`;

export const PreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;

  .img-preview {
    align-self: center;
  }
`;

export const CancelButton = styled.button`
  background-color: transparent;
  border-radius: 50px;
  border: none;
  color: ${color.darkgray};
  padding: 10px;
  margin-right: 20px;
  font-weight: bold;

  :hover {
    color: #757e98;
    transition: all ease 0.3s;
  }

  @media (max-width: 546px) {
    display: none;
  }
`;

export const SaveButton = styled.button`
  background-color: ${color.orange};
  border-radius: 50px;
  border: ${color.orange};
  padding: 12px 30px;
  height: 45px;
  line-height: 1;
  color: #fff;
  font-weight: bold;

  :hover {
    background-color: #c75600;
    border-color: #c75600;
    transition: all ease 0.3s;
  }

  @media (max-width: 546px) {
    width: 100%;
  }
`;
