import React, { ReactNode, useEffect, useState } from 'react';
import { Container } from './styles'

interface CategoryButtonProps {
  checked: boolean;
  setCheck: (ckeck: boolean) => void;
  defaultImg: string;
  selectedImg: string;
  children?: ReactNode;
}

const CategoryButton: React.FC<CategoryButtonProps> = ({
  checked,
  setCheck,
  defaultImg,
  selectedImg,
  children,
}) => {

  const [ categoryClass, setCategoryClass] = useState<string>(checked? 'selected' : 'default');
  const [ img, setImg ] = useState<string>(defaultImg);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setCategoryClass(checked === false? 'default' : 'selected')
    setImg(checked === false? defaultImg : selectedImg)
    
  })

  const handleSelectCategory = () => {
    setCheck(!checked)
  }

  return (
    <Container>
      <div className={'category ' + categoryClass}>
        <button onClick={handleSelectCategory}>
          <img src={img} alt="Imagem do Botão"></img>
        </button>
        <span>{children}</span>
      </div>
    </Container>
    
  )
}

export default CategoryButton;