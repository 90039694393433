import styled from "styled-components";
import * as color from '../../styles/colors'

export const Container = styled.div`
  margin: 5px 5px 5px 0;

  button {
    width: auto;
    padding: 0 10px 0 10px;
    height: 30px;
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 0.015em;
    transition: 0.5s;
  }

  .default {
      border: 0;
      background-color: ${color.brightgray};
      color: ${color.gray};
  }

  .selected {
      border: 0;
      background-color: ${color.orange};
      color: #fff;
  }
`;