import styled from 'styled-components';
import * as color from '../../styles/colors'

export const Container = styled.div`
    .rc-slider-handle {
      border: solid 0px ${color.orange};
      background-color: ${color.orange};
    }
  
    .rc-slider-track {
      background-color: transparent;
    }
`;
