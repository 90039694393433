import styled from 'styled-components';
import * as color from '../../styles/colors';

export const Container = styled.div`
  position: absolute;
  top: -145px;
  left: -20px;
  right: -20px;
  background: ${color.orange};
`;

export const Title = styled.h3`
  font-size: 24px;
  color: #fff !important;
  line-height: 30px;
  margin-bottom: 12px;
`;

export const SubTitle = styled.p`
  font-size: 14px;
  color: #fff !important;
  line-height: 17px;
`;

export const ContentText = styled.div`
  /* display: flex;
  justify-content: center; */
  text-align: center;
  padding: 25px 20px 30px;
  margin-left: 270px;
  display: block;
  margin: auto;

  @media (max-width: 992px) {
    margin-left: 0;
    display: none;
  }
`;
