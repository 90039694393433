import React, { useContext, useEffect, useState } from 'react';
import ContainerPage from '../../../components/ContainerPage';
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import Carousel from '../../../components/Carousel';
import { Container, CarouselContainer } from './styles';
import Button from '../../../components/Button';

import folderIllustration from '../../../assets/document-tutorial/ilutsration-folder.svg';
import lightIllustration from '../../../assets/document-tutorial/ilutsration-light.svg';
import idIllustration from '../../../assets/document-tutorial/ilutsration-id.svg';
import okIllustration from '../../../assets/document-tutorial/ilutsration-ok.svg';
import GoBackButton from '../../../components/GoBackButton';
import arrowRight  from '../../../assets/arrow-right.svg'

const TutorialDocument: React.FC = () => {
  const [indexSlide, setIndexSlide] = useState<number | undefined>();

  const handleIndexSlider = (current:number, next:number) => {
    setIndexSlide(next)
  }

  const { nextStep, previousStep } = useContext(Context)

  useEffect(()=>{
    return window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [])

  return (
    <ContainerPage randomBkg showLogo>
      <Container>

        <h2 className="title-1">É hora de enviar <b>seus documentos!</b></h2>
        <p className="subtitle">Tenha em mãos um documento de identificação, comprovantes de residência e contrato empresarial.</p>

        <Carousel
           dots={true}
           infinite={false}
           speed={1000}
           slidesToShow={1}
           slideToScroll={1}
           fade={true}
           beforeChange={handleIndexSlider}
           autoplay={true}
           autoplaySpeed={4000}
        >
          <CarouselContainer>
            <img src={folderIllustration} alt="Ilustração"/>
            <br/>
            <h2 className="title-2">Junte toda a <b>documentação</b></h2>
          </CarouselContainer>

          <CarouselContainer>
            <img src={lightIllustration} alt="Ilustração"/>
            <br/>
            <h2 className="title-2">Encontre um lugar <b>iluminado</b></h2>
          </CarouselContainer>

          <CarouselContainer>
            <img src={idIllustration} alt="Ilustração"/>
            <br/>
            <h2 className="title-2">Você pode fotografar ou escanear, desde que as <b>informações sejam legíveis</b></h2>
          </CarouselContainer>

          <CarouselContainer>
            <img src={okIllustration} alt="Ilustração"/>
            <br/>
            <h2 className="title-2">Pronto pra começar? <br/>Clique no botão abaixo</h2>
          </CarouselContainer>

        </Carousel>

        <div className="buttons-div">
          
            <Button
              primary
              type="button"
              className="btn-continue"
              textTransform
              onClick={() => nextStep()}
              disabled={!(indexSlide===3)}
            >
              Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
            </Button>

            <GoBackButton className='goback-button' onClick={() => previousStep()} />
          
        </div>

      </Container>
    </ContainerPage>
  );
};

export default TutorialDocument;
