import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Container, TermsTextBox, Row } from './styles';
import Button from '../../../../components/Button';
import GoBackButton from '../../../../components/GoBackButton';
import Title from '../../../../components/Title';
import ErrorMessage from '../../../../components/ErrorMessage';
import UsePrivacyTermsText from '../../../../components/UsePrivacyTermsText';
import CheckboxInput from '../../../../components/CheckboxInput';
import ContainerPage from '../../../../components/ContainerPage';
import ReactPixel from 'react-facebook-pixel';
import api from '../../../../services/api';
import { PreRegisterContext as Context } from '../../../../context/PreRegisterContext';
import { AccountsContext } from '../../../../context/AccountsContext';

const UsePrivacyTerms: React.FC = () => {
  const { setUser } = useContext(AccountsContext);

  const {
    nextStep,
    usePrivacy,
    useTerms,
    setUsePrivacy,
    setUseTerms,
    previousStep,
    fullname,
    cpf,
    email,
    phone,
    password,
    userZipcode,
    userStreet,
    userStreetNumber,
    userComplementNumber,
    userCity,
    userState,
    userNeighborhood,
    userCountry,
    isCompanyRegister,
    cnpj,
    protocol,
    companyZipcode,
    companyStreet,
    companyStreetNumber,
    companyComplementNumber,
    companyCity,
    companyState,
    companyNeighborhood,
    bank,
    bankAccountNumber,
    bankAccountDigitNumber,
    bankAgencyNumber,
    bankAgencyDigitNumber,
    inviteCode,
    isSameAddress,
    averageBilling,
    categories,
    storeLink,
    gatewayName,
    currentEcommerce,
    howMeet,
    setUserResidentProveName,
    setUserResidentProveType,
    setResidenceReceipt,
    setCompanyContractName,
    setCompanyContractType,
    setCompanyContractDoc,
    setCompanyAddressProveName,
    setCompanyAddressProveType,
    setCompanyAddressReceipt,
    setBankStatementName,
    setBankStatementType,
    setBankStatementDoc,
    setUserDocumentName,
    setUserDocumentType,
    setFrontsideDocument,
    hasPromotionalTax,
  } = useContext(Context) || {};
  const [loading, setLoading] = useState<number>(0);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [errorsMsg, setErrorsMsg] = useState<string>('');

  console.log(hasPromotionalTax.toString());

  const handleUseTerms = useCallback(() => {
    setUseTerms(!useTerms);
    setErrorMsg('');
  }, [setUseTerms, useTerms]);

  const handleUsePrivacy = useCallback(() => {
    setUsePrivacy(!usePrivacy);
    setErrorMsg('');
  }, [setUsePrivacy, usePrivacy]);
  
  const handleRegisterData = useCallback(async () => {
    const formData = new FormData();
    formData.append('document', cpf);
    formData.append('name', fullname);
    formData.append('email', email);
    formData.append('cellphone', phone);
    formData.append('password', password);
    formData.append('zip_code', userZipcode);
    formData.append('street', userStreet);
    formData.append('number', userStreetNumber);
    formData.append('complement', userComplementNumber);
    formData.append('neighborhood', userNeighborhood);
    formData.append('city', userCity);
    formData.append('state', userState);
    formData.append('country', userCountry);
    formData.append('company_type', isCompanyRegister);
    formData.append('company_document', cnpj);
    formData.append('protocol', protocol);
    formData.append('zip_code_company', companyZipcode);
    formData.append('street_company', companyStreet);
    formData.append('number_company', companyStreetNumber);
    formData.append('complement_company', companyComplementNumber);
    formData.append('city_company', companyCity);
    formData.append('state_company', companyState);
    formData.append('neighborhood_company', companyNeighborhood);
    formData.append('privacy_terms', usePrivacy);
    formData.append('use_terms', useTerms);
    formData.append('bank', bank);
    formData.append('account', bankAccountNumber);
    formData.append('account_digit', bankAccountDigitNumber);
    formData.append('agency', bankAgencyNumber);
    formData.append('agency_digit', bankAgencyDigitNumber);
    formData.append('parameter', inviteCode);
    formData.append('is_same_address', isSameAddress.toString())
    formData.append('monthly_income', averageBilling.toString());
    formData.append('niche', categories);
    formData.append('website_url', storeLink);
    formData.append('gateway', gatewayName);
    formData.append('store', currentEcommerce);
    formData.append('cloudfox_referer', howMeet);
    formData.append('promotional_tax', (hasPromotionalTax.toString()==="true" ? "true" : ""));

    if (useTerms === true && usePrivacy === true) {
      try {
        setLoading(1);
        const response = await api.post(
          '/register',
          formData,

          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );

        ReactPixel.init('685333352269589');
        ReactPixel.pageView();
        ReactPixel.trackCustom('FinishRegister ', {
          nome: fullname,
          cpf: cpf,
          email: email,
          telefone: phone
        });

        // localStorage.clear();
        const userRes = response.data.user;
        setUser(userRes);
        // login(response.data.token);
        setLoading(0);
        nextStep();
      } catch (errors) {
        const errorData = errors.response.data;
        let errosconcat = '';
        if(errorData.message) {
          errosconcat = errorData.message;
          
          if(errorData.errors.data) {
            for (var [key, value] of Object.entries(errorData.errors.data[0])) {
              errosconcat += `<div> • ${value} <br/> <div/>`;   

              if(key === 'EMPRESA_CCMEI'){
                localStorage.removeItem('companyContractName');
                localStorage.removeItem('companyContractType');
                setCompanyContractName();
                setCompanyContractType();
                setCompanyContractDoc([]);                
              }

              if(key === 'EMPRESA_EXTRATO'){
                localStorage.removeItem('bankStatementName');
                localStorage.removeItem('bankStatementType');
                setBankStatementName();
                setBankStatementType();
                setBankStatementDoc([]);
              }

              if(key === 'EMPRESA_RESIDENCIA'){
                localStorage.removeItem('companyAddressProveName');
                localStorage.removeItem('companyAddressProveType');
                setCompanyAddressProveName();
                setCompanyAddressProveType();
                setCompanyAddressReceipt([]);
              }

              if(key === 'USUARIO_DOCUMENTO'){
                localStorage.removeItem('userDocumentName');
                localStorage.removeItem('userDocumentType');
                setUserDocumentName();
                setUserDocumentType();
                setFrontsideDocument([]);
              }

              if(key === 'USUARIO_RESIDENCIA'){
                localStorage.removeItem('companyAddressProveName');
                localStorage.removeItem('companyAddressProveType');
                setUserResidentProveName();
                setUserResidentProveType();
                setResidenceReceipt([]);
              }              
            }
            errosconcat += `<div> Clique em "voltar" para corrigir o(s) item(ns) acima. <br/> <div/>`;     

          }
          setErrorsMsg(errosconcat);
        } else if (errorData) {

          for (const key in errorData) {
            errosconcat += `<div> ${errorData[key][0]} <br/> <div/>`;
          }

          setErrorsMsg(errosconcat);
        }
        /**
        if(errors.errors.email != undefined)
        setErrorEmailMsg(errors.errors.email[0]);

        if(errors.errors.password != undefined)
        setErrorPasswordMsg(errors.errors.password[0]);
        */
        setLoading(0);
      }

    } else {
      setErrorMsg(
        'Por favor, leia e aceite os termos de uso e de privacidade.',
      );
    }
  }, [
    bank,
    bankAccountDigitNumber,
    bankAccountNumber,
    bankAgencyDigitNumber,
    bankAgencyNumber,
    cnpj,
    companyCity,
    companyComplementNumber,
    companyNeighborhood,
    companyState,
    companyStreet,
    companyStreetNumber,
    companyZipcode,
    cpf,
    email,
    fullname,
    inviteCode,
    isCompanyRegister,
    password,
    phone,
    protocol,
    setUser,
    usePrivacy,
    useTerms,
    userCity,
    userComplementNumber,
    userCountry,
    userNeighborhood,
    userState,
    userStreet,
    userStreetNumber,
    userZipcode,
    isSameAddress,
    averageBilling,
    categories, 
    currentEcommerce, 
    gatewayName,
    howMeet, 
    storeLink,
    nextStep,
  ]);

  const [ disableProgress, setDisableProgress ] = useState<boolean>(true);

  useEffect(() => {
    if(usePrivacy && useTerms){
      setDisableProgress(false)
    }else{
      setDisableProgress(true)
    }
    
  }, [ setDisableProgress, usePrivacy, useTerms ])

  return (
    <ContainerPage
      showLogo
      bankTermsBkg
      loading={loading}
      loadingText="Aguarde, estamos processado seu cadastro"
    >
      <Container>
        {errorsMsg && (
          <div
            style={{
              padding: '5px',
              border: '1px solid red',
              color: 'red',
              marginBottom: '30px',
            }}
            dangerouslySetInnerHTML={{ __html: errorsMsg }}
          />
        )}
        <Title primary>Termos de uso e privacidade</Title>
        <TermsTextBox>
          <UsePrivacyTermsText />
        </TermsTextBox>
        <CheckboxInput
          label="Li e aceito os"
          boldLabel="termos de uso"
          checked={useTerms}
          onChange={handleUseTerms}
        />
        <CheckboxInput
          label="Li e aceito os"
          boldLabel="termos de privacidade"
          checked={usePrivacy}
          onChange={handleUsePrivacy}
        />
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        <Button
          primary
          type="button"
          className="btn-finish"
          textTransform
          onClick={handleRegisterData}
          disabled={disableProgress}
        >
          Finalizar
        </Button>

        <Row>
          <GoBackButton className='goback-button' onClick={previousStep} />
        </Row>
      </Container>
    </ContainerPage>
  );
};

export default UsePrivacyTerms;
