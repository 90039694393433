import React from 'react';
import { Container } from './styles';
import cloudfoxLogo from '../../assets/logos/2021/svg/logo-secundary.svg';

const Topbar: React.FC = () => {
  return (
    <Container className="topbar">
      <img src={cloudfoxLogo} alt="" width={200}/>
    </Container>
  );
};

export default Topbar;
