import React, { useCallback, useState, useContext, useEffect } from 'react';
import { Container, Row } from './styles';
import Button from '../../../components/Button';
import GoBackButton from '../../../components/GoBackButton';
import Title from '../../../components/Title';
import UploadBox from '../../../components/UploadBox';
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import ContainerPage from '../../../components/ContainerPage';
import Modal from '../../../components/Modal';
import ErrorMessage from '../../../components/ErrorMessage';
import api from '../../../services/api';
import arrowRight  from '../../../assets/arrow-right.svg'

const ResidenceReceipt: React.FC = () => {
  const {
    setResidenceReceipt,
    cpf,
    nextStep,
    previousStep,
    userResidentProveName,
    setUserResidentProveName,
    userResidentProveType,
    setUserResidentProveType,
  } = useContext(Context) || {};
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [ disableProgress, setDisableProgress ] = useState<boolean>(true);

  const removeFile = useCallback(() => {
    localStorage.removeItem('userResidentProveName');
    localStorage.removeItem('userResidentProveType');
    setUserResidentProveName();
    setUserResidentProveType();
    setResidenceReceipt([]);
  }, [setResidenceReceipt, setUserResidentProveName, setUserResidentProveType]);

  const getData = useCallback(
    async (file) => {
      if (
        (file.type === 'image/png' && file.size < 10000000) ||
        (file.type === 'image/jpeg' && file.size < 10000000) ||
        (file.type === 'application/pdf' && file.size < 10000000)
      ) {
        setLoading(0);
      } else {
        setLoading(0);
        setErrorMsg(
          'Por favor, confira se seu arquivo é do tipo .jpeg, .jpg, .png, .pdf e se ele possui menos de 10MB de tamanho.',
        );
        return;
      }

      setLoading(1);
      const formData = new FormData();
      formData.append('file_to_upload', file);
      formData.append('document_type', 'USUARIO_RESIDENCIA');
      formData.append('document', cpf);

      try {
        const res = await api.post('register/upload-documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        // setUploadedData([file]);
        setResidenceReceipt([res.data.path]);
        setUserResidentProveName(res.data.fileName);
        setUserResidentProveType(res.data.fileType);
        setErrorMsg('');
        setLoading(0);
      } catch (error) {
        setLoading(0);
        setErrorMsg(error.response.data.errors.file_to_upload);
      }
    },
    [
      cpf,
      setResidenceReceipt,
      setUserResidentProveName,
      setUserResidentProveType,
    ],
  );

  const validateReceipt = useCallback(() => {
    if (userResidentProveName) {
      nextStep();
    } else {
      setErrorMsg(
        'O seu comprovante de residência é importante para completar o cadastro.',
      );
    }
  }, [nextStep, userResidentProveName]);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const residenceProveModal = (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      closeModal={toggleModal}
      headerLabel="Comprovante de residência"
    >
      <p style={{ marginBottom: '15px' }}>
        O Comprovante de endereço deve ser no nome do usuário e estar dentro do
        prazo de 90 dias de vencimento. São aceitos como comprovantes de
        residência as vias digitais ou fotos das contas de:
      </p>
      <ul style={{ marginLeft: '30px' }}>
        <li>Água</li>
        <li>Luz</li>
        <li>Internet</li>
        <li>Telefone fixo ou móvel</li>
        <li>Gás encanado</li>
      </ul>
      <p style={{ marginTop: '15px', fontWeight: 'bold' }}>
        OBS: Caso o comprovante esteja em nome de terceiros, é necessário enviar
        uma declaração de residência assinada pelo titular da conta e uma foto
        do mesmo.
      </p>
    </Modal>
  );

  useEffect(() => {
    if(userResidentProveName){
      setDisableProgress(false)
    }else{
      setDisableProgress(true)
    }
  }, [userResidentProveName, setDisableProgress])

  return (
    <ContainerPage showLogo residenceBkg loading={loading}>
      {residenceProveModal}
      <Container>
        <Title primary>Também precisamos de um comprovante de residência</Title>
        {/* <p>Pode ser uma conta de luz, uma fatura de cartão de crédito, etc..</p> */}

        <p style={{ marginBottom: '15px' }}>
          O Comprovante de endereço deve ser no nome do usuário e estar dentro
          do prazo de 90 dias de vencimento. São aceitos como comprovantes de
          residência as vias digitais ou fotos das contas de:
        </p>
        <ul style={{ marginLeft: '30px' }}>
          <li>Água</li>
          <li>Luz</li>
          <li>Internet</li>
          <li>Telefone fixo ou móvel</li>
          <li>Gás encanado</li>
        </ul>
        <p style={{ marginTop: '15px', fontSize: '11px' }}>
          OBS: Caso o comprovante esteja em nome de terceiros, é necessário
          enviar uma declaração de residência assinada pelo titular da conta e
          uma foto do mesmo.
        </p>
        {/* <Row className="help-row">
          <Button
            primary
            primaryOutline
            onClick={toggleModal}
            className="help-btn"
            icon={helpIcon}
            data-for="help-btn"
            data-tip
          />
        </Row> */}
        <UploadBox
          wholeFile={getData}
          removeFile={removeFile}
          fileName={userResidentProveName}
          fileType={userResidentProveType}
          id="userResidenceProve"
        >
          <p>
            Arraste e solte o arquivo aqui ou clique para{' '}
            <span>fazer upload</span>
          </p>
        </UploadBox>
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        <Button
          primary
          type="button"
          className="btn-continue"
          textTransform
          onClick={validateReceipt}
          disabled={disableProgress}
        >
          Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
        </Button>
        <Row>
          <GoBackButton className='goback-button' onClick={previousStep} />
          {/* <JumpStepLink>Pular esta etapa</JumpStepLink> */}
        </Row>
      </Container>
    </ContainerPage>
  );
};

export default ResidenceReceipt;
