import React from 'react';
import {
  Container,
  ModalFooter,
  ModalBody,
  ModalContent,
  ModalHeader,
} from './styles';
import Button from '../Button';
import closeIcon from '../../assets/x.svg';

interface ModalProp {
  id?: string;
  isOpen: boolean;
  contentLabel?: string;
  headerLabel?: string;
  style?: {};
  onRequestClose?: () => void;
  onAfterOpen?: () => void;
  closeModal?: () => void;
}
const Modal: React.FC<ModalProp> = ({
  children,
  id,
  isOpen,
  contentLabel,
  style,
  headerLabel,
  closeModal,
  onRequestClose,
  onAfterOpen,
}) => {
  return (
    <Container
      id={id}
      isOpen={isOpen}
      style={style}
      contentLabel={contentLabel}
      onRequestClose={onRequestClose}
      onAfterOpen={onAfterOpen}
      className="modal-component"
      overlayClassName="Modal-Overlay"
      closeTimeoutMS={500}
      ariaHideApp={false}
    >
      <ModalContent>
        <ModalHeader>
          <p>{headerLabel}</p>
          <button type="button" className="close-btn" onClick={closeModal}>
            <img src={closeIcon} alt="" />
          </button>
        </ModalHeader>
        <ModalBody>
          {children}
          <p style={{ marginTop: '15px', fontWeight: 'bold' }}>
            *Tipos de arquivos aceitos para upload:
          </p>
          <ul style={{ marginLeft: '30px', marginTop: '15px' }}>
            <li>PNG</li>
            <li>JPG/JPEG</li>
            <li>PDF</li>
          </ul>
        </ModalBody>

        <ModalFooter>
          <Button
            primary
            primaryOutline
            className="close-bottom-btn"
            onClick={closeModal}
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Container>
  );
};

export default Modal;
