import styled from 'styled-components';
import * as color from '../../../styles/colors';

export const Container = styled.div`
  
  display: flex;
  flex-direction: column;
  align-items: center;
  

  div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    max-width: 400px;

    > img {
      width: 82px;
      height: 82px;
      margin-bottom: 15px;
    }

    h1 {
      font-size: 25px;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 25px;
      text-align: center;
    }

    button {
      margin-bottom: 15px;
      cursor: pointer;
    }

    .cancel {
      text-decoration: underline;
      cursor: pointer;
      color: ${color.darkgray}
    }
  }

  

  .content {
    max-width: 300px;
  }

  
`;