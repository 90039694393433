import React, { useState, useEffect, useCallback } from 'react';
import { useParams,useHistory,useLocation } from 'react-router-dom';

import api from '../../../services/api';
import { Container, Content,ContainerForm, SwitchContainer, Row,Col10,Col40,Col25} from './styles';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import PageTopbar from '../../../components/PageTopbar';
import GobackLink from '../../../components/GoBackLink';
import Input from '../../../components/Input';
import InputCpf from '../../../components/InputCpf';
import Select from '../../../components/AdminSelectInput';
import Card from '../../../components/Card';
import AccordionPermission from '../../../components/AccordionPermission';

import { IUser } from '../../../entities/User';
import { permissionsList } from '../../../data/permissionsList';

interface UserProps {
    id: string;
}

const roles = [
    {
        value: 'admin',
        label: 'Administrativo',
    },
    {
        value: 'attendance',
        label: 'Atendimento',
    },
    {
        value: 'finantial',
        label: 'Financeiro',
    },
    {
        value: 'custom',
        label: 'Personalizado',
    },
];

const services = [
    {
        value: 'Sirius',
        label: 'Sirius',
    },
];

type FormData = {
    id_code: string | undefined;
    service: string | undefined;
    role: string | undefined;
    name: string | undefined;
    email: string | undefined;
    document: string | undefined;
    cellphone: string | undefined;
    refund_permission: boolean | undefined;
    password: string | undefined;
    permissions: Array<string> | undefined;
};


const PeopleAndAccessEdit: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    
    const [forceToReload, setForceToReload] = useState<number>(0);
    const [userEditSelected, setUserEditSelect] = useState<IUser | undefined>();
    const { id } = useParams<UserProps>();

    const [collaboratorType, setCollaboratorType] = useState<string>('');
    const [loading, setLoading] = useState<number>(0);
    const [generalError, setGeneralError] = useState<string>('');
    const [new_permissions, setNewPermissions] = useState<Array<any>>([]);    
    const [role, setRole] = useState<string>('');    
    const [permissionsListTemp, setPermissionsListTemp] = useState<Array<any>>([...permissionsList]);    
    const [isModeView,setIsModeView] = useState(location.pathname.search("view")==-1?false:true);
    const [titleForm, setTitleForm] = useState<string>(isModeView ? 'Pessoas e acessos' : id === undefined ? 'Cadastrar novo acesso' : 'Edição de acesso');    
    
    
    const { register, setValue, handleSubmit, errors, setError } = useForm<FormData>({
        defaultValues: {
            service: 'Sirius',
            id_code: userEditSelected?.id_code,
            name: userEditSelected?.name,
            cellphone: userEditSelected?.cellphone,
            email: userEditSelected?.email,
            document: userEditSelected?.document,
            role: userEditSelected?.roles[0],
            refund_permission: userEditSelected?.refund_permission,
            permissions: userEditSelected?.permissions
        },
    });

    const onSubmit = handleSubmit(async ({ ...FormData }) => {
        FormData.permissions = new_permissions;
        console.log(collaboratorType);
        console.log(new_permissions.length);
        if(collaboratorType=='custom' && new_permissions.length==0){
            toast.error('Selecione pelo menos uma permissão.');            
            return false;
        }

        if (userEditSelected) {
            userEditSelected.permissions = new_permissions;
        }

        try {
            if (userEditSelected?.id_code) {
                setLoading(1);
                await api.put(`/collaborators/${userEditSelected.id_code}`, {
                    ...FormData,
                });
            } else {
                setLoading(1);
                await api.post('/collaborators', {
                    ...FormData,
                });
            }

            setLoading(0);
            // onCancelClick(true);
            if(location.pathname.search("create")==-1 ){
                toast.success('Colaborador atualizado com sucesso!');
            }else{
                toast.success('Colaborador cadastrado com sucesso!');
            }

            setTimeout(() => {
                history.push("/people-and-access");  
            }, 1600);

        } catch (errors) {
            console.log(errors);
            if(errors){
                if (errors == 500) {
                    setGeneralError('Ocorreu um problema, tente novamente mais tarde.');
                } else {
                    const errorData = errors.response.data;
                    Object.entries(errorData.errors).map((resError: any) => {
                        setError(resError[0], {
                            type: 'manual',
                            message: resError[1][0],
                        });
                    });
                    setGeneralError('');
                }
            }else{
                setGeneralError('Ocorreu um problema, tente novamente mais tarde.');
            }
            setLoading(0);
        }
    });

    const getColaboratorApi = async () => {
        setLoading(1);
        try {
            const response = await api.get('/collaborators');
            const comp = response.data.data;
            const findUser = comp.find((c: IUser) => c.id_code == id);
            
            setUserEditSelect(findUser);
            
            setLoading(0);
        } catch (error) {
            setLoading(0);
        }
    };

    useEffect(() => {
        getColaboratorApi();        
    },[forceToReload]);

    useEffect(() => {
        
        register(
            { name: 'role' },
            { required: { value: true, message: 'Campo obrigatório' } },
        );
        register(
            { name: 'service' },
            { required: { value: true, message: 'Campo obrigatório' } },
        );
        register({ name: 'refund_permission' });

        if (userEditSelected?.roles[0]) {
            setRole(userEditSelected?.roles[0]);
        }

        setValue('service', 'Sirius');
        setValue('id_code', userEditSelected?.id_code);
        setValue('name', userEditSelected?.name);
        setValue('cellphone', userEditSelected?.cellphone);
        setValue('email', userEditSelected?.email);
        setValue('document', userEditSelected?.document);
        setValue('role', userEditSelected?.roles[0]);
        setValue('collaboratorType', userEditSelected?.roles[0]);
        setValue('refund_permission', userEditSelected?.refund_permission);
        setCollaboratorType(userEditSelected?.roles[0]);

        if(userEditSelected?.permissions){
            setNewPermissions(userEditSelected.permissions);
            updatePermissionsListTemp(userEditSelected.permissions);
        }
        
    }, [register, userEditSelected]);

    useEffect(()=>{
        console.log('monitorando permissionsListTemp');
    },[permissionsListTemp]);

    const resetPermissionsList = ()=>{
        permissionsList.forEach((item:any) => {
            item.permissions.toView.checked=false;
            item.permissions.toView.partial=false;
            item.permissions.manage.checked=false;
            item.permissions.manage.partial=false;            
            item.items.forEach((row:any) => {                    
                row.permissions.toView.checked=false;
                row.permissions.manage.checked=false;                   
            });
            item.at_least_one =false;
        });
        setPermissionsListTemp([...permissionsList]);        
    }

    const getPermissionsFromRole = async (slugRole:string)=>{
                
        const response = await api.get(`/collaborators/permissions-role/${slugRole}`);
        setNewPermissions(response.data);
        updatePermissionsListTemp(response.data);
    }

    const handleChange = useCallback(

        (e:any) => {
            setValue('role', e.value);
            setCollaboratorType(e.value);
            setRole(e.value);  
            
            console.log(e.value);
            if(e.value && e.value != 'custom'){               
                getPermissionsFromRole(e.value);
            } else{
                setNewPermissions([]);
                resetPermissionsList();
            }
        }
    ,[setValue, role, collaboratorType]);
    

    const handleSwitchRefund = useCallback(
        (e) => {
            setValue('refund_permission', e.target.checked);
        },
        [setValue],
    );

    const handleSwitchPermissions = useCallback(
        (e) => {
            console.log(e.target.id);
            if(e.target.id.search("main") == -1){
                console.log('permissao normal');
                addOrRemovePermission(e.target.id,e.target.checked);                
            }else{
                if(e.target.id.search("_all") == -1){
                    console.log('permissao de switch main');
                    permissionsList.forEach((item:any) => {
                        if(item.value == e.target.id){                                
                            if(item.items.length==0){
                                addOrRemovePermission(item.permissions.toView.value,e.target.checked);
                                addOrRemovePermission(item.permissions.manage.value,e.target.checked);
                            }
                            item.items.forEach((permission:any) => {                            
                                addOrRemovePermission(permission.permissions.toView.value,e.target.checked);
                                addOrRemovePermission(permission.permissions.manage.value,e.target.checked);
                            });  
                            return;                          
                        }                    
                    });
                }else{    
                    console.log('permissao de checkbox main');                
                    permissionsList.forEach((item:any) => {
                       if(item.permissions.toView.value == e.target.id){
                            item.items.forEach((permission:any) => {                                
                                addOrRemovePermission(permission.permissions.toView.value,e.target.checked);                                                                
                                addOrRemovePermission(permission.permissions.manage.value,false);                                
                            });      
                            return;                      
                        }
                        if(item.permissions.manage.value == e.target.id){                            
                            item.items.forEach((permission:any) => {                                
                                addOrRemovePermission(permission.permissions.manage.value,e.target.checked);
                            });    
                            return;                        
                        }
                    });                    
                }                
            }

            updatePermissionsListTemp(new_permissions);            
        },
        [setValue,new_permissions,permissionsList],
    );

    const addOrRemovePermission = (permission:any,checked:boolean)=>{
        
        if(permission){
            if(checked){
                if (new_permissions[permission] === undefined) {
                    new_permissions.push(permission);
                    console.log(`adicionando ${permission}`);
                }
            }else{
                var index = new_permissions.indexOf(permission);
                if(index>=0){
                    new_permissions.splice(index, 1); 
                    console.log(`deletando ${permission}`);
                }
            }
        }
    }

    const updatePermissionsListTemp = (permissionListSource:Array<string>)=>{

        console.log(permissionListSource);
        var totalViewChecked = 0;
        var totalManageChecked = 0;  
        var totalView = 0; 
        var totalManage = 0; 

        resetPermissionsList();

        permissionsList.forEach((item:any) => {
            totalViewChecked = 0;
            totalManageChecked = 0; 
            totalView = 0;
            totalManage = 0;

            permissionListSource.forEach((itemPermission: any) => {
                if (itemPermission == item.permissions.toView.value) {
                    item.permissions.toView.checked = true;
                    item.at_least_one =true;
                }
                if (itemPermission == item.permissions.manage.value) {
                    item.permissions.manage.checked = true;
                    item.at_least_one =true;
                }

                if(!item.permissions.toView.checked && item.permissions.manage.checked){
                    item.permissions.manage.checked = false;
                    item.at_least_one =false;
                    addOrRemovePermission(item.permissions.manage.value,false);
                }
            });

            if(item.items.length>0){
                item.items.forEach((permission:any) => {

                    if(permission.permissions.toView.show){
                        totalView++;
                    }
                    if(permission.permissions.manage.show){
                        totalManage++;
                    }
                    permissionListSource.forEach((itemPermission: any) => {
                        if (itemPermission == permission.permissions.toView.value) {
                            permission.permissions.toView.checked = true;
                            item.at_least_one =true;                        
                            totalViewChecked++;
                        }
        
                        if (itemPermission == permission.permissions.manage.value) {
                            permission.permissions.manage.checked = true;
                            item.at_least_one =true;                        
                            totalManageChecked++;
                        }   
                        
                    });
                    if(!permission.permissions.toView.checked && permission.permissions.manage.checked){
                        permission.permissions.manage.checked = false;
                        addOrRemovePermission(permission.permissions.manage.value,false);
                        totalManageChecked--;
                    }
                }); 
                        
                console.log(`totalView:${totalView}, totalMange:${totalManage}, viewChecked:${totalViewChecked}, manageCehcked:${totalManageChecked}`);

                if(totalView>0){
                    if(totalViewChecked>0){
                        item.permissions.toView.checked = true;
                        item.permissions.toView.partial = true;
                    }
                    if(totalView== totalViewChecked){
                        item.permissions.toView.checked = true;
                        item.permissions.toView.partial = false;
                    }
                }

                if(totalManage>0){
                    if(totalManageChecked>0){
                        item.permissions.manage.checked = true;
                        item.permissions.manage.partial = true;
                    }
                    if(totalManage == totalManageChecked){                        
                        item.permissions.manage.partial = false;
                    }
                }                
            }
        });

        setPermissionsListTemp([...permissionsList]);                    
    }

    const normalizePhone = (str: string) => {
        return str
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{2})(\d)/, '($1)$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{4})\d+?$/, '$1'); // captura 4 numeros seguidos de um traço e não deixa ser digitado mais nada
        // .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    };

    const handleCancelClick = (e:any)=>{        
        history.push("/people-and-access");        
    }

    return (
        <Container>
            <PageTopbar
                title={titleForm}
                subTitle="Crie e gerencie as permissões de colaboradores"
            />
            <Content>
                <GobackLink to="/people-and-access" className="goback-button" />                
                <ContainerForm
                    cancelButton                    
                    submitButton
                    onSubmit={onSubmit}
                    onCancelClick={handleCancelClick}
                    hideSubmitButton={isModeView}                    
                >
                    {/* <Loader loading={loading} /> */}
                    <p>{generalError}</p>
                    
                    <Card cardTitle="Dados do Usuário" loading={loading}>
                        <div className="row">
                            <Input
                                label="Nome"
                                id="name"
                                name="name"
                                mask=""
                                className="column"
                                register={register({
                                    required: { value: true, message: 'Campo obrigatório' },
                                })}
                                autoComplete="new-name"
                                errorMessage={errors.name?.message}
                            />

                            <Input
                                label="Email"
                                id="email"
                                name="email"
                                type="email"
                                className="column"
                                autoComplete="new-email"
                                register={register({
                                    required: { value: true, message: 'Campo obrigatório' },
                                })}                                
                                errorMessage={errors.email?.message}                                    
                            />

                            <Input
                                label="Telefone"
                                id="cellphone"
                                name="cellphone"
                                placeholder="(99)99999-9999"
                                register={register({
                                    required: { value: true, message: 'Campo obrigatório' },
                                })}
                                errorMessage={errors.cellphone?.message}
                                className="column"
                                autoComplete="new-phone"
                                onChange={(event) => {
                                    const { value } = event.target;
                                    event.target.value = normalizePhone(value);
                                }}                                    
                            />

                            <InputCpf
                                label="CPF"
                                id="document"
                                name="document"
                                mask="999.999.999-99"
                                placeholder="CPF"
                                register={register({
                                    required: { value: true, message: 'Campo obrigatório' },
                                })}
                                className="column"
                                autoComplete="new-document"
                                errorMessage={errors.document?.message}                                    
                            />

                            {userEditSelected?.id_code == undefined && (
                                <Input
                                    label="Senha"
                                    id="password"
                                    name="password"
                                    type="password"
                                    register={register({
                                        required: { value: true, message: 'Campo obrigatório' },
                                    })}
                                    className="column"
                                    autoComplete="new-password"
                                    errorMessage={errors.password?.message}
                                />
                            )}

                            {userEditSelected?.id_code != undefined && (
                                <Input
                                    label="Senha"
                                    id="password"
                                    name="password"
                                    type="password"
                                    register={register()}
                                    className="column"
                                    autoComplete="new-password"
                                    errorMessage={errors.password?.message}
                                />
                            )}
                        </div>

                        <div className="row">
                            <Select
                                label="Serviços"
                                className="column"
                                name="services"
                                value={services.find((v) => v.value === 'Sirius')}
                                options={services}
                                errorMessage={errors.service?.message}
                            />
                            <Select
                                name="role"
                                className="column"
                                label="Função"
                                options={roles}
                                value={roles.find((v) => v.value === role)}
                                onChange={handleChange}
                                errorMessage={errors.role?.message ? 'Campo Obrigatório' : ''}
                            />
                        </div>

                    </Card>
                                       
                    {role && (
                    <Card cardTitle="Gerencie as Permissões" loading={loading} style={{marginTop:25}}>
                        <Row>
                            <Col40>Seção</Col40>
                            <Col25 style={{textAlign:'center'}}>Visualizar</Col25>
                            <Col25 style={{textAlign:'center'}}>Gerenciar</Col25>
                            <Col10></Col10>
                        </Row>
                        
                        {permissionsListTemp && permissionsListTemp?.map((rowMain:any) => (
                                <AccordionPermission key={rowMain.value}
                                    id={rowMain.value} 
                                    permissions={rowMain.permissions} 
                                    label={rowMain.label} 
                                    items={rowMain.items}
                                    onChange={handleSwitchPermissions}
                                    checked={rowMain.at_least_one}
                                    role={collaboratorType}
                                    isModeView={isModeView}
                                    >
                                </AccordionPermission>
                            ))
                        }
                    </Card>
                    )}
                </ContainerForm>
            </Content>
        </Container>
        
        
    );
};

export default PeopleAndAccessEdit;