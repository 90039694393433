import React from 'react';
import { Container } from './styles';
import PageTopbar from '../../../components/PageTopbar';
import ActiveDevices from './ActiveDevices';
import ChangePassword from './ChangePassword';

const SecurityPage: React.FC = () => {
  return (
    <Container>
      <PageTopbar
        title="Segurança"
        subTitle="Gerencie seus dados para acesso aos seus produtos CloudFox"
      />
      <ActiveDevices />
      <ChangePassword />
    </Container>
  );
};

export default SecurityPage;
