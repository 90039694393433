/* eslint-disable eqeqeq */
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ContentBox from '../../../components/ContentBox';
import * as color from '../../../styles/colors';
import plusIcon from '../../../assets/plus.svg';

interface CompaniesProps {
  isCompanyActive?: number;
}

export const Container = styled.div`
  position: relative;

  .companies-list-card {
    min-height: 150px;
  }

  .no-companies-message {
    font-size: 20px;
    text-align: center;
  }
`;

export const CompanyBox = styled(ContentBox)`
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  img {
    margin-right: 28px;
    width: 40px;
    height: 40px;
  }

  .dropdown-container {
    top: 10px;
  }
`;

export const CompanyBoxInactive = styled(ContentBox)`
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #ccc;
  img {
    margin-right: 28px;
    width: 40px;
    height: 40px;
  }

  .dropdown-container {
    top: 10px;
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;

  .header {
    font-weight: bold;
  }

  /* .register-date {
    font-size: 12px;
  } */

  .company-status {
    margin: 15px 0 0;

    .status-date {
      display: flex;
      align-items: center;
    }
  }

  .service-status {
    margin-top: 15px;
  }

  .company-name {
    padding-right: 10px;

    &:hover {
      color: ${color.orange};
    }
  }

  .company-name-disabled {
    padding-right: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ServiceType = styled.div`
  position: relative;
  margin: 10px 0 0 20px;
  color: ${color.darkgray};
  ::before {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    background: #00be9b;
    border-radius: 10px;
    left: -20px;
    top: 3px;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;

  > p {
    margin-right: 8px;
  }
`;

export const AddButton = styled(Link)`
  height: 60px;
  width: 60px;
  border-radius: 60px;
  background: url(${plusIcon}) no-repeat center ${color.orange};
  background-size: 24px;
  border: 1px solid ${color.orange};
  position: fixed;
  right: 55px;
  bottom: 30px;
  box-shadow: 0px 12px 25px rgba(14, 40, 93, 0.15);

  @media (max-width: 546px) {
    position: unset;
    margin-top: 20px;
    width: 100%;
    height: 40px;
  }

  @media (max-width: 769px) {
    position: unset;
    margin-top: 20px;
  }
`;

export const CompanyStatus = styled.p<CompaniesProps>`
  font-size: 14px;
  font-weight: bold;
  margin-left: 15px;
  line-height: 14px;
  color: ${({ isCompanyActive }) =>
    isCompanyActive == 0 ? `${color.red}` : `${color.green}`} !important;
`;
