import styled from "styled-components";
import * as color from '../../styles/colors'

export const Container = styled.div`

  .category {
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      width: 64px;
      height: 64px;
      margin:  5px 15px 5px 15px;
      box-sizing: border-box;
      border-radius: 4px;  
    }

    span {
      max-width: 89px;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 120%;
      text-align: center;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
  }

  .default {
    button {
      border: 1px solid ${color.orange};
      background-color: ${color.lightorange};
    }

    span {
      color: ${color.lightgray};
    }
  }


  .selected {
    button {
      border: 1px solid ${color.orange};
      background-color: ${color.orange};
      display: flex;
      justify-content: center;
      align-items: center;

    }
    span {
      color: ${color.orange};
    }
  }
`;