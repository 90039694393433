import styled from 'styled-components';
import { shade } from 'polished';
import * as color from '../../../../styles/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .question {
    font-size: 28px;
    margin-top: 20px;
  }

  .phrase {
    font-size: 16px;
    margin-top: 15px;
    text-align:center;
  }

  .buttons-container {
    margin-top: 40px;

    .cancel-button {
      background-color: transparent;
      border-radius: 50px;
      border: 1px solid;
      color: ${color.darkgray};
      padding: 12px 22px;
      height: 45px;
      font-weight: bold;
      float:left;
      margin:0px 10px;

      :hover {
        color: ${shade(-0.8, color.darkgray)};
        transition: all ease 0.3s;
      }
    }

    .delete-button {
      background-color: #ff4c52;
      border-radius: 50px;
      border: #ff4c52;
      padding: 12px 30px;
      height: 45px;
      line-height: 1;
      color: #fff;
      font-weight: bold;
      margin-left: 20px;

      :hover {
        background-color: ${shade(0.1, '#ff4c52')};
        /* border-color: #ff4c52; */
        transition: all ease 0.3s;
      }
    }
  }
`;
