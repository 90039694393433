/* eslint-disable eqeqeq */
import React, { useContext, useCallback, useState, useEffect } from 'react';
import { Container, Row, Avatar } from './styles';
import SmallCard from '../../../components/SmallCard';
import PageTopbar from '../../../components/PageTopbar';
import Loader from '../../../components/Loader';
import siriusLogo from '../../../assets/services/sirius-logo.svg';
import vegaLogo from '../../../assets/services/vega-logo.svg';
import api from '../../../services/api';
import { AccountsContext as Context } from '../../../context/AccountsContext';

const noAvatarImage =
  'https://cloudfox-documents.s3.amazonaws.com/cloudfox/defaults/user-default.png';

const Home: React.FC = () => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState<number>(0);

  useEffect(() => {
    // if (window.Appcues != undefined && window.Appcues != null) {
    //   window.Appcues.page();
    // }
  });

  async function handleLoginAdmin() {
    try {
      setLoading(1);
      const response = await api.post('/send-authenticated');
      window.location.href = response.data.data.url;
      // setLoading(0);
    } catch (error) {
      setLoading(0);
    }
  }

  const imageError = useCallback((e) => {
    e.target.src = noAvatarImage;
  }, []);

  return (
    <>
      <Loader loading={loading} loadingText="Aguarde, estamos redirecionando" />

      <Container>
        <PageTopbar
          title={`Bem vindo,  ${user?.name}`}
          subTitle="Gerencie suas informações, privacidade e segurança dos seus produtos
            Cloudfox"
        >
          <Avatar>
            <img
              className="photo"
              src={user?.photo ? user?.photo : noAvatarImage}
              onError={imageError}
              alt=""
            />
          </Avatar>
        </PageTopbar>
        <Row>
          <SmallCard
            className="service-card"
            footerText="Acessar gateway"
            contenText="Gateway de pagamentos"
            footerOnClick={handleLoginAdmin}
            cardOnClick={handleLoginAdmin}
          >
            <img src={siriusLogo} alt="" className="sirius-img" />
          </SmallCard>
          <SmallCard
            className="service-card vega"
            footerText="Em breve"
            contenText="Internet banking"
          >
            <img src={vegaLogo} alt="" className="vega-img" />
          </SmallCard>
          {/* <SmallCard
          icon={securityLockIcon}
          redCircle
          title="Problemas de segurança encontrados"
          contenText="Resolva esses problemas agora para proteger sua conta."
          footerText="Proteger minha conta"
        />
        <SmallCard
          icon={moneyBagIcon}
          redCircle
          title="Faltam informações sobre seus dados bancários"
          contenText="Envie os documentos e informações necessárias para comprovar o cadastro da sua conta."
          footerText="Completar cadastro bancário"
        /> */}
        </Row>
        {/* <Row>
        <SmallCard
          icon={suitcaseIcon}
          onlyIcon
          title="Cadastre e gerencie suas empresas na CloudFox"
          contenText="Gerencie todas as suas empresas ativas e atreladas aos produtos CloudFox."
          footerText="Gerenciar empresas"
        />
        <SmallCard
          title="Como fazer login nos produtos CloudFox"
          contenText="Este guia passo a passo ajuda você a escolher as configurações de privacidade ideais para login."
          footerText="Senha e login em duas etapas"
        />
      </Row> */}
      </Container>
    </>
  );
};

export default Home;
