import React, {
  useState,
  useRef,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { IOnResult } from 'react-cep-promise';
import { PreRegisterContext as Context } from '../../../../context/PreRegisterContext';
import {
  Container,
  Row,
  CepInput,
  TextLabel,
  TextWrapper,
  HideContainer,
  GoBackContainer,
} from './styles';
import TextInput from '../../SignupComponents/TextInput';
import SelectInput from '../../SignupComponents/SelectInput';
import Button from '../../../../components/Button';
import GoBackButton from '../../../../components/GoBackButton';
import Title from '../../../../components/Title';
import ContainerPage from '../../../../components/ContainerPage';
import ErrorMessage from '../../../../components/ErrorMessage';
import { states } from '../../../../data/stateList';
import arrowRight  from '../../../../assets/arrow-right.svg'

interface StatesProps {
  name?: string;
  initials: string;
}

const list = states.map((s: StatesProps) => {
  return { value: s.initials, label: s.initials };
});

const CompanyAddress: React.FC = () => {
  const {
    companyCity,
    setCompanyCity,
    companyZipcode,
    setCompanyZipcode,
    companyState,
    setCompanyState,
    companyStreet,
    setCompanyStreet,
    companyStreetNumber,
    setCompanyStreetNumber,
    companyComplementNumber,
    setCompanyComplementNumber,
    companyNeighborhood,
    setCompanyNeighborhood,
    nextStep,
    previousStep,
  } = useContext(Context) || {};
  const [placeholder] = useState('CEP');
  const inputRef = useRef<any>();
  const [fetching, setFetching] = useState(false);
  const [hasStreet, setHasStreet] = useState('');
  const [hasCity, setHasCity] = useState('');
  const [hasState, setHasState] = useState('');
  const [hasNeighborhood, setHasNeighborhood] = useState('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [showAddress, setShowAddress] = useState<boolean>(false);

  const onResult = useCallback(
    (result: IOnResult) => {
      const { data } = result;

      if (data) {

        setHasStreet('');
        setHasState('');
        setHasCity('');
        setHasNeighborhood('');
        
        setCompanyCity(data.city);
        setCompanyStreet(data.street);
        setCompanyState(data.state);
        setCompanyNeighborhood(data.neighborhood);
        setErrorMsg('');
        setShowAddress(true);
      } else {
        setShowAddress(false);
        setErrorMsg('Este cep não existe. Por favor, insira um cep válido.');
      }
    },
    [setCompanyCity, setCompanyNeighborhood, setCompanyState, setCompanyStreet],
  );

  useEffect(() => {
    if (companyZipcode && companyStreet && companyState) {
      setShowAddress(true);
    }
  }, [companyState, companyStreet, companyZipcode]);

  const validateCompanyAddress = useCallback(() => {
    !companyStreet ? setHasStreet('Preencha com uma rua.') : setHasStreet('');
    !companyState ? setHasState('Preencha com um estado.') : setHasState('');
    !companyCity ? setHasCity('Preencha com uma cidade.') : setHasCity('');
    !companyNeighborhood ? setHasNeighborhood('Preencha com um bairro') : setHasNeighborhood('');

    companyStreet &&
      companyState &&
      companyCity &&
      companyNeighborhood &&
      nextStep();
  }, [companyCity, companyNeighborhood, companyState, companyStreet, nextStep]);

  return (
    <ContainerPage showLogo zipcodeBkg>
      <Container>
        <Title primary>Endereço da Empresa</Title>
        <TextWrapper>
          <CepInput
            id="zipcode"
            name="companyZipcode"
            fetching={fetching}
            mask="99999-999"
            placeholder={placeholder}
            setFetching={setFetching}
            onChange={(e) => setCompanyZipcode(e.target.value)}
            onResult={onResult}
            value={companyZipcode}
          />
          <TextLabel
            onClick={() => inputRef.current.getInputDOMNode().focus()}
            className="form__label"
            htmlFor="cep"
          >
            {placeholder}
          </TextLabel>
        </TextWrapper>
        {errorMsg && <ErrorMessage marginTop="24px">{errorMsg}</ErrorMessage>}
        <HideContainer show={showAddress}>
          <TextInput
            id="street"
            name="companyStreet"
            mask=""
            type="text"
            placeholder="Rua"
            value={companyStreet}
            error={hasStreet}
            onChange={(e) => setCompanyStreet(e.currentTarget.value)}
          />

          <TextInput
            id="companyNeighborhood"
            name="companyNeighborhood"
            mask=""
            type="text"
            placeholder="Bairro"
            value={companyNeighborhood}
            error={hasNeighborhood}
            onChange={(e) => setCompanyNeighborhood(e.currentTarget.value)}
          />
          <Row>
            <TextInput
              split
              id="address-number"
              name="companyNumber"
              mask=""
              type="text"
              placeholder="Número"
              value={companyStreetNumber}
              onChange={(e) => setCompanyStreetNumber(e.currentTarget.value)}
            />
            <TextInput
              split
              id="place-number"
              name="companyComplementNumber"
              mask=""
              type="text"
              placeholder="Complemento"
              value={companyComplementNumber}
              onChange={(e) =>
                setCompanyComplementNumber(e.currentTarget.value)}
            />
          </Row>
          <Row>
            <TextInput
              split
              id="city"
              name="companyCity"
              mask=""
              type="text"
              placeholder="Cidade"
              value={companyCity}
              error={hasCity}
              onChange={(e) => setCompanyCity(e.currentTarget.value)}
            />
            <SelectInput
              split
              id="state"
              name="companyState"
              placeholder="Estado"
              value={list.find((v) => v.value === companyState)}
              error={hasState}
              onChange={(e) => setCompanyState(e.value)}
              options={list}
            />
          </Row>
          <Button
            primary
            type="button"
            className="btn-continue"
            textTransform
            onClick={validateCompanyAddress}
          >
            Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
          </Button>
        </HideContainer>
        <GoBackContainer>
          <GoBackButton className='goback-button' onClick={previousStep} />
        </GoBackContainer>
      </Container>
    </ContainerPage>
  );
};

export default CompanyAddress;
