import styled from 'styled-components';
import ContentBox from '../../../../../components/ContentBox';
import arrowDownIcon from '../../../../../assets/arrow-down.svg';

export const Container = styled.div`
  .companies-list {
    margin-top: 28px;
  }

  .top-tax-info {
    margin-bottom: 30px;
  }

  .bottom-tax-info {
    line-height: 22px;
    p {
      font-size: 11px;
    }
  }

  .no-companies-message {
    font-size: 20px;
    text-align: center;
  }
`;

export const CompanyBox = styled(ContentBox)`
  display: flex;
  padding: 20px;
  margin-bottom: 20px;
  img {
    margin-right: 28px;
    width: 40px;
    height: 40px;
  }

  .dropdown-container {
    top: 10px;
  }
`;

export const Content = styled.div`
  display: block;
  width: 100%;

  .header {
    font-weight: bold;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .company-footer-row {
    margin: 25px 0 0;

    .credit-card-tax {
      display: flex;
      align-items: center;
    }

    .company-update-button {
      text-align: right;

      button {
        height: 38px;
      }
    }
  }

  .service-status {
    margin-top: 20px;

    .select-label {
      margin-bottom: 6px;
    }
  }

  .company-name {
    padding-right: 10px;
  }

  .company-document {
    text-align: end;
  }

  .tax-type {
    height: 38px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #c3c6d1;

    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 8px 15px;
    background: url(${arrowDownIcon}) no-repeat right 0.85rem center/12px 9px
      #fff;
  }

  .credit-card-tax-rate {
    font-size: 10px;
  }
`;
