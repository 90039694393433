import styled from 'styled-components';
import * as color from '../../../../styles/colors';

interface InputProps {
  error?: string;
}

export const Container = styled.div`
  margin-top: 20px;
`;

export const TextLabel = styled.label<InputProps>`
  top: 0;
  display: block;
  transition: 0.2s;
  font-weight: normal;
  font-size: 12px;
  color: ${({ error }) => (error ? color.red : '#2e2e2e')};
  font-family: 'Roboto';
  opacity: 0.8;
`;
