import React from "react";
import { Container } from "./styles";

const UsePrivacyTermsText: React.FC = () => {
  return (
    <Container>
      <strong>
        <p>1. INTRODUÇÃO</p>
      </strong>
      <p>
        Pelo presente instrumento particular, de um lado, na qualidade de
        contratante, o CLIENTE, identificado e qualificado no cadastro pleno de
        identificação; e, de outro lado, na qualidade de contratada, CLOUDFOX
        INTERMEDIAÇÃO DE SERVIÇOS E NEGÓCIOS EM GERAL LTDA., inscrita no CNPJ
        sob o nº 34.109.335/0001-64, com sede na AV. MARCOS PENTEADO DE ULHÔA
        RODRIGUES, nº 939, 8º ANDAR, ED. JACARANDÁ, TORRE 1, TAMBORÉ,
        BARUERI/SP, CEP.: 06.460-040; têm entre si justo e acordado o quanto
        segue
      </p>
      <p>
        Como condição para acesso e uso dos serviços oferecidos pela CLOUDFOX,
        consistente em emissão de cobranças e gestão de recebíveis, o
        CONTRATANTE deve declarar que fez a leitura completa e atenta das regras
        deste documento, estando plenamente ciente e de acordo com elas.
      </p>
      <strong>
        <p>2. OBJETO</p>
      </strong>
      <p>
        O objeto deste contrato é a prestação de serviços em tecnologia e
        disponibilização de ferramentas, pela CLOUDFOX ao CONTRATANTE, de gestão
        de pagamentos, por conta e ordem do CONTRATANTE, intermediação de
        transações, emissão de cobranças e transferência de recursos.
      </p>
      <p>
        2.1 O CONTRATANTE em questão declara-se ciente de que os serviços têm
        por finalidade facilitar a gestão de pagamentos e não devem ser
        confundidos com serviços financeiros, não sendo possível investimento de
        recursos ou captação de empréstimo ou quaisquer outros serviços
        disponibilizados exclusivamente por entidades bancárias.
      </p>
      <p>
        2.2 Os serviços são prestados mediante a licença de uso sobre as
        ferramentas de tecnologia CLOUDFOX, de propriedade da CLOUDFOX,
        disponível no endereço eletrônico ​www.cloudfox.net
      </p>
      <p>
        2.3 O CONTRATANTE está ciente sobre a outorga realizada, neste ato de
        contrato, mandato à CLOUDFOX, que constitui procurador, para fim de
        executar as ordens de pagamentos, transferência e resgates de recursos,
        assim como qualquer outra prática definida como necessária para a
        prestação do serviço em questão.
      </p>
      <strong>
        <p>3. SERVIÇOS INCLUSOS</p>
      </strong>
      <p>
        Os serviços oferecidos referem-se a intermediação de pagamento na
        modalidade de boleto bancário, cartão de crédito, cartão de débito, PIX
        e e-wallet, e na disponibilização de ferramentas de tecnologia para que
        o CONTRATANTE possa receber pagamentos via internet, emitidos em sua
        página virtual, sistema de gestão ou através da geração manual de
        cobranças
      </p>
      <p>
        3.1 O serviço de intermediação inclui:
        <br />
        ● (i) a captura das transações comerciais;
        <br />
        ● (ii) o controle dos pedidos gerados e valores recebidos;
        <br />
        ● (iii) controle e fornecimento de extratos sobre as movimentações
        financeiras.
        <br />
      </p>
      <p>
        3.2 O valor mínimo para emissão de um boleto e captura de cartão será de
        R$5,00 (cinco reais).
      </p>
      <p>
        3.3 O valor máximo para emissão de um boleto ou captura de cartão será
        determinado pela CLOUDFOX e poderá variar de acordo com a análise de
        risco realizada pela própria CLOUDFOX.
      </p>
      <strong>
        <p>4. HABILITAÇÃO</p>
      </strong>
      <p>
        O uso dos serviços da CLOUDFOX e a disponibilização integral de suas
        ferramentas, estão sujeitas ao registro e habilitação do CONTRATANTE
        junto à CLOUDFOX.
      </p>
      <p>
        4.1 Para se habilitar na CLOUDFOX, o CONTRATANTE deverá realizar
        primeiramente o seu registro no endereço eletrônico
        https://accounts.cloudfox.net/, e selecionar o tipo “pessoa jurídica”.
        Os serviços prestados pela CLOUDFOX, deixaram de ser ofertados a novos
        CLIENTES do tipo “pessoa física”, a partir de 06/09/2021.
      </p>
      <p>
        4.2 Após realizar o registro, atendendo as diretrizes de segurança e
        compliance, deverá o CONTRATANTE realizar agendamento de
        videoconferência para alinhamento de objetivos entre CONTRATANTE e
        CONTRATADA. O CONTRATANTE está ciente de que a videoconferência será
        gravada e feita coleta de informações pessoais, autorizando e anuindo
        com a gravação, que será concedida a título gratuito, por tempo
        indeterminado.
      </p>
      <p>
        4.3 Após o CONTRATANTE realizar o registro deverá submeter os documentos
        pessoais e de sua empresa de forma eletrônica, por meio de anexo,
        através do portal CLOUDFOX, em formato de arquivo JPEG ou PDF legíveis,
        com resolução mínima 300dpis, com tamanho máximo de 2MB, os quais
        passarão por análise de autenticidade junto à CLOUDFOX, num prazo de até
        7 (sete) dias úteis, a contar do envio. Em caso de reprovação dos
        documentos remetidos, a CLOUDFOX encaminhará o motivo para o e-mail do
        CONTRATANTE, que poderá reenviar a documentação por até 3 (três) vezes
        para nova análise, observadas as orientações recebidas.
      </p>
      <p>
        4.4 Após o cadastro do CONTRATANTE ser aprovado, poderá acessar, com seu
        usuário e senha, a área onde será feita a gestão das suas cobranças e
        uso das ferramentas da CLOUDFOX.
      </p>
      <p>
        4.5 Caberá somente à CLOUDFOX os critérios de avaliação dos dados
        comerciais fornecidos pelo CONTRATANTE para sua aprovação, reprovação ou
        atualização.
      </p>
      <p />
      <p>
        4.6 O CONTRATANTE se compromete em NÃO registrar dados comerciais que
        não tenham ligação ao seu próprio comércio, ficando sujeito à
        responsabilidade mencionada no item 9 (nove) deste documento caso o
        CONTRATANTE faça uso indevido de informações comerciais, mesmo que, por
        desconhecimento ou quaisquer outros motivos, venha ser aprovado pela
        CLOUDFOX.
      </p>
      <p>
        4.7 Os documentos necessários devem estar válidos e atualizados, a
        saber:
      </p>
      <ul>
        <li>
          ● (i) Documento de identificação pessoal, tais como: Registro geral
          (RG), Carteira Nacional de Habilitação (CNH) ou Carteira de Registro
          Nacional Migratório (CRNM);
        </li>
        <li>
          ● (ii) Cadastro de Pessoa Física (CPF) e Cadastro Nacional de Pessoa
          Jurídica (CNPJ);
        </li>
        <li>● (iii) Comprovante de residência pessoal e da empresa</li>
        <li>● (iv) Comprovante bancário;</li>
        <li>
          ● (v) Atos Constitutivos da Empresa. Ex. Contrato social com todas as
          suas alterações (contrato social consolidado) ou Certificado da
          Condição de Microempreendedor Individual (CCMEI).
        </li>
      </ul>
      <p>
        4.8 Os documentos pessoais citados no item anterior são obrigatórios e
        insubstituíveis. Em caso de perda das vias originais, poderá ser
        encaminhado à CLOUDFOX documento equivalente expedido por instituições
        oficiais, anexado ao boletim de ocorrência policial emitido em razão da
        perda/extravio dos documentos.
      </p>
      <p>
        4.9 O envio dos atos constitutivos e suas alterações são obrigatórios
        para registro de contas CLOUDFOX do tipo “pessoa jurídica” ou caso o
        CONTRATANTE use informações empresariais nas suas relações comerciais.
        Nos atos constitutivos enviados pelo CONTRATANTE deverá constar
        obrigatoriamente como sócio ou administrador, o titular da conta
        CLOUDFOX, bem como deve estar devidamente registrado nos órgãos
        competentes.
      </p>
      <p>
        4.10 Caso ocorra alteração dos dados cadastrais do CONTRATANTE, os
        documentos pessoais e empresariais deverão ser reenviados e submetidos à
        análise da CLOUDFOX, sob pena de ser suspensa a conta do CONTRATANTE até
        sua atualização.
      </p>
      <p>
        4.11 A CLOUDFOX poderá realizar procedimentos adicionais para
        comprovação de habilitação para o uso das ferramentas, a qualquer tempo
        e a seu critério, caso julgue necessário para manter a legitimidade das
        informações. Os procedimentos adicionais poderão ser os que seguem, mas
        não se limitam somente a estes:
      </p>
      <ul>
        <li>
          ● (i) Confirmação do endereço via correspondência física e retorno de
          aviso de recebimento;
        </li>
        <li>
          ● (ii) Envio de documentos pessoais de todos os sócios da empresa;
        </li>
        <li>
          ● (iii) Exigência de assinatura com reconhecimento de firma por
          autenticidade em cartório e envio a CLOUDFOX.
        </li>
      </ul>
      <p>
        4.12 O CONTRATANTE poderá emitir suas cobranças somente após seus dados
        comerciais serem aprovados.
      </p>
      <p>
        4.13 Caso haja recursos disponíveis e seu cadastro estiver pendente de
        alguma informação ou de documentos, a solicitação de retirada de recurso
        para conta bancária poderá ser realizada somente após todos os
        documentos pessoais e empresariais serem aprovados pela CLOUDFOX
      </p>
      <p>
        4.14 Caso o CONTRATANTE ou seu representante legal não façam o envio da
        documentação solicitada, independentemente do motivo, o saldo de valores
        recebidos poderá ficar bloqueado até que a situação seja regularizada.
      </p>
      <p>
        4.15 Os eventuais custos que envolvem a habilitação do CONTRATANTE,
        deverão ser custeados integralmente pelo CONTRATANTE, não sendo passivo
        de reembolso.
      </p>
      <p>
        4.16 Os prazos estabelecidos nos tópicos anteriores, referentes ao
        registro/habilitação do CONTRATANTE na CLOUDFOX, poderão sofrer
        dilatação, caso haja necessidade de confirmações adicionais ou caso a
        juízo da CLOUDFOX, haja algum risco associado à operação.
      </p>
      <p>
        4.17 A CLOUDFOX se reserva ao direito de exigir que eventuais documentos
        solicitados do CONTRATANTE sejam autenticados por autenticidade por
        órgão com fé pública.
      </p>
      <strong>
        <p>5. TARIFA PARA PRESTAÇÃO DE SERVIÇO</p>
      </strong>
      <p>
        Em contrapartida à prestação dos serviços e a licença de uso das
        ferramentas CLOUDFOX, o CONTRATANTE pagará à CLOUDFOX uma tarifa por
        cada transação que for aprovada. O valor cobrado será de acordo com a
        negociação estabelecida entre a CLOUDFOX e o CONTRATANTE. Caso nenhuma
        negociação direta seja firmada, será adotado a tabela de tarifas da
        CLOUDFOX disponível no endereço eletrônico www.cloudfox.net
      </p>
      <p>
        5.1 A tarifa será paga à CLOUDFOX, no ato da aprovação da transação, não
        passível de devolução sob nenhuma hipótese.
      </p>
      <p>
        5.2 A CLOUDFOX poderá ALTERAR as tarifas sobre os serviços prestados,
        informando previamente o CONTRATANTE através das ferramentas disponíveis
        no portal online www.cloudfox.net e/ou através do e-mail fornecido pelo
        CONTRATANTE, num prazo de até 10 (dez) dias de antecedência do reajuste.
        Em caso de NÃO concordância com as novas condições de tarifa, o
        CONTRATANTE poderá encerrar sua operação, não aderindo às novas
        condições, devendo arcar com os débitos pendentes em seu cadastro
        CLOUDFOX, caso haja algum
      </p>
      <p>
        5.3 O CONTRATANTE está ciente e concorda que, caso as tarifas cobradas
        pelo banco emissor e/ou adquirente venham a sofrer quaisquer reajustes,
        os referidos reajustes deverão ser refletidos nas tarifas cobradas pela
        CLOUDFOX, de forma imediata, sem aviso prévio, assegurando o equilíbrio
        econômico-financeiro da prestação de serviços, objeto do presente
        contrato.
      </p>
      <strong>
        <p>6.​ ​TRANSFERÊNCIAS E RECURSOS</p>
      </strong>
      <p>
        O prazo de liquidação do boleto ocorrerá de acordo com as regras do
        banco emissor. Os valores líquidos referentes aos pagamentos aprovados,
        ou seja, já com as tarifas descontadas, constarão na conta CLOUDFOX do
        CONTRATANTE e ficarão disponíveis para solicitação de saque no prazo
        referente ao plano selecionado, atendido o disposto na cláusula 6.13 e
        6.151. Todo o controle dos recursos a receber e recursos disponíveis,
        assim como solicitação de saque poderão ser feitos através do painel do
        CONTRATANTE disponibilizado pela CLOUDFOX.
      </p>
      <p>
        6.1 A CLOUDFOX manterá os recursos constantes na conta CLOUDFOX do
        CONTRATANTE sob sua custódia, em conta bancária de titularidade da
        CLOUDFOX e em instituição financeira de escolha deste último.
      </p>
      <p>
        6.2 Os créditos existentes na conta do CONTRATANTE não sofrerão nenhuma
        correção monetária, acréscimo de juros ou qualquer tipo de atualização
        financeira, permanecendo inalterados por todo o prazo em que forem
        mantidos na conta. A CLOUDFOX não terá nenhuma responsabilidade pela
        desvalorização ou desatualização monetária do valor dos créditos
        mantidos pelo CONTRATANTE em sua conta. A CLOUDFOX poderá receber juros
        sobre os valores que mantiver em seu nome. O CONTRATANTE concorda em
        transferir para a CLOUDFOX seus direitos sobre quaisquer juros relativos
        a seus recursos.
      </p>
      <p>
        6.3 A CLOUDFOX pode utilizar os recursos existentes na conta CLOUDFOX do
        CONTRATANTE, bem como destinar eventuais créditos do CONTRATANTE ao
        pagamento de condenações judiciais ou administrativas e/ou à prestação
        de garantias de responsabilidade do CONTRATANTE.
      </p>
      <p>
        6.3.1 O CONTRATANTE autoriza e aceita que a CLOUDFOX desconte de sua
        Conta CLOUDFOX, todo e qualquer valor decorrente de condenações e/ou
        acordos em ações judiciais e/ou administrativas, incluindo honorários
        advocatícios, honorários de sucumbência, custas processuais, pagas pela
        CLOUDFOX, ocasionados pela comercialização dos produtos e/ou serviços do
        CONTRATANTE, em que esta for demandada.
      </p>
      <p>
        6.3.2 Da cobrança de valores por honorários advocatícios: O CONTRATANTE
        autoriza a CLOUDFOX a descontar, do dinheiro disponível e informado em
        sua Conta CLOUDFOX, a quantia de R$1.000,00 (hum mil reais), a título de
        cobrança devida pelo CONTRATANTE em favor da CLOUDFOX, sempre que esta
        ocupar o polo passivo em demanda judicial, e no caso de reclamação
        extrajudicial autoriza o desconto da quantia de R$ 500,00 (quinhentos
        reais), por produto/serviço comercializado através da plataforma, em que
        a CLOUDFOX for isoladamente ou conjuntamente com o CONTRATANTE acionado,
        para defesa única e exclusiva da CLOUDFOX. Não sendo de responsabilidade
        da CLOUDFOX a defesa do CONTRATANTE que terá que se fazer representar
        por profissional habilitado.
      </p>
      <p>
        6.3.3 Os pedidos de Chargebacks podem ser feitos em até 365 dias, após o
        dia da compra. Assim, após o levantamento do saldo e/ou encerramento da
        Conta do Usuário, podem surgir débitos decorrentes de Chargebacks e/ou
        cancelamentos, dos quais uma vez cientificado pela CLOUDFOX deverá
        depositar o valor, em conta bancária em favor da mesma, no prazo de até
        5 (cinco) dias corridos.
      </p>
      <p>
        6.4 Para resgatar os recursos disponíveis em sua conta CLOUDFOX, o
        CONTRATANTE deverá operar no sistema bancário nacional e possuir uma
        conta em um dos bancos disponibilizados no sistema da CLOUDFOX.
      </p>
      <p>
        6.5 Para solicitação de saques o CONTRATANTE deverá cadastrar uma conta
        bancária, corrente. Se o cadastro do CONTRATANTE na CLOUDFOX for do tipo
        pessoa física, a conta bancária fornecida deverá estar registrada em seu
        CPF/MF. Caso o registro do CONTRATANTE na CLOUDFOX seja do tipo
        empresarial, a conta bancária fornecida deverá estar registrada em seu
        CNPJ.
      </p>
      <p>
        6.6 Não serão aceitos pedidos de saque para conta bancária de titular
        diferente ao registrado na CLOUDFOX. Também não será permitido pedido de
        saque para conta bancária na modalidade compartilhada ou conjunta.
      </p>
      <p>
        6.7 O valor do saque solicitado não poderá ser inferior à R$500,00
        (quinhentos reais), caso contrário, será cobrada uma taxa de R$10,00
        (dez reais) por pedido de saque.
      </p>
      <p>
        6.8 O CONTRATANTE poderá solicitar a quantidade de 1 (um) pedido de
        saque por dia.
      </p>
      <p>
        6.9 Os pedidos de saques poderão passar por confirmações diversas, antes
        de transferir os recursos. Tais confirmações ocorrerão via telefone e/ou
        em outro canal de atendimento. A CLOUDFOX poderá adotar medidas
        complementares, tais como: transferir parcialmente o montante para o
        CONTRATANTE e solicitar informações sobre o valor depositado. O processo
        de confirmação poderá se repetir sempre que a CLOUDFOX julgar necessário
        para garantir a segurança nas operações.
      </p>
      <p>
        6.9.1 A qualquer tempo a CLOUDFOX poderá criar limites de retirada
        diária e prazos para a liberação dos créditos do CONTRATANTE.
      </p>
      <p>
        6.10 O prazo para que a CLOUDFOX efetive o depósito do saque solicitado
        pelo CONTRATANTE será de até 1 (um) dia útil, após a confirmação da
        possibilidade do saque pela CLOUDFOX.
      </p>

      <p>
        6.10.1 A confirmação de possibilidade de saque a pedido da CLOUDFOX,
        junto à operadora, pode levar até 07 (sete) dias úteis para
        compensar/disponibilizar o valor à CLOUDFOX para que então possa ser
        cumprida a solicitação de saque do CONTRATANTE.
      </p>
      <p>
        6.10.2 A CLOUDFOX fará a retenção do percentual de 10% (dez por cento)
        das vendas mensais realizadas pelo CONTRATANTE, a título de margem de
        segurança para compensar eventuais chargebacks, contestações,
        atendimentos e mediações que constem como pendentes/em aberto, não sendo
        possível o pedido de saque até o mês subsequente ou até que a sua
        solução.
      </p>
      <p>
        6.10.2.1 A margem de segurança prevista para retenção de percentual de
        10% (dez por cento), pode ter seu percentual fixado em parâmetro menor
        de acordo com o risco da atividade exercida pelo CONTRATANTE, sendo
        fixada num percentual mínimo entre 5% (cinco por cento) até 10% (dez por
        cento), de acordo com análise de risco mercadológico, a critério
        exclusivo da CLOUDFOX.
      </p>
      <p>
        6.10.3 No caso de encerramento da conta do CONTRATANTE junto à CLOUDFOX
        ou na sua inoperabilidade, permanecerá retido o percentual de 10% (dez
        por cento) a título de margem de segurança conforme previsto na cláusula
        6.10.2, pelo prazo de 365 dias que responderá pelo chargeback que poderá
        ocorrer em até 365 dias, ´conforme disposto na cláusula 6.3.3, sendo
        liberado o valor para saque somente após o decurso do prazo.
      </p>
      <p>
        6.11 Os prazos estabelecidos para liquidação do boleto e cartão,
        disponibilidade do recurso e depósito do saque, poderão sofrer dilatação
        em caso de necessidade de confirmações adicionais e/ou em casos que, a
        juízo da CLOUDFOX, houver algum risco associado à operação
      </p>
      <p>
        6.12 Dependendo do nível de verificação da conta do CONTRATANTE, a
        CLOUDFOX poderá limitar, retardar ou reter por prazo indeterminado sua
        possibilidade de retirar recursos, até que o CONTRATANTE atenda aos
        pedidos de informações feitos pela CLOUDFOX e enquanto realiza a análise
        de riscos.
      </p>
      <p>
        6.12.1 Fica ciente o CONTRATANTE que a CLOUDFOX poderá realizar estorno
        de valores para os clientes pagadores/compradores caso seja constatada
        qualquer irregularidade de entrega dos produtos ou serviços em
        decorrência da falta de comprovação da licitude da transação comercial.
      </p>
      <p>
        6.13 O valor líquido do CONTRATANTE não será disponibilizado em casos
        de:
      </p>
      <ul>
        <li>
          ● (i) infração do CONTRATANTE aos termos deste contrato, quando esta
          puder ocasionar perdas para terceiros ou para a CLOUDFOX;
        </li>
        <li>
          ● (ii) indícios ou provas de que o CONTRATANTE possa estar envolvido
          em atividades e/ou transações potencialmente fraudulentas ou suspeitas
          de serem práticas criminosas mediante o uso dos sistemas da CLOUDFOX
        </li>
        <li>
          ● (iii) não postagem do produto e fornecimento do código de rastreio
          válido a fim de se possibilitar a confirmação de sua entrega ao
          consumidor/comprador.
        </li>
      </ul>
      <p>
        6.14 É de inteira responsabilidade do CONTRATANTE zelar pela
        regularidade da conta bancária fornecida, bem como pela correção das
        informações prestadas à CLOUDFOX. A CLOUDFOX não se responsabiliza e não
        fará a devolução de qualquer valor ou quantia, caso o CONTRATANTE tenha
        fornecido alguma informação errada e/ou incorreta para transferência de
        valores.
      </p>
      <p>
        6.15 O CONTRATANTE poderá solicitar o saque, em regra, após a postagem
        do produto e fornecimento do código de rastreio à CLOUDFOX, de acordo
        com o prazo selecionado previamente na plataforma, ou seja, o
        fornecimento de código de rastreio válido e em andamento do produto é
        condição essencial para o liberação do valor como disponível no extrato,
        contudo, o pedido de saque será atendido/pago ao CONTRATANTE, após a
        confirmação de entrega do produto.
      </p>
      <p>
        6.15.1 Quando do pedido de levantamento do saldo disponível na conta
        CLOUDFOX pelo CONTRATANTE, será verificado se todos os códigos de
        rastreio dos produtos são válidos e se a entrega do produto ao
        comprador/consumidor foi concluída, para efetivo
        levantamento/transferência dos valores disponíveis na conta CLOUDFOX,
        sob pena de a CLOUDFOX poder manter bloqueados os valores referentes as
        vendas ainda não concluídas.
      </p>
      <p>
        6.16 Caso haja suspeitas de fraude ou risco à operação, a CLOUDFOX
        poderá reter parte ou todo o valor da Conta Virtual para arcar com os
        possíveis Chargebacks ou Reembolsos, ou prejuízos e indenizações
        suportadas pela CLOUDFOX por culpa do CONTRATANTE ou terceiros que
        utilizem sua Conta Virtual.
      </p>
      <p>
        6.17 A CLOUDFOX se compromete a dar todo o suporte necessário para o
        CONTRATANTE, como explicar o motivo do bloqueio e fornecer uma previsão
        de liberação de valores.
      </p>
      <p>
        6.18 O percentual liberado para antecipação de cada CONTRATANTE será
        variável de acordo com critérios de análise exclusivos da CLOUDFOX.
      </p>
      <p>
        6.19 A equipe de risco poderá solicitar informações ou documentos do
        CONTRATANTE, além dos códigos de rastreio das vendas de produtos físicos
        e comprovantes de envio dos produtos digitais, para análise da
        viabilidade de qualquer antecipação.
      </p>
      <p>
        6.20 O CONTRATANTE declara-se ciente de que, uma vez transferidos os
        valores da Conta Virtual à Conta Bancária, de acordo com as instruções
        dadas pelo CONTRATANTE, tais pagamentos são definitivos e irreversíveis.
      </p>
      <p>
        6.21 Nas hipóteses de o CONTRATANTE solicitar o encerramento da conta
        junto à CLOUDFOX, será observado para liberação e levantamento total de
        saldo:
        <ul>
          <li>
            - margem de segurança de 10% (dez por cento) das vendas a fim de
            garantir chargebacks que podem vir a ocorrer nos próximos 365 dias;
          </li>
          <li>- contestações em análise/aberto;</li>
          <li>
            - pendências nos códigos informados: códigos que constem com
            problema na entrega, códigos postados (sem movimentação), códigos
            duplicados, códigos anteriores à venda;
          </li>
          <li>- mediações com mais de 07 (sete) dias sem solução.</li>
        </ul>
      </p>
      <strong>
        <p>7. SUPORTE AO CONSUMIDOR, DA MEDIAÇÃO, CHARGEBACKS E REEMBOLSOS</p>
      </strong>
      <p>
        7.1 A CLOUDFOX não é responsável pelo Suporte ao Consumidor sobre
        qualquer questão referente ao Produto. As reclamações feitas perante o
        Suporte da CLOUDFOX referentes a vícios, defeitos ou qualquer outro
        problema do produto serão encaminhadas ao CONTRATANTE responsável. Em
        relação aos chamados de Consumidores encaminhados ao CONTRATANTE, este
        deverá manter a CLOUDFOX informada sobre a resolução da questão, sendo
        reservado o direito da CLOUDFOX de acompanhar o suporte a ser feito pelo
        CONTRATANTE.
      </p>
      <p>
        7.1.1 A resposta do CONTRATANTE sobre o suporte prestado ao Consumidor
        deverá ser feita, exclusivamente, por e-mail, via chat ou Whatsapp
        presente na Plataforma.
      </p>
      <p>
        7.2 O CONTRATANTE deverá responder, em até 7 (sete) dias úteis, as
        solicitações feitas por qualquer Consumidor ao seu serviço de suporte.
        Caso não haja resposta, a CLOUDFOX poderá, a seu exclusivo critério e
        sem a necessidade de notificar o CONTRATANTE previamente, cancelar a
        transação e realizar o reembolso ao consumidor, independente do produto
        já ter sido enviado ou entregue.
      </p>
      <p>
        7.3 Caso o Consumidor apresente uma solicitação contra o CONTRATANTE no
        site “Reclame Aqui”, este terá o prazo de 1 (um) dia útil para prestar o
        devido suporte ao Consumidor, devendo informar a CLOUDFOX sobre a
        resposta ao caso. Se, no prazo estipulado, o CONTRATANTE não apresentar
        sua resposta ao Consumidor, a CLOUDFOX poderá, a seu exclusivo critério
        e sem a necessidade de notificar o CONTRATANTE previamente, cancelar
        imediatamente a Transação, efetuando o Reembolso ao Consumidor,
        independente do produto já ter sido enviado ou entregue.
      </p>
      <p>
        7.3.1 Reclamações formalizadas em face da CLOUDFOX no “Reclame Aqui” que
        forem do CONTRATANTE serão redirecionadas a pedido da CLOUDFOX para o
        “Reclame Aqui” do contratante.
      </p>
      <p>
        7.3.2 O CONTRATANTE permanecerá responsável em responder às solicitações
        dos consumidores no “Reclame Aqui”, mesmo após o encerramento da conta
        junto à CLOUDFOX.
      </p>
      <p>
        7.4. A CLOUDFOX disponibiliza ferramenta/página de serviço de
        atendimento ao consumidor para o CONTRATANTE, por onde oferece seu
        suporte ao consumidor final. Feita reclamação pelo consumidor, deverá o
        CONTRATANTE responder, em até 7 (sete) dias corridos. Caso o consumidor
        e o CONTRATANTE não cheguem a uma solução, poderá, o consumidor,
        solicitar a mediação da CLOUDFOX para solução da questão, com base no
        presente termos de uso e na boa-fé, devendo a decisão da CLOUDFOX ser
        acatada de modo integral e irrecorrível pelo CONTARTANTE e consumidor.
      </p>
      <p>
        7.4.1 A CLOUDFOX não é responsável por qualquer questão referente ao
        produto. As reclamações feitas por meio da ferramenta da CLOUDFOX
        referentes a vícios, defeitos ou qualquer outro problema do produto, se
        não respondidas no prazo de 7 (sete) dias pelo CONTRATANTE serão
        respondidas pela CLOUDFOX que poderá, a seu exclusivo critério e sem a
        necessidade de notificar o CONTRATANTE analisar o pedido do consumidor,
        e acatar pedido de cancelamento, reembolso ou estorno independente do
        produto já ter sido enviado ou entregue.
      </p>
      <p>
        7.4.2 A mediação é um processo voluntário que oferece àqueles que estão
        vivenciando uma situação de conflito a oportunidade e o espaço adequados
        para conseguir buscar uma solução que atenda a todos os envolvidos.
      </p>
      <p>
        7.5 Caso ocorra qualquer Chargeback ou Reembolso referente a qualquer
        Transação, o valor equivalente às contestações de recebimento efetuadas
        pelos Consumidores ou ao Reembolso informado pelo CONTRATANTE na Conta
        Virtual será debitado automaticamente de qualquer valor que o
        CONTRATANTE tenha recebido ou venha a receber por meio da Plataforma
        CLOUDFOX.
      </p>
      <p>
        7.6 O CONTRATANTE reconhece que a CLOUDFOX poderá efetivar o Reembolso
        quando forem levantadas suspeitas sobre a não observância dos direitos
        dos Consumidores ou sobre a operação do CONTRATANTE, a exemplo do:
      </p>

      <ul>
        <li>
          ● não envio de comprovação válida de entrega ou não envio do código de
          rastreamento dentro de 2 (dois) dias úteis após solicitação pela
          CLOUDFOX;
        </li>
        <li>
          ● mediante solicitação da CLOUDFOX, envio de um aviso de recebimento
          sem assinatura, ou envio de códigos de rastreamento cujo status (nos
          Correios ou transportadora relevante) não demonstre que o produto foi
          entregue com sucesso ao destinatário;
        </li>
        <li>
          ● transação aprovada sem código de rastreio válido por mais de 10
          (dez) dias corridos.
        </li>
        <li>● envio de códigos de rastreamento que não são válidos;</li>
        <li>
          ● entrega de produto em endereço divergente do cadastrado pelo
          Consumidor na Plataforma CLOUDFOX;
        </li>
        <li>
          ● realizar entrega em mãos sem recibo assinado e sem cópia de
          documento (RG e/ou documento válido em território nacional) que
          contenha assinatura idêntica ao recibo;
        </li>
        <li>● cadastro de produto físico como digital, ou vice-versa;</li>
        <li>
          ● o cadastro na CLOUDFOX for em nome do Consumidor e o pagamento vier
          a ser contestado pelo portador do cartão;
        </li>
        <li>
          ● envio de produtos que não estejam de acordo com as informações
          específicas da oferta;
        </li>
        <li>
          ● nos casos de dois ou mais pagamentos idênticos, não envio de
          comprovação válida da entrega de dois ou mais produtos e/ou serviços;
        </li>
        <li>
          ● não cancelamento da Transação quando solicitado pela CLOUDFOX devido
          à identificação de qualquer irregularidade;
        </li>
        <li>
          ● as Políticas ou Termos e Condições do site do CONTRATANTE não
          estiverem claros ou oneram o Consumidor;
        </li>
        <li>
          ● o Consumidor comprovar que efetuou a solicitação do cancelamento da
          compra no prazo de até 7 (sete) dias a contar de sua assinatura (data
          da compra) ou do ato de recebimento do produto ou serviço, como
          garante o art. 49 do Código de Defesa do Consumidor, e o CONTRATANTE
          não cancelar a Transação;
        </li>
        <li>
          ● o CONTRATANTE entregar produtos com vícios de qualidade que os
          tornem impróprios ou inadequados ao consumo a que se destinam;
        </li>
        <li>
          ● o Consumidor comprovar, por meio de código de rastreamento, que
          devolveu o produto ao CONTRATANTE;
        </li>
        <li>
          ● o Consumidor comprovar que tentou solucionar o problema com o
          CONTRATANTE, mas não obteve resposta;
        </li>
        <li>● o CONTRATANTE recusar o recebimento do produto devolvido;</li>
        <li>
          ● o CONTRATANTE não divulgar apropriadamente possíveis atrasos na
          entrega do produto e/ou serviços;
        </li>
        <li>
          ● o CONTRATANTE comercializar produtos/serviços diferentes da
          categoria informada em sua Conta Virtual na CLOUDFOX;
        </li>
        <li>
          ● o CONTRATANTE desmembrar o valor do produto/serviço em mais de uma
          Transação e isto for identificado pelo Consumidor como duplicidade de
          pagamento;
        </li>
        <li>
          ● realizar o envio do produto ou a prestação de serviço somente em
          data igual ou posterior a data do recebimento da contestação; entre
          outros.
        </li>
      </ul>
      <p>
        7.7 Caso o CONTRATANTE possua índice de Chargeback em volume considerado
        elevado, segundo critérios definidos exclusivamente pela CLOUDFOX, e/ou
        pelas bandeiras de cartões de crédito e/ou débito e/ou credenciadoras, a
        CLOUDFOX e/ou a respectiva bandeira poderá aplicar penalidade de multa
        ao CONTRATANTE, sem prejuízo da possibilidade de bloqueio da Conta
        Virtual, rescisão imediata do Contrato pela CLOUDFOX, alteração da
        Remuneração e da obrigação de indenização por quaisquer perdas
        ocasionadas à CONTRATADA.
      </p>
      <p>
        7.7.1 A CLOUDFOX reserva-se ao direito de bloquear a realização de
        qualquer Transação por meio da Plataforma CLOUDFOX do CONTRATANTE, caso
        o índice de Chargeback no cartão de crédito ultrapasse o equivalente a
        3% (três por cento) do total de Transações já realizadas pelo
        CONTRATANTE por meio da Plataforma CLOUDFOX. Índices acima de 1%
        sofrerão penalidades determinadas pela CLOUDFOX.
      </p>
      <p>
        7.7.2 A cada chargeback recebido pela loja do CONTRATANTE, será cobrado
        uma porcentagem (mediante avaliação da mesma) do saldo do CONTRATANTE
        para gastos operacionais de reversão de chargeback.
      </p>
      <p>
        7.8 A CLOUDFOX não reembolsará o CONTRATANTE por qualquer tarifa
        cobrável sob este Termo, se a Transação for reembolsada ou sujeita a um
        Chargeback.
      </p>
      <p>
        7.9 Caso o saldo da Conta Virtual do CONTRATANTE não seja suficiente
        para cobrir o valor integral de qualquer Chargeback e/ou Reembolso e a
        Conta Virtual permaneça com saldo negativo por mais de 30 (trinta) dias
        consecutivos, o CONTRATANTE receberá uma notificação extrajudicial
        indicando o débito em aberto, emitida pela CLOUDFOX, devendo o
        CONTRATANTE efetuar seu pagamento integral dentro de, no máximo, 10
        (dez) dias contados do recebimento da notificação, sob pena da CLOUDFOX
        tomar as medidas judiciais cabíveis.
      </p>
      <p>
        7.9.1 Após o prazo de 30 (trinta) dias, com o saldo devedor negativo,
        aplicar-se-á a incidência de multa contratual de 2% (dois por cento),
        juros de mora de 1% (um por cento) ao mês e atualizado monetariamente
        pelo índice do INPC.
      </p>
      <p>
        7.10 O CONTRATANTE compromete-se a envidar seus melhores esforços para
        reduzir ao máximo a quantidade de Chargebacks, incluindo, sem limitação,
        por meio de um Suporte eficiente.
      </p>

      <strong>
        <p>8. OBRIGAÇÕES E RESPONSABILIDADES DO USUÁRIO</p>
      </strong>
      <p>
        8.1 Não obstante as demais obrigações previstas neste Contrato, o
        CONTRATANTE se responsabiliza a fornecer à CLOUDFOX, imediatamente,
        todas as informações que lhe sejam solicitadas para fins de execução do
        Contrato, incluindo, mas não se limitando a, dados cadastrais,
        societários e/ou bancários.
      </p>
      <p>
        8.1.1 O CONTRATANTE deve fornecer tais informações à CLOUDFOX no prazo
        de 2 (dois) dias úteis após solicitação, sob pena de o CONTRATANTE
        responder, nos termos da lei, pela veracidade, certeza, suficiência e
        consistência das informações prestadas à CLOUDFOX, bem como por eventual
        divergência entre os dados informados e os dados reais e/ou oficiais,
        incluindo quaisquer perdas relacionadas e incorridas pela CLOUDFOX.
      </p>
      <p>
        8.2 O CONTRATANTE compromete-se a manter em absoluto sigilo e
        confidencialidade todas as informações que lhe são disponibilizadas nos
        termos deste Contrato e utilizá-las exclusivamente para as finalidades e
        serviços contratados, ficando vedada qualquer alteração de sua forma ou
        substância.
      </p>
      <p>
        8.2.1 No caso de sua eventual violação ou divulgação de informações
        confidenciais, inclusive por atos de seus funcionários ou terceiros, o
        CONTRATANTE será responsável pelo ressarcimento das perdas ocasionadas à
        CLOUDFOX e a terceiros, incluindo danos emergentes, lucros cessantes,
        custas judiciais e honorários advocatícios.
      </p>
      <p>
        8.3 O CONTRATANTE compromete-se a disponibilizar no rodapé do seu site
        ou link de venda do produto o seu CNPJ ou CPF/MF, dados de contato
        visíveis (e-mail, telefone), prazo de entrega e política de troca e
        devolução, uma vez que estes são requisitos exigidos pelo Decreto nº
        7.962/2013 (lei do e-commerce). A CLOUDFOX se reserva ao direito de
        rescindir o Contrato caso o CONTRATANTE não esteja adequado a esses
        parâmetros.
      </p>
      <p>
        8.4 O CONTRATANTE é o único e exclusivo responsável pela entrega,
        qualidade e correspondência dos produtos vendidos por ele, bem como
        adequação a todas as normas do Código de Defesa do Consumidor.
      </p>
      <p>
        8.4.1 Assim, a CLOUDFOX não se responsabiliza quanto aos riscos,
        nocividade, periculosidade, defeitos, vícios de qualidade, vícios de
        quantidade, insuficiência, inadequação de informações, publicidade
        enganosa e/ou abusiva, do produto e/ou serviço adquirido.
      </p>
      <p>
        8.4.2 Caso seja verificado que o conteúdo do produto ou de sua página de
        vendas viola direitos autorais, direitos de imagem, voz, intimidade ou
        de propriedade industrial (marca, patente, desenhos industriais), a
        CLOUDFOX poderá suspender, bloquear e remover a Conta do CONTRATANTE.
      </p>
      <p>
        8.5 O CONTRATANTE é o único e exclusivo responsável pelo envio dos dados
        de acesso (login e senha) de produtos digitais (tais como vídeo aulas,
        cursos) aos Consumidores.
      </p>
      <p>
        8.6 O CONTRATANTE se obriga a ressarcir integralmente a CLOUDFOX caso
        esta venha a arcar com qualquer tipo de pagamento efetuado em
        decorrência de condenação judicial, administrativa ou acordo
        extrajudicial cujo objeto esteja relacionado com atividades do
        CONTRATANTE na Plataforma CLOUDFOX, incluindo, sem limitação, qualquer
        valor que venha a ser pago pela CLOUDFOX a título de reembolso,
        indenização, multa, juros e honorários advocatícios.
      </p>
      <p>
        8.7 É de exclusiva obrigação do CONTRATANTE o recolhimento pontual de
        todo e qualquer imposto ou tributo devido sobre o valor auferido com a
        venda de produtos anunciados em seu website ou de seus estabelecimentos
        comerciais que utilizará qualquer página de pagamento hospedada para a
        realização de transações, não havendo qualquer responsabilidade da
        CLOUDFOX nesse sentido
      </p>
      <p>
        8.8 O CONTRATANTE deverá observar, respeitar e cumprir todas e quaisquer
        leis, regulamentos e instruções aplicáveis às suas atividades,
        incluindo, mas não se limitando a, as regras e exigências determinadas
        pelo Banco Central, pelas bandeiras de cartões de crédito e/ou débito,
        pelo mercado de meios de pagamento, pelo sistema de pagamentos
        brasileiro e a Lei de Prevenção à Lavagem de Dinheiro. O CONTRATANTE
        compromete-se a não realizar operações ilícitas, contrárias à moral e
        aos bons costumes, ou que o CONTRATANTE saiba ou deva saber que são
        nulas ou anuláveis, tais como transações que:
      </p>
      <ul>
        <li>
          ● O CONTRATANTE seja impedido de celebrar devido a disposições legais,
          regulamentares, contratuais ou estatutárias;
        </li>
        <li>
          ● O CONTRATANTE saiba ou deva saber que o Consumidor esteja impedido
          de celebrar devido a disposições legais, regulamentares, contratuais
          ou estatutárias;
        </li>
        <li>
          ● Tenham como motivo qualquer objetivo ilícito ou contrário à moral e
          aos bons costumes, que violem ou tenham a intenção de violar este
          Termo ou a Política de Privacidade, que tenham como objetivo fraudar
          qualquer lei ou direitos de terceiros, ou, ainda, constituam
          simulação;
        </li>
        <li>
          ● O CONTRATANTE saiba ou deva saber serem nulas ou estarem maculadas
          de qualquer vício que as torne anuláveis.
        </li>
      </ul>
      <p>
        8.9 O CONTRATANTE compromete-se a cumprir todas as disposições legais e
        regulamentares aplicáveis à sua atividade, incluindo, sem limitação, as
        disposições referentes à proteção do Consumidor, inclusive em relação à
        oferta, publicidade e fornecimento de produtos oferecidos, assumindo
        integral responsabilidade pelos riscos, inclusive pela potencial
        nocividade ou periculosidade, defeitos, vícios de qualidade ou de
        quantidade dos produtos ou serviços ofertados pelo CONTRATANTE. Entre
        outros atos que infringem as normas de proteção ao Consumidor que o
        CONTRATANTE deverá respeitar, o CONTRATANTE expressamente se compromete
        a não praticar os seguintes atos:
      </p>
      <ul>
        <li>
          ● (i) prestar informações referentes aos produtos ou serviços
          ofertados que sejam insuficientes, imprecisas ou inadequadas;
        </li>
        <li>
          ● (ii) fazer ofertas cujas indicações constantes em mensagens
          publicitárias não estejam de acordo com as indicações constantes dos
          recipientes ou embalagens dos produtos ou serviços ofertados;
        </li>
        <li>
          ● (iii) oferecer produtos ou serviços inadequados aos fins a que se
          destinam;
        </li>
        <li>
          ● (iv) realizar publicidade enganosa ou abusiva relativa aos produtos
          e serviços ofertados;
        </li>
        <li>
          ● (v) descumprir normas legais ou contratuais relativas à garantia dos
          produtos ou serviços ofertados;
        </li>
        <li>
          ● (vi) oferecer ou fazer propaganda de qualquer produto sem obter
          todas as licenças, autorizações ou permissões das autoridades
          competentes para a sua comercialização, incluindo, sem limitação,
          qualquer registro ou autorização necessária de autoridades sanitárias;
        </li>
        <li>
          ● (vii) oferecer ou fazer propaganda de qualquer produto ou serviço
          ilícito ou que seja regulado por normas legais ou regulamentares que
          impeçam ou restrinjam sua comercialização pelo CONTRATANTE, salvo se o
          CONTRATANTE obtiver todas as licenças, autorizações ou permissões
          prévias das autoridades competentes.
        </li>
        <li>
          (vii) oferecer ou fazer propaganda de qualquer produto ou serviço
          ilícito ou que seja regulado por normas legais ou regulamentares que
          impeçam ou restrinjam sua comercialização pelo CONTRATANTE, salvo se o
          CONTRATANTE obtiver todas as licenças, autorizações ou permissões
          prévias das autoridades competentes.
        </li>
      </ul>
      <p>
        8.10 O CONTRATANTE se compromete a reparar a CLOUDFOX por eventuais
        prejuízos decorrentes de multas e/ou penalidades aplicadas pelos agentes
        do mercado de cartões de pagamento (credenciadoras, bandeiras de cartões
        de crédito e/ou débito e emissores) ou por autoridades governamentais em
        virtude de atos ou fatos atribuíveis ao CONTRATANTE.
      </p>
      <p>
        8.11 O CONTRATANTE se obriga a enviar à CLOUDFOX em até 2 (dois) dias
        úteis todos os códigos de rastreio dos produtos físicos vendidos por
        meio da Plataforma CLOUDFOX, quando solicitado, para que a CLOUDFOX
        possa averiguar a legalidade da operação, podendo a Conta Virtual ficar
        bloqueada temporariamente durante esta análise.
      </p>
      <p>
        8.12 O CONTRATANTE deverá efetivar seu cadastro no site ”Reclame Aqui“ e
        no site Consumidor.gov.br em até 48 (quarenta e oito) horas após
        aprovação do seu cadastro pela CLOUDFOX, sob pena de bloqueio do
        Checkout de pagamento até sua regularização.
      </p>
      <strong>
        <p>9. REGRAS DE CONDUTA</p>
      </strong>
      <p>
        9.1 O CONTRATANTE se compromete a não utilizar a Plataforma CLOUDFOX
        para a publicação ou divulgação de conteúdo:
      </p>
      <ul>
        <li>
          ● que tenha caráter discriminatório, obsceno, ofensivo, ameaçador,
          abusivo, vexatório, prejudicial, ou que contenha expressões de ódio
          contra pessoas ou grupos, contenha insultos ou ameaças religiosas ou
          raciais, ou que incentive danos morais e patrimoniais, ou que possa
          violar qualquer direito de terceiros;
        </li>
        <li>
          ● protegido por direitos autorais ou que, por qualquer razão, viole
          direitos de terceiros.
        </li>
      </ul>

      <p>9.2 Além disso, ficam expressamente proibidas as seguintes ações:</p>
      <ul>
        <li>
          ● ceder, vender, alugar ou de qualquer forma transferir a Conta
          Virtual a terceiros;
        </li>
        <li>
          ● coletar dados e informações de terceiro Usuário, bem como divulgar
          qualquer informação de caráter privado ou confidencial;
        </li>
        <li>
          ● usar a marca da CLOUDFOX para publicidade ou credibilidade de
          projetos sem a expressa autorização e prévia autorização da CLOUDFOX,
          por escrito;
        </li>
        <li>
          ● usar a CLOUDFOX para publicar ou transmitir vírus, worm, Cavalo de
          Tróia, easter egg, time bomb, spyware ou outro código, arquivo ou
          programa de computador malicioso que seja prejudicial ou invasivo, ou
          cuja intenção seja danificar, tomar controle da operação ou monitorar
          o uso de qualquer hardware, software ou equipamento, seja nosso, dos
          Usuários, ou de terceiros, relacionados ou não com a CLOUDFOX;
        </li>
        <li>
          ● fazer qualquer alteração na Plataforma CLOUDFOX ou seu conteúdo,
          assim como, e não somente, modificar, adaptar, fazer engenharia
          reversa, abstendo-se de qualquer ato que possa prejudicar ou alterar a
          integridade ou a operação regular da Plataforma CLOUDFOX e seus
          servidores, respondendo civil e criminalmente por todo ato ilegal a
          que der causa;
        </li>
        <li>
          ● criar banco de dados, transferir ou armazenar conteúdo da Plataforma
          CLOUDFOX;
        </li>
        <li>
          ● usar robô, spider ou qualquer tipo de aplicativo ou dispositivo,
          manual ou automático, para recuperar ou indexar conteúdo da Plataforma
          CLOUDFOX;
        </li>
        <li>
          ● usar a CLOUDFOX para encaminhar mensagens não solicitadas e/ou não
          autorizadas (“Spam”) aos demais Usuários e/ou a terceiros;
        </li>
        <li>
          ● usar a CLOUDFOX para violar quaisquer direitos legais de terceiros,
          ou para obter ou coletar informações de identificação de Usuários da
          CLOUDFOX;
        </li>
        <li>
          ● e utilizar qualquer forma que possa ser considerada fraudulenta para
          obter vantagem, para si ou para outrem.
        </li>
      </ul>
      <p>
        9.3 Sem prejuízo de qualquer obrigação ou regra de conduta assumida pelo
        CONTRATANTE por meio deste Termo, o CONTRATANTE compromete-se a, durante
        toda a vigência deste Termo, a cumprir as seguintes regras:
      </p>

      <ul>
        <li>
          ● Não possuir envolvimento, direta ou indiretamente, em atividades
          enganosas ou fraudulentas de qualquer natureza;
        </li>
        <li>
          ● Não possuir envolvimento, direta ou indiretamente, em práticas de
          marketing potencialmente enganosas ou abusivas;
        </li>
        <li>
          ● Não possuir envolvimento em atividades que estejam relacionadas a
          lavagem de dinheiro ou resultem em lavagem de dinheiro;
        </li>
        <li>
          ● Não possuir envolvimento em atividades que violem as regras das
          bandeiras de cartões de crédito e/ou débito; e
        </li>
        <li>● Possuir CNPJ ou CPF/MF ativo.</li>
      </ul>

      <p>
        9.4 O CONTRATANTE não poderá utilizar a Plataforma da CLOUDFOX para
        desenvolver atividades ou comercializar bens e/ou serviços não
        autorizados por lei ou em desacordo com este Termo, independente de sua
        licitude, entre eles, de forma exemplificativa, estão:
      </p>

      <ul>
        <li>● Serviços de acompanhantes;</li>
        <li>● Casas de prostituição;</li>
        <li>● Partidos políticos;</li>
        <li>
          ● Casas de jogos ilegais, incluindo casas online, salões de jogos de
          azar e competições esportivas fantasiosas com prêmios ou promessas de
          prêmios para os vencedores;
        </li>
        <li>● Comércio de pornografia infantil e bestialidades;</li>
        <li>● Farmácias e drogarias online;</li>
        <li>
          ● Marketing direto, relacionados a clientes de tele - serviços Ativos
          (ligações de saída e de entrada);
        </li>
        <li>● Serviços relacionados a preparativos de viagens;</li>
        <li>
          ● Apostas, incluindo bilhetes de loteria, fichas de cassino, apostas
          de corrida de cavalos dentro e fora das pistas de corridas;
        </li>
        <li>
          ● Oportunidades de negócio – comerciantes físicos ou online que
          possuam, em suas razões sociais ou nomes fantasia, incluindo, mas não
          se limitando às palavras “Google”, “vantagem(ns) fácil(eis)”,
          “enriquecimento rápido”, “trabalho a partir da residência”;
        </li>
        <li>
          ● Comerciantes de produtos contrabandeados ou falsificados, mesmo que
          anunciados como originais;
        </li>
        <li>
          ● Comerciantes, receptadores ou atravessadores de mercadoria roubada
          ou furtada;
        </li>
        <li>
          ● Esquemas de enriquecimento rápido, incluindo, mas não se limitando
          aqueles comerciantes que tenham em suas razões sociais ou nomes
          fantasia, as palavras “Dinheiro”, “Fortuna”, “Riquezas”,
          “Financiamento” ou “Lucros”;
        </li>
        <li>● Horóscopo ou cartomantes, em lojas físicas ou online;</li>
        <li>● Salões de massagem que não possuam licença para operar;</li>
        <li>
          ● Comércio de entorpecentes ilícitos de qualquer tipo, origem ou fim;
        </li>
        <li>
          ● Comércio de maconha em todas suas formas, ainda que anunciada para
          fins medicinais;
        </li>
        <li>
          ● Empresas de marketing multinível que se assemelham à estrutura de
          pirâmide, bem como quaisquer estruturas não permitidas pela legislação
          em vigor;
        </li>
        <li>
          ● Estabelecimentos Comerciais que se utilizem de trabalho escravo, mão
          de obra infantil ou quaisquer outras práticas de trabalho proibidas
          pela legislação brasileira;
        </li>
        <li>
          ● Estabelecimentos Comerciais que realizam atividades relacionadas à
          doações e/ou “vaquinhas” que envolvem montantes acima do limite
          determinado pela Contratada;
        </li>
        <li>
          ● Quaisquer outros estabelecimentos comerciais, comerciantes ou
          profissionais que não possuam os registros ou licenças necessárias
          para operar em seu ramo de atuação, incluindo, mas não se limitando a
          médicos, advogados, dentistas e taxistas;
        </li>
        <li>
          ● Estabelecimentos Comerciais que vendem suplementos proibidos pela
          Anvisa;
        </li>
        <li>● Agências de cobranças;</li>
        <li>
          ● Estabelecimentos Comerciais que fornecem ou restituem aos
          Portadores, por qualquer motivo, quantias em dinheiro (moeda nacional
          ou estrangeira, cheques, ordens de pagamento ou títulos de crédito),
          façam Transações simuladas ou capital de giro;
        </li>
        <li>● Tabacarias online;</li>
        <li>● Criptomoedas e moedas virtuais;</li>
        <li>● Venda de seguidores em redes sociais;</li>
        <li>● Cyberlocker;</li>
        <li>● Armas de fogo;</li>
        <li>● Conteúdo adulto.</li>
      </ul>
      <p>
        9.5 A CLOUDFOX poderá, a qualquer tempo e sem aviso, rescindir o
        presente contrato e bloquear, a seu critério, a Conta Virtual de
        qualquer CONTRATANTE que venha a infringir a lei e/ou os termos deste
        instrumento, ou, ainda, que possua saldo devedor em sua Conta Virtual,
        impedindo-se, assim, toda e qualquer atividade na Plataforma CLOUDFOX,
        sem qualquer direito a indenização ou ressarcimento pela CLOUDFOX,
        devendo arcar com todo e qualquer prejuízo que o bloqueio venha a causar
        a outro Usuário ou a terceiros.
      </p>
      <p>
        9.6 A CLOUDFOX poderá, ainda, bloquear temporariamente qualquer Conta
        Virtual envolvida em atividades suspeitas ou atividades que representem
        risco para a CLOUDFOX, para averiguação e análise da legalidade da
        operação.
      </p>
      <p>9.6.1 Entende-se como atividades suspeitas ou que apresentam risco:</p>

      <ul>
        <li>● Conta Virtual com alto índice de Chargebacks;</li>
        <li>
          ● suspeita de não envio do produto aos Consumidores, ou envio de
          produtos distintos do que foram comprados, que pode se dar por meio da
          análise de reclamações dos Consumidores no Reclame Aqui ou no canal
          próprio da CLOUDFOX;
        </li>
        <li>● operações sinalizadas pelo controle antifraude da CLOUDFOX;</li>
        <li>● informar código de rastreio falso;</li>
        <li>
          ● cadastro do produto como digital quando na realidade é produto
          físico; e quaisquer outros indícios apurados pela equipe de risco da
          CLOUDFOX que representem suspeita de ilegalidade ou irregularidade na
          operação do CONTRATANTE.
        </li>
      </ul>

      <p>
        9.6.2 Atividades que representam risco para a CLOUDFOX são entendidas
        como situações em que o saldo do CONTRATANTE possa ficar negativo na
        Conta Virtual ou situações que signifiquem deterioração da relação da
        CLOUDFOX com as bandeiras de cartões de crédito e/ou débito e
        Credenciadores.
      </p>
      <p>
        9.7 Caso a CLOUDFOX entenda, a seu exclusivo critério, haver qualquer
        indício de ilicitude, fraude ou violação à Lei de Prevenção à Lavagem de
        Dinheiro em qualquer Transação ou atividade do CONTRATANTE em sua Conta
        Virtual, ou se houver denúncia contra o CONTRATANTE, a CLOUDFOX poderá,
        a seu exclusivo critério e sem necessidade de prévio aviso, até que se
        esclareça a situação, bloquear a Conta Virtual do CONTRATANTE.
      </p>
      <p>
        9.8 No caso de rescisão deste instrumento e/ou bloqueio da Conta Virtual
        do CONTRATANTE por conduta ilegal ou que viole quaisquer disposições
        aqui estabelecidas, o saldo disponível ao CONTRATANTE por ocasião do
        bloqueio poderá ficar retido ou ser consignado judicialmente para
        eventual reparação de dano ou Reembolso aos Consumidores.
      </p>

      <strong>
        <p>10. ESTORNOS E DEVOLUÇÕES</p>
      </strong>

      <p>
        Qualquer estorno ou devolução de pagamento será debitado na conta
        CLOUDFOX do CONTRATANTE.
      </p>

      <p>
        10.1 A CLOUDFOX debitará na conta CLOUDFOX do CONTRATANTE o montante
        equivalente às contestações de recebimento da mercadoria ou da prestação
        do serviço contra o CONTRATANTE, ou ainda, em caso de desistência da
        compra no prazo legal considerando a legislação vigente para vendas no
        comércio eletrônico.
      </p>

      <p>
        10.2 A CLOUDFOX estornará ao sacado/pagador os pagamentos irregulares,
        ou seja, que possuem inconsistências de informações que constam no
        pedido, como exemplo:
      </p>

      <ul>
        <li>
          ● (i) Pagamento menor do que o valor original – O pedido não será
          aprovado e o valor pago será estornado ao sacado/pagador;
        </li>
        <li>
          ● (ii) Pagamento maior do que o valor original – O pedido será
          aprovado e o excedente ao valor do pedido será estornado ao
          sacado/pagador;
        </li>
        <li>
          ● (iii) Pagamento duplicado – O pedido será aprovado e o valor pago em
          duplicidade será estornado ao sacado/pagador;
        </li>
      </ul>

      <p>
        10.3 As tarifas que envolvem a operação de estorno ao sacado/pagador
        serão pagas pelo CONTRATANTE de forma à vista, debitado no saldo da
        conta CLOUDFOX do CONTRATANTE.
      </p>

      <strong>
        <p>11. PROPRIEDADE INTELECTUAL</p>
      </strong>

      <p>
        O CONTRATANTE se compromete a não infringir quaisquer direitos relativos
        a marcas, patentes ou, ainda, direito de propriedade, de representação
        e/ou autoral, responsabilizando-se perante a CLOUDFOX e terceiros
        interessados pela obrigação assumida neste item, obrigando-se a arcar
        integralmente por todo ônus gerado pelo eventual descumprimento deste
        item, mesmo que ocorra de forma não intencional.
      </p>

      <strong>
        <p>12. RESPONSABILIDADE DO CONTRATO</p>
      </strong>

      <p>
        O CONTRATANTE é responsável pelo uso das ferramentas CLOUDFOX, nos
        termos deste contrato, comprometendo-se a observar e cumprir
        integralmente a legislação nacional aplicável, as normas e políticas de
        uso, segurança e privacidade da CLOUDFOX e de seus parceiros comerciais.
      </p>
      <p>
        12.1 O CONTRATANTE é responsável pela entrega do produto e/ou serviço
        referente à operação financeira, possibilitando o perfeito estado de uso
        e/ou o deleite do sacado/pagador.
      </p>
      <p>
        12.2 O CONTRATANTE deverá manter no mínimo um endereço de e-mail e um
        número de telefone, ambos ativos, para manter um canal de comunicação
        com a CLOUDFOX, assegurando assim os prazos operacionais estabelecidos
        por este documento e também a sua permanência no uso dos serviços.
      </p>
      <p>
        12.3 O uso da ferramenta CLOUDFOX através de sistemas de terceiros é de
        inteira responsabilidade do CONTRATANTE e do seu fornecedor.
      </p>
      <p>
        12.4 O sacado/pagador ou pagador da cobrança gerada na CLOUDFOX, que por
        sua vez é cliente do CONTRATANTE, se tornará também cliente da CLOUDFOX
        no ato da emissão da cobrança. Desta forma, entendendo que o cliente do
        CONTRATANTE também é cliente da CLOUDFOX, fica estabelecido que a
        CLOUDFOX poderá requerer a qualquer tempo, dados pessoais do
        sacado/pagador como: notas fiscais, comprovante de entrega do
        produto/serviço, ou ainda, qualquer outro documento ou comprovação
        referente à operação de venda, ficando o CONTRATANTE ciente da
        obrigatoriedade do fornecimento integral de tais informações.
      </p>
      <p>
        12.5 A CLOUDFOX manterá suporte técnico e operacional ao serviço,
        realizado diretamente pela CLOUDFOX ou por terceiro por ele contratado,
        sob sua responsabilidade. O suporte será prestado das 9:00h às 18:00h em
        dias úteis, via chat ou através de correio eletrônico
        (help@cloudfox.net). Para esclarecimento de dúvidas de ordem não
        funcional e problemas no serviço, o CONTRATANTE poderá usar o contato
        telefônico, devendo este assumir integralmente eventuais custos de
        ligações locais e interurbanas. O serviço de suporte prestado via e-mail
        ou salas de bate-papo serão gratuitos.
      </p>
      <p>
        12.6 O CONTRATANTE se declara ciente de que as soluções de pagamentos da
        CLOUDFOX se destina tão somente a efetivar pagamentos e recebimentos em
        moeda corrente nacional, bem como assegura que todos os recursos
        creditados na conta CLOUDFOX são oriundos de fontes lícitas e são
        declarados às autoridades competentes.
      </p>
      <p>
        12.7 O CONTRATANTE é integralmente responsável pelo seu login e senha,
        os quais deve manter em sigilo.
      </p>
      <p>
        12.8 O CONTRATANTE se compromete a instalar e manter atualizados
        softwares anti-spywares, antivírus e outros que objetivem evitar a
        violação do computador que acessa o software de solução de pagamentos da
        CLOUDFOX.
      </p>

      <strong>
        <p>13. SEGURANÇA</p>
      </strong>

      <p>
        A CLOUDFOX monitora constantemente os clientes e transações e pode, a
        qualquer tempo, manifestar não interesse na prestação do serviço, por
        entender que o CONTRATANTE ou transações não atendem ao perfil de seus
        negócios. Nesta hipótese, o CONTRATANTE será impedido de efetuar novas
        cobranças, sendo que os demais serviços serão processados normalmente,
        incluindo transferências e compensação das cobranças já emitidas.
      </p>
      <p>
        13.1 A CLOUDFOX se reserva no direito de validar transações do
        CONTRATANTE junto a seus clientes, com expressa ressalva que em caso de
        dificuldades de contato com os clientes do CONTRATANTE, a(s)
        transação(ões) não será(ão) efetivada(s) até que a CLOUDFOX comprove sua
        legitimidade. O CONTRATANTE desobriga a CLOUDFOX de qualquer dano,
        prejuízo, multa, juros desencadeados pelo atraso em pagamentos e/ou
        repasses.
      </p>
      <p>
        13.2 Em casos em que não seja transparente a legitimidade da transação e
        a CLOUDFOX não tenha segurança suficiente para efetivar ou desfazer a
        transação, valores custodiados pela CLOUDFOX serão bloqueados, e
        depositados em juízo a disposição do CONTRATANTE, visto que a CLOUDFOX
        não possui elementos comprobatórios suficientes que promovam o
        encerramento da questão.
      </p>
      <p>
        13.3 A CLOUDFOX se reserva o direito de eleger as transações que irá
        intermediar, independente do critério de legitimidade ou ilegitimidade
        da transação. Neste caso, os valores serão devolvidos integralmente ao
        sacado/pagador, cliente do CONTRATANTE.
      </p>
      <p>
        13.4 Se a CLOUDFOX constatar a existência de dados cadastrais incorretos
        relacionados ao CONTRATANTE e este se recusar a enviar os documentos
        solicitados para a solução da divergência, a conta CLOUDFOX do
        CONTRATANTE pode ser bloqueada, não assistindo ao CONTRATANTE direito de
        indenização.
      </p>
      <p>
        13.5 A CLOUDFOX pode reter todo e qualquer valor que o CONTRATANTE tiver
        a receber se, a juízo da CLOUDFOX, houver alto nível de risco
        operacional ou de crédito associado ao desempenho do CONTRATANTE, à sua
        conta CLOUDFOX ou a qualquer das transações relacionadas a este ou ao(s)
        outro(s) recebedor(es).
      </p>
      <p>
        13.6 Não é permitido o acesso às áreas de programação da CLOUDFOX, seu
        banco de dados ou qualquer outro conjunto de informações que faça parte
        da atividade de Webmastering.
      </p>
      <p>
        13.7 Não é autorizado ao sacado/pagador ou CONTRATANTE realizar ou
        permitir engenharia reversa, nem traduzir, descompilar, copiar,
        modificar, reproduzir, alugar, sublicenciar, publicar, divulgar,
        transmitir, emprestar, distribuir ou, de outra maneira, dispor das
        ferramentas da CLOUDFOX e de suas funcionalidades.
      </p>
      <p>
        13.8 Na CLOUDFOX é proibida a utilização de softwares spider, ou de
        mineração de dados, de qualquer tipo ou espécie, além de outro aqui não
        tipificado, mas que atue de modo automatizado, tanto para realizar
        operações massificadas ou para quaisquer outras finalidades.
      </p>

      <strong>
        <p>14. VIGÊNCIA E RESCISÃO</p>
      </strong>

      <p>
        Este contrato possui validade por prazo indeterminado, entrando em vigor
        na data do aceite através do portal online www.cloudfox.net ou na data
        de início da utilização das ferramentas da CLOUDFOX pelo CONTRATANTE, o
        que for mais recente.
      </p>

      <p>
        14.1 Este contrato poderá ser encerrado pela CLOUDFOX, sem nenhum ônus e
        a qualquer momento, mediante devida notificação enviada ao CONTRATANTE
        através das ferramentas disponíveis no portal online www.cloudfox.net
        e/ou os meios de contato fornecidos pelo CONTRATANTE.
      </p>

      <p>
        14.2 O encerramento deste contrato poderá ocorrer de forma imediata e
        sem aviso prévio, caso seja identificado o descumprimento do CONTRATANTE
        a qualquer item ou subitem previsto neste documento.
      </p>

      <p>
        14.2.1 A conta do CONTRATANTE será encerrada no caso de inatividade pelo
        período de 12 (doze) meses consecutivos. Compreende-se como inatividade
        a conta não movimentada pelo usuário.
      </p>

      <p>
        14.3 O CONTRATANTE poderá encerrar o contrato mediante notificação
        enviada à CLOUDFOX por escrito, via carta-postal para o endereço da
        CLOUDFOX ou e-mail, num prazo de 30 (trinta) dias de antecedência.
      </p>

      <p>
        14.4 Após receber o pedido de cancelamento da conta por parte do
        CONTRATANTE poderá ser adotado o seguinte procedimento:
      </p>

      <ul>
        <li>
          ● (i) A CLOUDFOX irá encaminhar documento com os termos de
          encerramento de conta para o endereço ou e-mail do solicitante;
        </li>
        <li>
          ● (ii) O titular da conta CLOUDFOX deverá assinar o termo de
          encerramento e em seguida reconhecer como verdadeiro em cartório de
          sua região com competência para tal;
        </li>
        <li>● (iii) Encaminhar via carta-postal ao endereço da CLOUDFOX;</li>
        <li>
          ● (iv) Após recebimento, a CLOUDFOX analisará a autenticidade, e se
          houver parecer positivo prosseguirá com o cancelamento;
        </li>
      </ul>

      <p>
        14.5 Os eventuais custos que envolvem os itens 10.3 e 10.4 deste
        documento, deverão ser custeados integralmente pelo CONTRATANTE
        solicitante, não passíveis de reembolso.
      </p>
      <p>
        14.6 Em caso de rescisão por qualquer uma das partes e por qualquer
        motivo, o CONTRATANTE declara-se ciente de que, caso haja débitos
        gerados pelo uso das ferramentas CLOUDFOX ou pelo descumprimento dos
        itens deste documento, em favor da própria CLOUDFOX ou a terceiros,
        deverão ser pagos.
      </p>
      <p>
        14.7 Os débitos citados no item anterior, caso existam, serão pagos em
        primeiro momento com as verbas disponíveis do próprio CONTRATANTE no
        sistema CLOUDFOX, e caso os valores não sejam suficientes o CONTRATANTE
        deverá transferir à CLOUDFOX os recursos faltantes no prazo máximo de 10
        (dez) dias corridos após a comunicação de rescisão. Caso não haja
        débitos pendentes no ato da rescisão, os recursos disponíveis do
        CONTRATANTE serão transferidos para sua conta bancária registrada do
        sistema.
      </p>
      <p>
        14.8 O presente contrato será também rescindido de pleno direito,
        independentemente de qualquer notificação ou interpelação, judicial ou
        extrajudicial, no caso de o CONTRATANTE, de qualquer forma, comprometer
        a imagem pública da CLOUDFOX e/ou de qualquer empresa associada à
        CLOUDFOX na prestação do serviço.
      </p>

      <strong>
        <p>15. LIMITAÇÕES</p>
      </strong>

      <p>
        Na extensão máxima permitida pela lei em vigor, o serviço é fornecido
        “no estado em que se encontra” e “conforme a disponibilidade”, com todas
        as falhas e sem garantia de qualquer espécie.
      </p>

      <p>
        15.1 A CLOUDFOX não garante que as funções contidas no serviço atendam
        às necessidades do CONTRATANTE, que a operação do serviço será
        ininterrupta ou livre de erros, que qualquer serviço continuará
        disponível, que os defeitos no serviço serão corrigidos ou que o serviço
        será compatível ou funcione com qualquer serviço, aplicações ou serviços
        de terceiros.
      </p>

      <strong>
        <p>16. REVISÃO</p>
      </strong>

      <p>
        A CLOUDFOX revisará periodicamente este contrato e poderá efetuar
        adequações sobre a prestação dos serviços e o uso das ferramentas
        disponibilizadas para este fim, por esse motivo consulte-o com certa
        frequência, no endereço eletrônico
        https://cloudfox.net/sirius/use-terms-and-privacy-policy.html.
      </p>
      <p>
        16.1 Em caso de alteração neste documento a CLOUDFOX notificará o
        CONTRATANTE através das ferramentas disponíveis no portal online
        www.cloudfox.net e/ou os meios de contato fornecidos pelo CONTRATANTE,
        passando a vigorar imediatamente.
      </p>
      <p>
        16.2 Caso o CONTRATANTE esteja em desacordo com a mudança no contrato
        poderá encerrá-lo observando o item 14 (quatorze) deste documento e seus
        subitens.
      </p>
      <p>
        16.3 Em caso de mudança no contrato, a continuidade do uso das
        ferramentas da CLOUDFOX será entendido como aceite aos termos das
        atualizações, as quais serão integralmente aplicáveis.
      </p>

      <strong>
        <p>17. POLÍTICA DE PRIVACIDADE</p>
      </strong>

      <p>
        Nós reconhecemos, nos termos da Política de Privacidade e da legislação
        aplicável, a confidencialidade e segurança das informações prestadas
        pelos CONTRATANTES, empenhando-se em resguardar o seu sigilo, salvo por
        força de lei ou ordem judicial.
      </p>

      <strong>
        <p>18. TRATAMENTO E PROTEÇÃO DE DADOS</p>
      </strong>

      <p>
        Nos termos da Lei Geral de Proteção de Dados (Lei nº 13.709/18), o
        CONTRATANTE reconhece que a CLOUDFOX realiza o tratamento de dados
        pessoais com finalidades específicas, tais como: para o devido
        cumprimento das obrigações legais e regulatórias, para o exercício
        regular de direitos e para a proteção do crédito, bem como sempre que
        necessário para a execução do contrato firmado com seus clientes ou para
        atender aos interesses legítimos da CLOUDFOX, clientes ou de terceiros.
        Para qualquer outra finalidade, para a qual a lei não dispense a
        exigência do consentimento do CONTRATANTE, o tratamento estará
        condicionado à manifestação livre, informada e inequívoca do titular
        concordando com o tratamento de seus dados pessoais para aquela
        determinada finalidade.
      </p>
      <p>
        18.1 O CONTRATANTE expressamente autoriza que as informações cadastrais
        fornecidas, tais como nome, CPF, CNPJ, endereço, e-mail, telefone, sejam
        mantidas pela CLOUDFOX, bem como autoriza que tais informações possam
        ser eventualmente compartilhadas com: (i) às autoridades públicas
        competentes que as solicitarem formalmente, nos termos da legislação
        brasileira, em especial a Lei nº 12.965/2014 (“Marco Civil da
        Internet”); (ii) a parceiros estratégicos, comerciais ou técnicos das
        empresas para a prestação dos Serviços; e (iii) as partes envolvidas em
        uma mesma negociação, como, por exemplo, para um cliente envolvido em
        uma transação objeto de disputa.
      </p>
      <p>
        18.2 A CLOUDFOX poderá compartilhar seus dados pessoais estritamente
        necessários para as respectivas finalidades específicas, com
        fornecedores e prestadores de serviços, incluindo empresas de
        telemarketing, de processamento de dados, de tecnologia voltada à
        prevenção a fraudes, correspondentes bancários, bandeiras,
        credenciadoras e empresas ou escritórios especializados em cobrança de
        dívidas.
      </p>
      <p>
        18.3 A CLOUDFOX poderá fornecer seus dados pessoais sempre que estiver
        obrigado, seja em virtude de disposição legal, ato de autoridade
        competente ou ordem judicial.
      </p>
      <p>
        18.4 Mesmo após o término deste contrato, os dados pessoais e outras
        informações a ele relacionadas poderão ser conservados pela CLOUDFOX
        para cumprimento de obrigações legais e regulatórias pelos prazos
        previstos na legislação vigente.
      </p>

      <strong>
        <p>19. FORO</p>
      </strong>

      <p>
        O presente documento será interpretado segundo a legislação brasileira,
        no idioma português, sendo eleito o Foro da comarca de Resende/RJ, local
        do escritório administrativo da CLOUDFOX, para dirimir toda e qualquer
        questão decorrente do presente instrumento, renunciando expressamente a
        qualquer outro, por mais privilegiado que seja.
      </p>

      <p>Resende, 20 de Setembro de 2021.</p>
    </Container>
  );
};

export default UsePrivacyTermsText;
