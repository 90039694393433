import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Container, Title, SwitchContainer, PixInfoBanner, ValidatePixContainer, LastVerification } from './styles';
import Form from '../../../components/Form';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import Select from '../../../components/Select';
import useBankList from '../../../hooks/useBankList';
import { ICompany } from '../../../entities/Company';
import api from '../../../services/api';
import { AccountsContext as Context } from '../../../context/AccountsContext';
import SwitchInput from '../../../components/SwitchInputWithoutState';
import { getFomattedDate, getMinuteDiff } from '../../../utils/functions';

import ConfirmPixKeyContent from '../../../components/ModalContents/ConfirmPixKeyContent'

// import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'

import orangeDot from '../../../assets/orange_dot.svg'
import redDot from '../../../assets/red_dot.svg'
import greenDot from '../../../assets/green_dot.svg'

import useToggle from '../../../hooks/useToggle';
import Modal from '../../../components/ModalAdmin';

interface CompanyProfileProps {
  company: ICompany | undefined;
  sendDataToCompanyDetail?: any;
}

interface BankProps {
  name: string;
  code: string;
}

interface ParamTypes {
  id: string
}

type FormData = {
  bank: string;
  account: string;
  account_digit: string;
  agency: string;
  agency_digit: string;
  active_flag: number;
  has_pix_key: boolean;
  pix_key_situation : string;
};

const BankAccountForm: React.FC<CompanyProfileProps> = ({
  company,
  sendDataToCompanyDetail,
}) => {
  const { setDisableUploadCompanyBankAccountDocuments, setCompany, lastVerificationPixKey, setLastVerificationPixKey } = useContext(Context);
  const [bank, setBank] = useState<string>('');
  const [has_pix_key, setHasPixKey] = useState<boolean | undefined>(false);
  const [pixStatus, setPixStatus] = useState<string | undefined>('');
  const [pixKeySituation, setPixKeySituation] = useState<string | undefined>(company?.pix_key_situation);
  const [loading, setLoading] = useState<number>(0);
  const bankList = useBankList();
  // const MySwal = withReactContent(Swal)
  const [isModalOpen, setIsModalOpen] = useToggle(false);

  const handleConfirmPixKey = () => {
    setIsModalOpen();
    changePix();
    setPixStatus('Ativa');
  }

  const pixConfirmModal = (
    <Modal isOpen={isModalOpen} onRequestClose={setIsModalOpen} closeModal={setIsModalOpen} smallWidth={true}>
      <ConfirmPixKeyContent onConfirm={handleConfirmPixKey} onCancel={setIsModalOpen} companyName={company?.fantasy_name}/>
    </Modal>
  );

  const params = useParams<ParamTypes>()

  const { register, reset, setValue, handleSubmit, errors  } = useForm<FormData>(
    {
      defaultValues: {
        ...company,
      },
    },
  );

  useEffect(() => {
    if(company){
      setHasPixKey(company.has_pix_key)
      if(company.has_pix_key){
        setPixStatus('Ativa')
      }else{
        setPixStatus('Desativada')
      }
    }
  },[company])

  const list = bankList.map((s: BankProps) => {
    return { value: s.code, label: s.name };
  });

  useEffect(() => {
    reset({
      ...company,
    });

    if (company?.bank) {
      setBank(company?.bank);
    }

    if (
      company?.bank === '' ||
      company?.bank === null ||
      company?.account === '' ||
      company?.account === null ||
      company?.agency === '' ||
      company?.agency === null
    ) {
      setDisableUploadCompanyBankAccountDocuments(true);
    } else {
      setDisableUploadCompanyBankAccountDocuments(false);
    }
  }, [company, reset, setDisableUploadCompanyBankAccountDocuments, pixKeySituation]);

  useEffect(() => {
    register(
      { name: 'bank' },
      { required: { value: true, message: 'Campo obrigatório' } },
    );
  }, [register]);

  const handleChange = useCallback(
     (e) => {
      setValue('bank', e.value);
      setBank(e.value);
    },
    [setValue],
  );

  async function updateApiPix() {
    try {
      const response = await api.put(
        `/companies/pix-toogle/${params.id}`,
        {
          has_pix_key: has_pix_key ? 0 : 1,
        },
      );
      const companyRes: ICompany = response.data.data;
      setCompany(companyRes);
      setPixKeySituation(companyRes.pix_key_situation)
      toast.success('Pix atualizado com sucesso!');
    } catch (error) {
      toast.error('Aconteceu algum problema');
    }
    setLoading(0)
  }

  const changePix = () => {
    updateApiPix();
    const new_has_pix_key = !has_pix_key;
    if(new_has_pix_key){
      setPixStatus('Ativa')
    }else{
      setPixStatus('Desativada')
    }

    setHasPixKey(new_has_pix_key);
    setValue('has_pix_key', has_pix_key);
    setLoading(0);
  }

  const handleChangePix =  () =>  {
    if(has_pix_key){
      changePix()
    }else{
      setIsModalOpen();
    }
    
    // MySwal.fire({
    //   title: 'Você tem certeza',
    //   text: "Após realizar esta ação todos os seus projetos serão afetados.",
    //   icon: 'info',
    //   showCancelButton: true,
    //   confirmButtonText: `Sim`,
    //   cancelButtonText: `Não`,
    // }).then((res) => {
    //   if (res.isConfirmed) {
    //     updateApiPix();
    //     const new_has_pix_key = !has_pix_key;
    //     if(new_has_pix_key){
    //       setPixStatus('Ativa')
    //     }else{
    //       setPixStatus('Desativada')
    //     }

    //     setHasPixKey(new_has_pix_key);
    //     setValue('has_pix_key', has_pix_key);
    //   }
    // })

  };

  const handleSendValidatePixKey = () => {
    setLoading(1);
    setPixKeySituation('WAITING_VERIFICATION');
    handleValidatePixKey();
  }

  async function handleValidatePixKey() {   
        try {
          const response = await api.put(
            `companies/verify-pix-key/${company?.id_code}`
          );
          console.log(response.data.data);
          const companyRes: ICompany = response.data.data;
          setCompany(companyRes);
          setLastVerificationPixKey(new Date());
          setLoading(0);
          toast.success(`Solicitação enviada com sucesso!`);
        } catch (error) {
          setLoading(0);
          toast.error('Aconteceu algum problema...');
        }
    if(company?.pix_key_situation === 'NO_KEY' || company?.pix_key_situation === 'WRONG_KEY' || company?.pix_key_situation === undefined){
      
    }
  };

  async function handleResendValidatePixKey() {
    const currentDate = new Date();
    
    if(lastVerificationPixKey === null || lastVerificationPixKey === undefined){
      handleValidatePixKey();
      return;
    }
    
    const minDiff = getMinuteDiff(lastVerificationPixKey, currentDate)
    // const minDif = 0
    if(minDiff > 15){
      handleValidatePixKey();
    }else{
      var nextVerif = 15 - minDiff
      toast.error(`Você enviou uma solicitação a pouco tempo. Tente novamente daqui ${nextVerif}min.`)
    }
  }

  const onSubmit = useCallback(
    async (data: FormData) => {
      if (
        company?.bank === data.bank &&
        company?.agency === data.agency &&
        company.agency_digit === data.agency_digit &&
        company.account === data.account &&
        company.account_digit === data.account_digit
      ) {
        toast.warning('Dados da conta bancária continuam inalterados');
      } else {
        setLoading(1);
        try {
          const response = await api.put(
            `/companies/bank/${company?.id_code}`,
            {
              ...data,
              active_flag: company?.active_flag,
            },
          );
          const companyRes: ICompany = response.data.data;
          sendDataToCompanyDetail(companyRes);
          setLoading(0);
          toast.success('Informação bancária atualizada com sucesso!');
          setDisableUploadCompanyBankAccountDocuments(false);
        } catch (error) {
          setLoading(0);
        }
      }
    },
    [
      company,
      sendDataToCompanyDetail,
      setDisableUploadCompanyBankAccountDocuments,
    ],
  );

  // var title = {
  //   fontSize: '30px',
  //   marginBottom: '20px',
  //   color: '#56607e',
  //   fontWeight: 'bold',
  // }


  var subtitle = {
    padding: '20px',
  }

  return (
    <Container cardTitle="Conta bancária" loading={loading}>
      {pixConfirmModal}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <Select
            name="bank"
            className="col-md-12"
            label="Banco"
            value={list.find((v) => v.value === bank)}
            options={list}
            onChange={handleChange}
            disabled={!company?.can_edit_bank_information}
            errorMessage={errors.bank?.message}
          />
        </div>
        <div className="row">
          <Input
            label="Agência"
            id="bank-agency"
            name="agency"
            mask=""
            placeholder="Agência"
            className="col-md-7 col-xs-12"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            disabled={!company?.can_edit_bank_information}
            errorMessage={errors.agency?.message}
          />
          <Input
            label="Digito"
            id="bank-agency-digit"
            name="agency_digit"
            mask=""
            placeholder="Digito"
            className="col-md-5 col-xs-12"
            register={register}
            disabled={!company?.can_edit_bank_information}
          />
        </div>
        <div className="row">
          <Input
            label="Conta"
            id="bank-account"
            name="account"
            mask=""
            placeholder="Conta"
            className="col-md-7 col-xs-12"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            disabled={!company?.can_edit_bank_information}
            errorMessage={errors.account?.message}
          />
          <Input
            label="Digito"
            id="bank-account-digit"
            name="account_digit"
            mask=""
            placeholder="Digito"
            className="col-md-5 col-xs-12"
            register={register}
            disabled={!company?.can_edit_bank_information}
          />


    <div className="row" style={{ margin: "0 0 16px"}}>
        <Title style={{fontSize: "20px"}}>
          Recebimento PIX
        </Title>

         <p className="subtitle" style={subtitle}>
          Para ativar os recebimentos instantâneos, direto na sua conta, <b>confirme que sua chave está ativa </b> para receber pagamentos das suas vendas <b>feitas pelo Pix.</b>
        </p>
        
        { pixKeySituation === 'NO_KEY' && (
          <PixInfoBanner>
            <div className="orange">
              <b>Atenção:</b> só será possível receber pagamentos via PIX no checkout após a <b>verficação da sua chave PIX.</b>
            </div>
          </PixInfoBanner>
        )}
        
        { pixKeySituation === 'WAITING_VERIFICATION' && (
          <PixInfoBanner>
            <div className="orange">
              <b>Sua chave foi enviada para verificação. Agora é só aguardar!</b> Caso seja validada, em breve você poderá ativar os recebimentos PIX na conta da sua empresa.
            </div>
          </PixInfoBanner>
        )}
        
        { pixKeySituation === 'VERIFIED' && (
          <PixInfoBanner>
            <div className="green">
              <b>Sua chave PIX foi validada com sucesso!</b> Agora sua empresa esta habilitada para receber pagamentos por PIX no checkout.
            </div>
          </PixInfoBanner>
        )}
        
        { pixKeySituation === 'WRONG_KEY' && (
          <PixInfoBanner>
            <div className="red">
              <b>Parece que você ainda não criou uma chave PIX.</b> Para ativar o recebimento instantâneo, você deve criar uma chave do mesmo CPF/CNPJ da sua conta.
            </div>
          </PixInfoBanner>
        )}

        { pixStatus === undefined && (
          <PixInfoBanner>
            
          </PixInfoBanner>
        )}
        <Input
            label="Pix"
            id="bank-account-digit"
            name="account_digit"
            mask=""
            type="text"
            value={company?.company_document}
            placeholder="Chave pix"
            className="col-md-6 "
           // register={register}
            disabled={true}
          />

          { pixKeySituation === 'NO_KEY' && (
            <>
              <ValidatePixContainer>
                <p className="switch-label">Verifique sua chave</p>
                <Button primaryPill type="button" onClick={handleSendValidatePixKey}>Solicitar verificação de chave</Button>
              </ValidatePixContainer>
            </>
          )}

          { pixKeySituation === 'WAITING_VERIFICATION' && (
            <>
              <ValidatePixContainer>
                <p><img src={orangeDot} alt="Verificando chave"/>Verificando chave</p>
                <SwitchContainer className="column">
                  <SwitchInput
                    id="refund_permission"
                    className="switch-pix"
                    disabled={true}
                    defaultValue={false}
                  />
                  <p className="switch-label"><b>Em verificação</b></p>
                </SwitchContainer>
              </ValidatePixContainer>

              <LastVerification>
                { lastVerificationPixKey !== '' && lastVerificationPixKey !== undefined &&(
                  <>Verificação de Chave PIX solicitada em {getFomattedDate(lastVerificationPixKey)}</>
                )}
              </LastVerification>
            </>
          )}

          { pixKeySituation === 'VERIFIED' && (
            <ValidatePixContainer has_pix_key={has_pix_key}>
              <p><img src={greenDot} alt="Chave Verificada"/>Chave verificada</p>
              <SwitchContainer className="column">
                <SwitchInput
                  id="refund_permission"
                  className="switch-pix"
                  defaultValue={has_pix_key}
                  onChange={handleChangePix}
                />
                <p className="switch-label label"><b>{pixStatus}</b></p>
              </SwitchContainer>
            </ValidatePixContainer>
          )}

          { pixKeySituation === 'WRONG_KEY' && (
            <ValidatePixContainer>
              <p><img src={redDot} alt="Chave inexistente"/>Chave inexistente</p>
              <Button primaryPill type="button" onClick={handleResendValidatePixKey}>Solicitar nova verificação</Button>
            </ValidatePixContainer>
          )}    

   </div>
        </div>
        {company?.can_edit_bank_information && (
          <div
            className="row"
            style={{ margin: '20px 0 0 0', justifyContent: 'flex-end' }}
          >
            <Button primaryPill>Salvar</Button>
          </div>
        )}
      </Form>
    </Container>
  );
};

export default BankAccountForm;
