import styled from 'styled-components';
import * as color from '../../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  padding: 16px;

  .btn-finish {
    margin: 30px 0 0 0;
  }

  .goback-button {
    margin-top: 10px;
  }

  @media (min-width: 576px) {
    width: 420px;
    padding: 10px;
  }
`;

export const TermsTextBox = styled.div`
  height: 275px;
  font-size: 12px;
  font-family: 'Roboto';
  line-height: 14px;
  margin: 20px 0 34px;
  padding-right: 10px;
  overflow: auto;

  p {
    margin-bottom: 10px;
  }

  li {
    margin-bottom: 6px;
  }

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${color.orange};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #e5e5e5;
    background-color: #f5f5f5;
    border-radius: 4px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-around;
`;
