/* eslint-disable eqeqeq */
import React, { useContext, useEffect } from 'react';
import { Container } from './styles';
import PersonalForm from './PersonalForm';
import ResidenceForm from './ResidenceForm';
import DocumentsForm from './DocumentsForm';
import PageTopbar from '../../../components/PageTopbar';
import api from '../../../services/api';

import { AccountsContext as Context } from '../../../context/AccountsContext';

const PersonalInfo: React.FC = () => {
  const { user, setUser } = useContext(Context);

  // useEffect(() => {
  //   if (window.Appcues != undefined && window.Appcues != null) {
  //     window.Appcues.page();
  //     window.Appcues.Appcues.identify(user?.id_code, {
  //       name: user?.name,
  //       email: user?.email,
  //     });
  //   }
  // }, [user]);

  useEffect(() => {
    async function getUser() {
      try {
        const response = await api.get('/me');
        setUser(response.data.data);
      } catch (error) {}
    }

    getUser();
  }, [setUser]);

  return (
    <Container>
      <PageTopbar
        title="Informações pessoais"
        subTitle="Gerencie suas informações e documentos atrelados a sua conta CloudFox"
      />
      <PersonalForm user={user} setUser={setUser} />
      {user?.is_account_owner && (
        <>
          <ResidenceForm user={user} setUser={setUser} />
          <DocumentsForm user={user} setUser={setUser} />
        </>
      )}
    </Container>
  );
};

export default PersonalInfo;
