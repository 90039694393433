import styled from 'styled-components';
import Card from '../../../../components/Card';
import * as color from '../../../../styles/colors';
// import avatar from '../../../assets/img/avatar.jpg';

export const Container = styled(Card)`
  .top-text {
    font-size: 14px;
    max-width: 300px;
    color: ${color.darkgray};
    margin-bottom: 35px;
  }

  @media (max-width: 576px) {
  }
`;

export const SectionTitle = styled.p`
  font-weight: bold;
  margin: 14px 0 14px;
`;

export const AvatarContainer = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* min-width: 185px; */
  padding: 0 15px;
  margin-bottom: 15px;

  @media (max-width: 576px) {
    justify-content: center;
  }
`;

export const Avatar = styled.div`
  position: relative;
  margin-left: 1rem !important;
  min-width: 90px;
  height: 90px;
  cursor: pointer;

  .photo {
    min-width: 90px;
    height: 90px;
    border: 4px solid ${color.orange};
    border-radius: 50px;
  }

  .icon-background {
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 90px;
    height: 29px;
    opacity: 0.7;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
  }

  .icon {
    position: absolute;
    bottom: 8px;
    left: 36px;
  }
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  color: blue;
  font-style: italic;
  line-height: 2px;
`;
