/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, {
  useState,
  useCallback,
  useRef,
  useContext,
  useEffect,
} from 'react';
import { IOnResult } from 'react-cep-promise';
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import {
  Container,
  Row,
  CepInput,
  TextLabel,
  TextWrapper,
  HideContainer,
  GoBackContainer,
} from './styles';

import TextInput from '../SignupComponents/TextInput';
import SelectInput from '../SignupComponents/SelectInput';
import Button from '../../../components/Button';
import GoBackButton from '../../../components/GoBackButton';
import Title from '../../../components/Title';
import { states } from '../../../data/stateList';
import ContainerPage from '../../../components/ContainerPage';
import ErrorMessage from '../../../components/ErrorMessage';
import arrowRight  from '../../../assets/arrow-right.svg'

interface AddressProps {
  show?: boolean;
  error?: string;
}

interface StatesProps {
  name?: string;
  initials: string;
}

const list = states.map((s: StatesProps) => {
  return { value: s.initials, label: s.initials };
});

const Address: React.FC<AddressProps> = ({ show }) => {
  const [placeholder, setPlaceholder] = useState('CEP');
  const inputRef = useRef<any>();
  const {
    userZipcode,
    setUserZipcode,
    userCity,
    setUserCity,
    userState,
    setUserState,
    userStreet,
    setUserStreet,
    userStreetNumber,
    setUserStreetNumber,
    userComplementNumber,
    setUserComplementNumber,
    nextStep,
    previousStep,
    userNeighborhood,
    setUserNeighborhood,
    userCountry,
    setUserCountry,
  } = useContext(Context) || {};
  const [fetching, setFetching] = useState(false);
  const [hasStreet, setHasStreet] = useState('');
  const [hasZipcode, setHasZipcode] = useState('');
  const [hasCity, setHasCity] = useState('');
  const [hasState, setHasState] = useState('');
  const [hasCountry, setHasCountry] = useState('');
  const [hasNeighborhood, setHasNeighborhood] = useState('');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [showAddress, setShowAddress] = useState<boolean>(false);

  const onResult = useCallback(
    (result: IOnResult) => {
      const { data, error } = result;

      error && setShowAddress(false);
      if (data) {
        setHasStreet('');
        setHasZipcode('');
        setHasState('');
        setHasCity('');
        setHasCountry('');
        setHasNeighborhood('');
        
        setUserCity(data.city);
        setUserState(data.state);
        setUserStreet(data.street);
        setUserNeighborhood(data.neighborhood);
        setUserCountry('Brasil');
        setErrorMsg('');
        setShowAddress(true);
      } else {
        setShowAddress(false);
        setErrorMsg('Este cep não existe. Por favor, insira um cep válido.');
      }
    },
    [
      setUserCity,
      setUserCountry,
      setUserNeighborhood,
      setUserState,
      setUserStreet,
    ],
  );

  useEffect(() => {
    if (userZipcode && userStreet && userState) {
      setShowAddress(true);
    }
  }, [userState, userStreet, userZipcode]);

  const validateUserAddress = useCallback(() => {
    !userStreet ? setHasStreet('Preencha com uma rua.') : setHasStreet('');
    !userZipcode ? setHasZipcode('Preencha um CEP.') : setHasZipcode('');
    !userState ? setHasState('Preencha com um estado.') : setHasState('');
    !userCity ? setHasCity('Preencha com uma cidade.') : setHasCity('');
    !userCountry ? setHasCountry('Preencha com um país.') : setHasCountry('');
    !userNeighborhood ? setHasNeighborhood('Preencha com um bairro') : setHasNeighborhood('');

    userStreet &&
      userZipcode &&
      userState &&
      userCity &&
      userCountry &&
      userNeighborhood &&
      nextStep();
  }, [
    nextStep,
    userCity,
    userCountry,
    userNeighborhood,
    userState,
    userStreet,
    userZipcode,
  ]);

  return (
    <ContainerPage showLogo zipcodeBkg>
      <Container>
        <Title primary>
          Para continuar, precisamos de mais algumas informações.
        </Title>
        <p>Qual o seu endereço?</p>
        <TextWrapper>
          <CepInput
            id="zipcode"
            fetching={fetching}
            mask="99999-999"
            placeholder={placeholder}
            setFetching={setFetching}
            onChange={(e) => setUserZipcode(e.target.value)}
            onResult={onResult}
            value={userZipcode}
            error={hasZipcode}
          />
          <TextLabel
            // onClick={() => inputRef.current.getInputDOMNode().focus()}
            className="form__label"
            htmlFor="cep"
          >
            {placeholder}
          </TextLabel>
        </TextWrapper>
        {errorMsg && <ErrorMessage marginTop="24px">{errorMsg}</ErrorMessage>}
        <HideContainer show={showAddress}>
          <TextInput
            id="street"
            name="street"
            mask=""
            type="text"
            placeholder="Rua"
            value={userStreet}
            error={hasStreet}
            onChange={(e) => setUserStreet(e.currentTarget.value)}
          />
          <TextInput
            id="country"
            name="country"
            mask=""
            type="text"
            placeholder="País"
            value={userCountry}
            error={hasCountry}
            onChange={(e) => setUserCountry(e.currentTarget.value)}
          />

          <TextInput
            id="neighborhood"
            name="neighborhood"
            mask=""
            type="text"
            placeholder="Bairro"
            value={userNeighborhood}
            error={hasNeighborhood}
            onChange={(e) => setUserNeighborhood(e.currentTarget.value)}
          />
          <Row>
            <TextInput
              split
              id="address-number"
              name="address-number"
              mask=""
              type="text"
              placeholder="Número"
              value={userStreetNumber}
              onChange={(e) => setUserStreetNumber(e.currentTarget.value)}
            />
            <TextInput
              split
              id="place-number"
              name="place-number"
              mask=""
              type="text"
              placeholder="Complemento"
              value={userComplementNumber}
              onChange={(e) => setUserComplementNumber(e.currentTarget.value)}
            />
          </Row>
          <Row>
            <TextInput
              split
              id="city"
              name="city"
              mask=""
              type="text"
              placeholder="Cidade"
              value={userCity}
              error={hasCity}
              onChange={(e) => setUserCity(e.currentTarget.value)}
            />

            <SelectInput
              split
              id="state"
              name="state"
              placeholder="Estado"
              value={list.find((v) => v.value === userState)}
              error={hasState}
              onChange={(e) => setUserState(e.value)}
              options={list}
            />
          </Row>
          <Button
            primary
            type="button"
            className="btn-continue"
            textTransform
            onClick={validateUserAddress}
          >
            Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
          </Button>
        </HideContainer>
        <GoBackContainer show={show}>
          <GoBackButton className='goback-button' onClick={previousStep} />
        </GoBackContainer>
      </Container>
    </ContainerPage>
  );
};

export default Address;
