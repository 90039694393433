import React, { useContext } from 'react';
import { FiMenu } from 'react-icons/fi';
import { Container, TopMenu, Trigger } from './styles';
import Dropdown from '../Dropdown';
import Button from '../Button';
import InTopbarConfig from '../InTopbarConfig';
import * as color from '../../styles/colors';
import { AccountsContext as Context } from '../../context/AccountsContext';

// import InTopbarNotification from '../InTopbarNotification';
import cloudfoxLogo from '../../assets/logos/2021/svg/icon-secundary.svg';
// import notificationsIcon from '../../assets/notifications.svg';

const InTopbar: React.FC = () => {
  const { mobileMenuToggle, setMobileMenuToggle } = useContext(Context);
  return (
    <Container className="navbar">
      <TopMenu>
        <div className="logo-button-group">
          <img src={cloudfoxLogo} alt="" className="logo" />
          <Button
            transparent
            onClick={() => setMobileMenuToggle(!mobileMenuToggle)}
          >
            <FiMenu color={color.orange} size={28} />
          </Button>
        </div>
        {/* <Dropdown className="notifications" icon={notificationsIcon}>
          <InTopbarNotification />
        </Dropdown> */}
        {/* <Avatar />
        <User>
          <p>Lorram Felix</p>
        </User> */}
        <Dropdown className="configurations" trigger={<Trigger />}>
          <InTopbarConfig />
          {/* <div>Login</div>
          <div>Logout</div> */}
        </Dropdown>
      </TopMenu>
      {/* <ContentText>
        <Title>Bem vindo, Lorram Felix</Title>
        <SubTitle>
          Gerencie suas informações, privacidade e segurança dos seus produtos
          Cloudfox
        </SubTitle>
      </ContentText> */}
    </Container>
  );
};

export default InTopbar;
