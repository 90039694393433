import styled from 'styled-components';
import Modal from 'react-modal';

export const Container = styled(Modal)`
  position: relative;
  max-width: 600px;
  background-color: white;
  border-radius: 5px;
  margin: 2.75rem auto;

  @media (max-width: 576px) {
    margin: 2.75rem 0.8rem;
  }
`;

export const ModalContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  outline: 0;
`;

export const ModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 16px;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #dee2e6;

  p {
    font-size: 24px;
  }
  .close-btn {
    height: 24px;
    background: transparent;
    border: none;
    img {
      height: 20px;
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #dee2e6;
  .close-bottom-btn {
    width: 120px;
  }
`;
