
import React from 'react';
import { Container } from './styles';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';


interface SliderInputProps {
  slideValue: number,
  setSlideValue: (value:number) => void,
  step: number,
  min: number,
  max: number,
}

const SliderInput: React.FC<SliderInputProps> = ({
    slideValue,
    setSlideValue,
    step,
    min,
    max,
  }) => {

  const onSliderChange = (value: number) => {
    setSlideValue(value);
  };

  return(
    <Container>
      <Slider
        value={slideValue}
        step={step}
        min={min}
        max={max}
        onChange={onSliderChange}
      />
    </Container>
  )
}

export default SliderInput