/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Container } from './styles';
import Input from '../../../../components/Input';
import FormWrapper from '../../../../components/Form';
import Modal from '../../../../components/ModalAdmin';
import ErrorMessage from '../../../../components/ErrorMessage';
import CodeInput from '../../../../components/VerificationCodeInput';
import api from '../../../../services/api';
import { login } from '../../../../services/auth.js';
import useToggle from '../../../../hooks/useToggle';

type FormData = {
  currentPassword: string;
  newPassword: string;
  RepeatPassword: string;
};

const ChangePassword: React.FC = () => {
  const [errorPasswordMsg, setErrorPasswordMsg] = useState('');
  const [isModalOpen, setIsModalOpen] = useToggle(false);
  const [errorMsg, setErrorMsg] = useState<string | null>('');
  const [loading, setLoading] = useState<number | undefined>(0);

  const { register, handleSubmit, errors, watch, setError, setValue } = useForm<
    FormData
  >();

  const onSubmit = async (data: FormData) => {
    try {
      const response = await api.post('/user/change-password', {
        current_password: data.currentPassword,
        new_password: data.newPassword,
        new_password_confirm: data.RepeatPassword,
      });

      login(response.data.token);
      setValue('currentPassword', '');
      setValue('newPassword', '');
      setValue('RepeatPassword', '');
      toast.success('Senha atualizada com sucesso!');
      // setIsModalOpen();
    } catch (error) {
      const errors = error.response.data;

      if (errors.errors !== undefined) {
        if (errors.errors.password !== undefined)
          setErrorPasswordMsg(errors.errors.password[0]);
      } else {
        setError('currentPassword', {
          type: 'manual',
          message: errors.message,
        });
      }
    }
  };

  /*

  async function handleSave() {
    try {
      const response = await api.post('/change-password', {
        new_password: newPassword,
        new_password_confirm: RepeatPassword,
      });
      login(response.data.token);
    } catch (error) {
      const errors = error.response.data;

      if (errors.errors !== undefined) {
        if (errors.errors.password !== undefined)
          setErrorPasswordMsg(errors.errors.password[0]);
      } else {
        setErrorPasswordMsg(errors.message);
      }
    }
  }


  const callModal = useCallback(() => {
    if (currentPassword && newPassword && RepeatPassword) {
      setErrorMsg('');
      setIsModalOpen();
    } else {
      console.log(currentPassword, newPassword, RepeatPassword)
      setErrorMsg('Por favor, todos os campos acima devem estar preenchidos.');
    }
  }, [RepeatPassword, currentPassword, newPassword, setIsModalOpen]);

  */

  const tokenComplete = useCallback(() => {
    setIsModalOpen();
    toast.success('Senha alterada com sucesso!');
  }, [setIsModalOpen]);

  const codeVerificationModal = (
    <Modal
      smallWidth
      isOpen={isModalOpen}
      onRequestClose={setIsModalOpen}
      closeModal={setIsModalOpen}
      headerLabel="Verificar código"
    >
      <CodeInput
        onComplete={tokenComplete}
        loading={loading}
        text="Um código foi enviado para o seu email. Digite seu código abaixo para
        finalizar a alteração de senha."
      />
    </Modal>
  );

  return (
    <Container cardTitle="Alterar senha">
      <span>{errorPasswordMsg}</span>
      {codeVerificationModal}
      <FormWrapper submitButton onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <Input
            id="currentPassword"
            className="col-xs-12"
            name="currentPassword"
            label="Senha atual"
            type="password"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
              minLength: {
                value: 6,
                message: 'Obrigatorio pelo menos 6 caracteres',
              },
            })}
            errorMessage={errors.currentPassword?.message}
          />
        </div>
        <div className="row">
          <Input
            id="newPassword"
            className="col-md-6 col-xs-12"
            name="newPassword"
            label="Nova senha"
            mask=""
            type="password"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
              minLength: {
                value: 6,
                message: 'Obrigatorio pelo menos 6 caracteres',
              },
            })}
            errorMessage={errors.newPassword?.message}
          />
          <Input
            id="RepeatPassword"
            className="col-md-6 col-xs-12"
            name="RepeatPassword"
            label="Repita nova senha"
            mask=""
            type="password"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
              minLength: {
                value: 6,
                message: 'Obrigatorio pelo menos 6 caracteres',
              },
              validate: (value) =>
                value === watch('newPassword') || 'As senhas não coincidem',
            })}
            errorMessage={errors.RepeatPassword?.message}
          />
        </div>
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
      </FormWrapper>
    </Container>
  );
};

export default ChangePassword;
