import styled from 'styled-components';
import { shade } from 'polished';
import Card from '../../../../components/Card';
import * as color from '../../../../styles/colors';

export const Container = styled(Card)`
  margin-top: 28px;

  .subtitle {
    margin-bottom: 30px;
  }

  .section-title-badge {
    display: flex;
    align-items: center;
    margin: 0 -5px 14px;

    .badge {
      width: fit-content;
    }

    .view-uploads-button {
      background: transparent;
      border: none;
      color: ${color.orange};

      &:hover {
        color: ${shade(0.2, color.orange)};
      }
    }

    .help-btn {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      padding: 2px;
      margin-left: 10px;

      img {
        height: -webkit-fill-available;
      }
    }
  }

  .tooltip-text {
    color: #fff;
  }
`;

export const SectionTitle = styled.p`
  font-size: 16px !important;
  font-weight: bold;
  /* margin: 14px 0 14px; */
`;
