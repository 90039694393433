import { createGlobalStyle } from 'styled-components';
import * as color from './colors';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: #fff;
    color: #2e2e2e;
    -webkit-font-smoothing: antialiased;
  }

  body, button {
    font: 14px 'Poppins', sans-serif;
    /* font-family: 'Poppins', sans-serif; */
  }

  input {
    font-family: 'Roboto', sans-serif;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  table {
    width: 100%;

    th {
      font-size: 15px;
      font-weight: bold;
      padding: 12px;
    }

    td {
      text-align: -webkit-center;
      padding: 8px;
    }
  }

  .Modal-Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
    overflow-x: hidden;
    overflow-y: auto;

    button {
      font-family: 'Mulish', sans-serif;
    }
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 400ms ease;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  }

  .col {
    padding: 0 5px;
    margin-bottom: 10px;
  }

  .label {
    font-size: 14px;
    color: ${color.darkgray};
    margin-left: 10px;
    line-height: 27px;
  }

  .uploadbox {
    height: 64px;
    justify-content: center;

    > p {
      font-size: 14px;
    }

    @media (max-width: 546px) {
      padding: 20px 20px 16px;
      > p {
        font-size: 12px;
      }
    }
  }

  .coming-soon-message {
    font-size: 20px !important;
    text-align: center;
  }
`;
