import styled from 'styled-components';
import { shade } from 'polished';
import Card from '../../../../../components/Card';
import * as color from '../../../../../styles/colors';

export const Container = styled(Card)`
  margin-top: 28px;

  .subtitle {
    margin-bottom: 30px;
  }

  .section-title-badge {
    align-items: center;
    margin: 0 0 14px;
  }

  .view-uploads-button {
    background: transparent;
    border: none;
    color: ${color.orange};

    &:hover {
      color: ${shade(0.2, color.orange)};
    }
  }

  .tooltip-text {
    color: #fff;
  }

  .badge {
    width: fit-content;
  }
`;

export const SectionTitle = styled.p`
  font-size: 16px !important;
  font-weight: bold;
`;

export const Col = styled.div`
  padding: 0 5px;
  margin-top: 50px;
`;
