import React, { useContext, useState, useCallback, useEffect } from 'react';
import {
  Container,
  ValidationContainer,
  BoldText,
  ValidationStatus,
  CircleStatus,
  ValidationText,
} from './styles';
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import ContainerPage from '../../../components/ContainerPage';
import TextInput from '../SignupComponents/TextInput';
import Button from '../../../components/Button';
import GoBackButton from '../../../components/GoBackButton';
import arrowRight  from '../../../assets/arrow-right.svg'

const PasswordView: React.FC = () => {
  const { previousStep, nextStep, password, setPassword, confirmPassword, setConfirmPassword } =
    useContext(Context) || {};

  const [eightCharacters, setEightCharacters] = useState(false);
  const [oneLetter, setOneLetter] = useState(false);
  const [oneNumber, setOneNumber] = useState(false);

  const [ equalPassword, setEqualPassword] = useState(false)
  const [ confirmPasswordError, setConfirmPasswordError ] = useState('')

  const hasEightChar = new RegExp('^(?=.{8,})');
  const hasOneLetter = new RegExp('^(?=.*[A-Za-z])');
  const hasOneNumber = new RegExp('^(?=.*[0-9])');

  const [ disableProgress, setDisableProgress ] = useState<boolean>(true);

  const passwordValidationCheck = useCallback(
    (e) => {
      const { value } = e.currentTarget;
      setPassword(value);
      hasOneLetter.test(value) ? setOneLetter(true) : setOneLetter(false);
      hasOneNumber.test(value) ? setOneNumber(true) : setOneNumber(false);
      hasEightChar.test(value) ? setEightCharacters(true) : setEightCharacters(false);
    },
    [hasEightChar, hasOneLetter, hasOneNumber, setPassword],
  );


  const confirmPasswordCheck = () => {
    if(confirmPassword === password){
      setEqualPassword(true);
      setConfirmPasswordError('');
    } else{
      setEqualPassword(false);
      setConfirmPasswordError('A confirmação de senha não confere');
    }  
  }

  const handlePasswordValidation = () => {
    confirmPasswordCheck();
    return oneLetter && oneNumber && eightCharacters === true
      ? (
          equalPassword
          ? nextStep()
          : console.log('')
        )
      : console.log('');
  };

  useEffect(() => {
    if(password && confirmPassword){
      setDisableProgress(false)
    }else{
      setDisableProgress(true)
    }
  }, [password, confirmPassword, setDisableProgress]);

  useEffect(() => {
    hasOneLetter.test(password) ? setOneLetter(true) : setOneLetter(false);
    hasOneNumber.test(password) ? setOneNumber(true) : setOneNumber(false);
    hasEightChar.test(password)
      ? setEightCharacters(true)
      : setEightCharacters(false);
  }, [hasEightChar, hasOneLetter, hasOneNumber, password, setPassword]);

  return (
    <ContainerPage showLogo safeboxBkg>
      <Container>
        <p>Agora, precisamos que você crie uma senha para acessar sua conta</p>
        <TextInput
          id="password"
          name="password"
          mask=""
          type="password"
          placeholder="Senha"
          value={password}
          onChange={passwordValidationCheck}
        />

        <TextInput
          id="confirm-password"
          name="confirm-password"
          mask=""
          type="password"
          placeholder="Confirme sua senha"
          value={confirmPassword}
          error={confirmPasswordError}
          onChange={e => setConfirmPassword(e.target.value)}
          onBlur={confirmPasswordCheck}
        />

        <ValidationContainer>
          <BoldText>Sua senha deve conter:</BoldText>
          <ValidationStatus>
            <CircleStatus goodPassword={eightCharacters} />
            <ValidationText goodPassword={eightCharacters}>
              8 ou mais caracteres
            </ValidationText>
          </ValidationStatus>
          <ValidationStatus>
            <CircleStatus goodPassword={oneNumber} />
            <ValidationText goodPassword={oneNumber}>
              Pelo menos um número
            </ValidationText>
          </ValidationStatus>
          <ValidationStatus>
            <CircleStatus goodPassword={oneLetter} />
            <ValidationText goodPassword={oneLetter}>
              Pelo menos uma letra
            </ValidationText>
          </ValidationStatus>
        </ValidationContainer>
        <Button
          primary
          type="button"
          className="btn-continue"
          textTransform
          onClick={handlePasswordValidation}
          disabled={disableProgress}
        >
          Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
        </Button>
        <GoBackButton className='goback-button' onClick={() => previousStep()} />
      </Container>
    </ContainerPage>
  );
};

export default PasswordView;
