import styled from 'styled-components';
import * as color from '../../../styles/colors';

export const Container = styled.div`  
  text-align: center;
  width: 420px;

  line-height: 1.4;
  color: ${ color.gray };

  .title-1 {
    
    margin: 0 0 10px 0;
  }

  .subtitle {
    font-size: 14px;
    color: ${color.lightgray};
    margin: 0 0 10px 0;
  }
  
  .buttons-div {
    height: 70px;
    margin: 50px 0 0 0;

    .goback-button {
      margin-top: 10px;
    }
  }

  @media (max-width: 768px) {
    width: 250px;
    .title-1 {
      font-size: 17px;
      width: 100%;
      line-height: 100%;
      margin: 0 0 10px 0 !important;
    }

    .subtitle {
      width: 100%;
      font-size: 12px;
      margin: 0 0 10px 0 !important;
    }
  }
`;

export const CarouselContainer = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-items: center;
  align-items: center;
    
  height: auto;
  width: 100%;

  text-align: center;
  background-color: transparent !important;

  .title-2 {
    width: 300px;
    line-height: 100%;
    margin-bottom: 20px;
    line-height: 120%;
  }

  img {
    margin: 20px 0 20px 0;
    height: 230px
  }

  @media (max-width: 768px) {
    img {
      margin: 0;
      height: 200px
    }

    .title-2 {
      font-size: 13px;
      width: 100%;
      line-height: 100%;
      margin-bottom: 0;
      line-height: 120%;
    }
  }
  
`;

