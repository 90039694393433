import styled from 'styled-components';
import * as color from '../../styles/colors';

export const Container = styled.div`
  .topbar-config {
    width: 180px;

    ul {
      margin-bottom: 0;
      list-style-type: none;

      li {
        display: flex;
        align-items: center;
        min-height: 35px;
        padding: 5px 5px 5px 0;
        cursor: pointer;

        a {
          text-decoration: none;
          color: ${color.darkgray};
        }

        img {
          margin-right: 20px;
        }

        span {
          :hover {
            color: ${color.orange};
          }
        }
      }

      .logout {
        margin-top: 5px;
        padding-top: 12px;
        /* border-top: 1px solid #eeeff3; */
      }
    }
  }
`;
