/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from './styles';
import GobackLink from '../../../../components/GoBackLink';
// import CompanyProfile from './CompanyProfile';
// import CompanyLinkedServices from './CompanyLinkedServices';
import CompanyAddressForm from './CompanyAddressForm';
import CompanyProfileForm from './CompanyProfileForm';
import CompanyDocuments from './CompanyDocuments';
import BankAccountForm from '../../BankAccountForm';
import PageTopbar from '../../../../components/PageTopbar';
import api from '../../../../services/api';
import { ICompany } from '../../../../entities/Company';

interface CompanyProps {
  id: string;
}

const CompanyDetail: React.FC = () => {
  // const { company, setCompany } = useContext(Context);
  const [company, setCompany] = useState<ICompany>();
  const { id } = useParams<CompanyProps>();

  const dataFromCompanyDocuments = useCallback((data) => {
    setCompany(data);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get('/companies');
        // setCompanies(response.data.data);
        const comp = response.data.data;
        const findCompany = comp.find((c: ICompany) => c.id_code == id);
        setCompany(findCompany);
      } catch (error) {}
    })();
  }, [id, setCompany]);

  return (
    <Container>
      <PageTopbar
        title="Empresas"
        subTitle="Gerencie as empresas relacionadas com seus produtos CloudFox"
      />
      <GobackLink to="/companies" className="goback-button" />
      {/* <CompanyProfile /> */}
      {/* <CompanyLinkedServices /> */}


      <CompanyProfileForm company={company} />
      { company && (
      <BankAccountForm
        company={company}
        sendDataToCompanyDetail={dataFromCompanyDocuments}
        />      
      )}
      {company?.type == 2 && <CompanyAddressForm company={company} />}
      <CompanyDocuments
        company={company}
        sendDataToCompanyDetail={dataFromCompanyDocuments}
      />

    </Container>
  );
};

export default CompanyDetail;
