import styled from 'styled-components';
import { shade } from 'polished';
import * as color from '../../../styles/colors';
import trashIcon from '../../../assets/trash.svg';

interface DocumentProps {
  spaceAround?: boolean;
  spaceBetween?: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  > p {
    margin-top: 10px;
  }

  .btn-continue {
    margin: 30px 0 0 0;
  }

  .goback-button {
    margin-top: 10px;
  }

  .help-btn {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    padding: 2px;
  }

  .help-row {
    justify-content: flex-end;
  }

  @media (min-width: 576px) {
    width: 480px;
    padding: 10px;
  }
`;

export const UploadBox = styled.div`
  height: 90px;
  font-size: 12px;
  font-family: 'Roboto';
  border: 2px dashed #acacac;
  border-radius: 4px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  padding: 10px 75px;
  color: #656565;

  span {
    font-weight: bold;
    color: ${color.orange};
    cursor: pointer;
  }
`;

export const Row = styled.div<DocumentProps>`
  width: 100%;
  display: flex;
  justify-content: ${({ spaceAround, spaceBetween }) =>
    (spaceAround && 'space-around') || (spaceBetween && 'space-between')};
`;

export const FileUploadedBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 20px 24px;
  margin: 18px 0;
`;

export const TrashButton = styled.button`
  height: 24px;
  width: 24px;
  background: url(${trashIcon}) ${color.orange} no-repeat center/16px 16px;
  border: ${color.orange};
  border-radius: 25px;
  align-self: center;
`;

export const FileName = styled.div`
  display: flex;
  font-size: 12px;
  font-family: 'Roboto';
  line-height: 14px;

  img {
    margin-right: 20px;
  }
`;

export const JumpStepLink = styled.div`
  color: ${color.orange};
  cursor: pointer;

  &:hover {
    color: ${shade(0.2, color.orange)};
  }
`;
