import React, { ButtonHTMLAttributes } from 'react';
import { Button } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: string;
  primary?: boolean;
  primaryOutline?: boolean;
  secondaryOutline?: boolean;
  textTransform?: boolean;
  primaryPill?: boolean;
  transparent?: boolean;
  disabled?: boolean;
  opacity?:boolean;
}

const ButtonComponent: React.FC<ButtonProps> = ({
  children,
  icon,
  primary,
  primaryOutline,
  secondaryOutline,
  textTransform,
  primaryPill,
  transparent,
  disabled,
  opacity,
  ...rest
}) => {
  return (
    <Button
      primary={primary}
      primaryOutline={primaryOutline}
      secondaryOutline={secondaryOutline}
      textTransform={textTransform}
      primaryPill={primaryPill}
      transparent={transparent}
      opacity={disabled ? 1 : 0}
      disabled={disabled}
      {...rest}
    >
      {children}
      {icon && <img src={icon} alt="" />}
    </Button>
  );
};

export default ButtonComponent;
