import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";



if (process.env.NODE_ENV === 'production') {

  Sentry.init({
    dsn: "https://847b66beaee34b5c86638099aa3eb823@o342134.ingest.sentry.io/5497744",
    integrations: [
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
