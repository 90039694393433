import cloudfoxLogo from '../../../assets/logos/2021/svg/logo-secundary.svg';
import finishIllustration from '../../../assets/finish-illustration.svg';
import facebookIcon from '../../../assets/social-media/facebook-orange.svg';
import instagramIcon from '../../../assets/social-media/instagram-orange.svg';
import youtubeIcon from '../../../assets/social-media/youtube-orange.svg';

import React from 'react';
import { Container } from './styles';
import { FadeContainer } from './styles';

const FinishRegister: React.FC = () => {
  return(
    <Container>
      <img className="logo" src={cloudfoxLogo} alt="Cloudfox"/>
      
      <FadeContainer show seconds='2.5'>
        <div className="illustration-container">
          <img className="illustration" src={finishIllustration} alt="Ilustração Finalizado" />  
        </div>
      </FadeContainer>

      <div>
        <h1>Você terminou seu cadastro! <br/>
            Agora é só aguardar pela call.</h1>

        <p>As informações do agendamento da call serão <br/>
          enviadas para o e-mail cadastrado. </p>
      </div>

      <div className="social-media-container">
        <p><b>Acompanhe a CloudFox:</b></p>

        <div className="social-media-imgs">
          <a href='https://www.facebook.com/cloudfox.net/'><img className="social-media-icon" src={facebookIcon} alt="Facebook"/></a>
          <a href='https://www.instagram.com/_cloudfox/'><img className="social-media-icon" src={instagramIcon} alt="Instagram"/></a>
          <a href='https://www.youtube.com/channel/UChMVx_AqELWchnuv6Z2K2rQ'><img className="social-media-icon" src={youtubeIcon} alt="Youtube"/></a>
        </div>
      </div>
      
    </Container>
  )
}

export default FinishRegister;