import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ContainerPage from '../../components/ContainerPage';
import TextInput from '../Signup/SignupComponents/TextInput';
import Title from '../../components/Title';
import Button from '../../components/Button';
import Form from '../../components/Form';

import api from '../../services/api';
import { login, logout } from '../../services/auth.js';

import { AccountsContext as Context } from '../../context/AccountsContext';

import { Container, Row } from './styles';

const Signin: React.FC = () => {
  const { setUser } = useContext(Context);

  const history = useHistory();
  const [loading, setLoading] = useState<number>(0);

  const [errorEmailMsg, setErrorEmailMsg] = useState('');
  const [errorPasswordMsg, setErrorPasswordMsg] = useState('');

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [from, setFrom] = useState<string>('');

  useEffect(() => {
    logout();
  }, []);

  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const codeFromURL = params.get('from');
    codeFromURL ? setFrom(codeFromURL) : setFrom('');
  }, [from]);

  async function handleLogin(e: any) {
    e.preventDefault();
    setLoading(1);
    try {
      const response = await api.post('/login', {
        email,
        password,
        from
      });
console.log(response);
      const userRes = response.data.user;
      localStorage.clear();
      setUser(userRes);
      login(response.data.token);

      if (from) {
        const response = await api.post('/send-authenticated');
        window.location.href = response.data.data.url;
      } else {
        setLoading(0);
        history.push('/home');
      }
    } catch (error) {
      setLoading(0);
      const errors = error.response.data;

      if (errors.errors !== undefined) {
        if (errors.errors.email !== undefined)
          setErrorEmailMsg(errors.errors.email[0]);

        if (errors.errors.password !== undefined)
          setErrorPasswordMsg(errors.errors.password[0]);
      } else {
        setErrorPasswordMsg(errors.message);
      }
    }
  }

  return (
    <ContainerPage randomBkg showLogo loading={loading}>
      <Container>
        <Title>Acessar conta</Title>
        <p>Insira seus dados para continuar</p>
        <Form>
          <TextInput
            id="email"
            name="email"
            mask=""
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            error={errorEmailMsg}
          />
          <TextInput
            id="password"
            name="password"
            mask=""
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            error={errorPasswordMsg}
          />
          <Button
            primary
            type="submit"
            className="btn-signin"
            textTransform
            onClick={handleLogin}
          >
            Acessar
          </Button>
        </Form>
        <Row>
          <Link to="/forgot-password" className="btn-forgot-password">
            Esqueci minha senha
          </Link>
        </Row>
        <Row>
          <Link to="/signup" className="signup-link">
            <p>Não tem conta? CADASTRE-SE</p>
          </Link>
        </Row>
      </Container>
    </ContainerPage>
  );
};

export default Signin;
