import React, { useContext, useCallback, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Container } from './styles';
import ContainerPage from '../../../components/ContainerPage';
import TextInput from '../SignupComponents/TextInput';
import Title from '../../../components/Title';
import Button from '../../../components/Button';
import GoBackLink from '../../../components/GoBackLink';
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import api from '../../../services/api';
import arrowRight  from '../../../assets/arrow-right.svg'

const UsernameView: React.FC = () => {
  const {
    fullname,
    setFullname,
    cpf,
    setCpf,
    nextStep,
    setInviteCode,
    inviteCode,
  } = useContext(Context);
  const [loading, setLoading] = useState<number>(0);
  const [fullnameErrorMsg, setFullnameErrorMsg] = useState<string>('');
  const [cpfErrorMsg, setCpfErrorMsg] = useState<string>('');
  const [isRecaptchaApproved, setIsRecaptchaApproved] = useState<boolean>(false);
  const [isInputsValidated, setIsInputsValidated] = useState<boolean>(false)

  useEffect(()=>{
    return window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [])

  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const codeFromURL = params.get('i');
    codeFromURL ? setInviteCode(codeFromURL) : setInviteCode('');
  }, [inviteCode, setInviteCode]);

  const verifyCPF = useCallback(() => {
    setLoading(1);
    api
      .get('/register/verify-cpf', {
        params: {
          document: cpf,
        },
      })
      .then(() => {
        setLoading(0);
        nextStep();
      })
      .catch((error) => {
        setLoading(0);
        if (error.response.data.errors) {
          setCpfErrorMsg(error.response.data.errors.document);
        } else {
          setCpfErrorMsg(error.response.data.message);
        }
      });
  }, [cpf, nextStep]);

  const validateInputs = useCallback(() => {

    if (!fullname) {
      setFullnameErrorMsg('Por favor, preencha o campo com seu nome.');
    } else if (fullname.length >= 200) {
      setFullnameErrorMsg('O campo nome deve conter no máximo 200 caracters.');
    } else if(fullname.split(' ').length <= 1){
      setFullnameErrorMsg('Por favor, preencher com nome completo.');
    }else {
      setFullnameErrorMsg('');
    }

    cpf.length <= 10
      ? setCpfErrorMsg('Por favor, preencha o campo com seu CPF.')
      : setCpfErrorMsg('');

    fullname &&
      fullname.length <= 200 &&
      fullname.split(' ').length > 1 &&
      cpf.length >= 10 &&
      isRecaptchaApproved &&
      verifyCPF();
    // fullname && cpf.length >= 10 && isRecaptchaApproved && nextStep();
  }, [fullname, cpf.length, isRecaptchaApproved, verifyCPF]);

  const formatCPF = useCallback(
    (v) => {
      // removes non-alphanumeric character
      const rawValue = v.replace(/[\W_]/g, '');
      setCpf(rawValue);
    },
    [setCpf],
  );

  const handleChangeCPF = useCallback(
    (e) => {
      const { value } = e.currentTarget;

      formatCPF(value);
    },
    [formatCPF],
  );

  const handleChangeRecaptcha = useCallback((e) => {
    e && setIsRecaptchaApproved(true);
  }, []);

  const handleExpiredRecaptcha = () => {
    setIsRecaptchaApproved(false);
    setIsInputsValidated(false);
  };


  useEffect(() => {
    if(fullname && cpf && isRecaptchaApproved){
      setIsInputsValidated(true)
    }
  }, [isRecaptchaApproved, fullname, cpf])
  return (
    <ContainerPage randomBkg showLogo loading={loading}>
      <Container>
        <Title>Abra sua conta</Title>
        <br/>
        <p>Qual seu nome?</p>
        <TextInput
          id="fullname"
          className="fullname"
          name="fullname"
          mask=""
          type="text"
          placeholder="Nome Completo"
          value={fullname}
          error={fullnameErrorMsg}
          onChange={(e) =>
            setFullname(
              e.currentTarget.value.replace(/(^\w|\s\w)/g, (m: any) =>
                m.toUpperCase(),
              ),
            )
          }
        />
        <p className="cpf-question">Qual seu CPF?</p>
        <TextInput
          id="cpf"
          className="cpf"
          name="cpf"
          mask="999.999.999-99"
          type="text"
          placeholder="CPF"
          value={cpf}
          onChange={handleChangeCPF}
          error={cpfErrorMsg}
        />
        <div className="recaptcha">
          <ReCAPTCHA
            sitekey="6LckRc8ZAAAAADiZrUNxqi2GgaG4BjLNqd0KRg9G"
            // sitekey="6LeQDMsZAAAAAOVm0vKUS_IEGs0L5igpv7oUS8Cr"
            onChange={handleChangeRecaptcha}
            onExpired={handleExpiredRecaptcha}
          />
        </div>
        <Button
          primary
          type="button"
          className="btn-continue"
          textTransform
          onClick={validateInputs}
          disabled={!isInputsValidated}
        >
          Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
        </Button>
        <GoBackLink to="/" className="goback-button" />
      </Container>
    </ContainerPage>
  );
};

export default UsernameView;
