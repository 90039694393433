import React, { useCallback, useContext, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { PreRegisterContext } from '../../context/PreRegisterContext';
import { api } from '../../services/api';

import {} from './styles';

import UsernameView from './UsernameView';
import EmailView from './EmailView';
import EmailTokenView from './EmailTokenView';
import PhoneTokenView from './PhoneTokenView';
import MobilePhoneView from './MobilePhoneView';
import PasswordView from './PasswordView';
import Address from './AddressView';
import ResidenceReceipt from './ResidenceReceipt';
import Document from './DocumentView';
import CnpjView from './CompanyData/CnpjView';
import CompanyAddressView from './CompanyData/CompanyAddressView';
import CompanyDocumentView from './CompanyData/CompanyDocumentView';
import BankInfoView from './BankAccount/BankInfoView';
import BankStatementView from './BankAccount/BankStatementView';
import UsePrivacyTermsView from './BankAccount/UsePrivacyTermsView';
import AccountLinksView from './AccountLinksView';
import AverageBillingView from './AvarageBillingView';
import Calendry from './CalendryView';
import TutorialDocument from './TutotialDocumentView';
import { useBeforeunload } from 'react-beforeunload';
import useUrlSearchParams from '../../hooks/useUrlSearchParams';

const Signup: React.FC = () => {
  const { 
    step, 
    cpf, 
    email, 
    phone, 
    fullname,
    userZipcode,
    userStreet,
    userStreetNumber,
    userComplementNumber,
    userCity,
    userState,
    userNeighborhood,
    userCountry,
    cnpj,
    companyZipcode,
    companyStreet,
    companyStreetNumber,
    companyComplementNumber,
    companyCity,
    companyState,
    companyNeighborhood,
    bank,
    bankAccountNumber,
    bankAccountDigitNumber,
    bankAgencyNumber,
    bankAgencyDigitNumber,
    averageBilling,
    storeLink,
    categories,
    gatewayName,
    currentEcommerce,
    howMeet,
    setHasPromotionalTax,
  } = useContext(PreRegisterContext);

  useEffect(() => {
    ReactPixel.init('685333352269589');
    ReactPixel.pageView();
  });
  
  // Promotional Tax
  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const promotionalTax = params.get('promotional-tax');
    if(promotionalTax){
      setHasPromotionalTax(promotionalTax);
    }
    
  });

  useBeforeunload( async (event:any) => {
    event.preventDefault();
    
      const formData = new FormData();
      formData.append('last_step', step.toString());

      //User
      formData.append('document', cpf);
      formData.append('email', email);
      formData.append('name', fullname);
      formData.append('phone', phone);
      
      //Person Address
      formData.append('zip_code', userZipcode);
      formData.append('country', userCountry);
      formData.append('state', userState);
      formData.append('city', userCity);
      formData.append('district', userNeighborhood);
      formData.append('street', userStreet);
      formData.append('number', userStreetNumber);
      formData.append('complement', userComplementNumber);

      // Company Address
      formData.append('company_document', cnpj);    
      formData.append('company_zip_code', companyZipcode);
      formData.append('company_state', companyState);
      formData.append('company_street', companyStreet);
      formData.append('company_city', companyCity);
      formData.append('company_district', companyNeighborhood);
      formData.append('company_number', companyStreetNumber);
      formData.append('company_complement', companyComplementNumber);
      
      // Bank Account
      formData.append('bank', bank);
      formData.append('agency', bankAgencyNumber);
      formData.append('agency_digit', bankAgencyDigitNumber);
      formData.append('account', bankAccountNumber);
      formData.append('account_digit', bankAccountDigitNumber);  

      // Leads
      formData.append('monthly_income', averageBilling.toString());
      formData.append('niche', categories);
      formData.append('website_url', storeLink);
      formData.append('gateway', gatewayName);
      formData.append('store', currentEcommerce);
      formData.append('cloudfox_referer', howMeet);

      try {
        const res = await api.post(
          'abandoned-signups',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        console.log(res)
        return res;
      } catch (error) {
        console.log(error.message)
        return error.message;
      }

  });

  useEffect(() => {
    ReactPixel.trackCustom('InitiateRegister ', {
      numero_passo: step,
      nome_passo: stepName(step),
      nome: fullname,
      cpf: cpf,
      email: email,
      telefone: phone
    });

  }, [step, fullname, cpf, email, phone])

  function stepName(step:number) {
    switch (step) {
      case 1:
        return 'Início CPF/E-mail'
      case 2:
        return "Email";
      case 3:
        return "Email token";
      case 4:
        return "Celular";
      case 5:
        return "Celular token";
      case 6: 
        return "Faturamento e Nichos"
      case 7: 
        return "Links e Gateways"
      case 8:
        return "Senha";
      case 9:
        return "Endereço";
      case 10:
        return "Tutorial";
      case 11:
        return "Documento";
      case 12:
        return "Comprovante de residencia";
      case 13:
        return "Cnpj";
      case 14:
        return "Endereço da empresa";
      case 15:
        return "Documento da empresa";
      case 16:
        return "Dados bancarios";
      case 17:
        return "Documentos bancarios";
      case 18:
        return "Termos";
      case 19:
        return "Agendamento";
      case 20:
        return "Finalizado";
      default:
        return 'Início CPF/email';
    }
  };

  const renderContent = useCallback(() => {
    switch (step) {
      case 1:
        return <UsernameView />;
      case 2:
        return <EmailView />;
      case 3:
        return <EmailTokenView />;
      case 4:
        return <MobilePhoneView />;   
      case 5:
        return <PhoneTokenView />;
      case 6:
        return <AverageBillingView />;
      case 7:
        return <AccountLinksView />;
      case 8:
        return <PasswordView />;
      case 9:
        return <Address />;
      case 10:
        return <TutorialDocument />;
      case 11:
        return <Document />;
      case 12:
        return <ResidenceReceipt />;
      case 13:
        return <CnpjView />;
      case 14:
        return <CompanyAddressView />;
      case 15:
        return <CompanyDocumentView />;
      case 16:
        return <BankInfoView />;
      case 17:
        return <BankStatementView />;
      case 18:
        return <UsePrivacyTermsView />;
      case 19:
        return <Calendry/>;
      default:
        return <UsernameView />;
    }
  }, [step]);

  return <>{renderContent()}</>;
};

export default Signup;
