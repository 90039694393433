/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, ServiceType, Row } from './styles';
// import Modal from '../../../components/ModalAdmin';
import Card from '../../../components/Card';
// import Content from '../../../components/ModalContents/ServiceDetailModalContent';
import PageTopbar from '../../../components/PageTopbar';
import SiriusLogo from '../../../assets/services/sirius-logo.svg';
// import PolarisLogo from '../../../assets/services/Polaris.svg';
// import OrionLogo from '../../../assets/services/Orion.svg';
import VegaLogo from '../../../assets/services/vega-logo.svg';
// import ZetaLogo from '../../../assets/services/Zeta.svg';
import plusIcon from '../../../assets/plus.svg';
import useToggle from '../../../hooks/useToggle';

const ServicesPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useToggle(false);

  // const serviceDetailModal = (
  //   <Modal
  //     isOpen={isModalOpen}
  //     onRequestClose={setIsModalOpen}
  //     closeModal={setIsModalOpen}
  //   >
  //     <Content onClickCancel={() => setIsModalOpen()} />
  //   </Modal>
  // );
  return (
    <Container>
      {/* {serviceDetailModal} */}
      <PageTopbar
        title="Serviços"
        subTitle="Gerencie seus dados para acesso aos seus produtos CloudFox"
      />
      <Card cardTitle="Ativos">
        <Row>
          <Link
            to="/services/sirius"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <ServiceType>
              <img src={SiriusLogo} alt="" className="logo sirius-logo" />
              <p className="company-name">Gateway de pagamentos</p>
              {/* <p className="last-access">Último acesso em </p>
            <p className="access-date">23.10.2020 às 22:30</p> */}
            </ServiceType>
          </Link>
          <ServiceType className="vega">
            {/* <AddButton>
              <img src={plusIcon} alt="" />
            </AddButton> */}
            <img src={VegaLogo} alt="" className="logo vega-logo" />
            <p className="company-name">Banking inteligente</p>
            <p className="">Em breve</p>
          </ServiceType>
          <ServiceType className="coming-soon-service">
            <p className="coming-soon-message">Em breve um novo serviço</p>
            {/* <p className="last-access">Último acesso em </p>
            <p className="access-date">23.10.2020 às 22:30</p> */}
          </ServiceType>
        </Row>
        {/* <SectionTitle>Outros</SectionTitle> */}
        {/* <Row>
          <ServiceType>
            <img src={PolarisLogo} alt="" className="logo" />
            <p className="company-name">Empresa Beta</p>
            <p className="last-access">Último acesso em </p>
            <p className="access-date">23.10.2020 às 22:30</p>
          </ServiceType>
          <ServiceType>
            <img src={OrionLogo} alt="" className="logo" />
            <p className="company-name">Empresa Alpha</p>
            <p className="last-access">Último acesso em </p>
            <p className="access-date">23.10.2020 às 22:30</p>
          </ServiceType>

          <ServiceType>
            <AddButton>
              <img src={plusIcon} alt="" />
            </AddButton>
            <img src={ZetaLogo} alt="" className="logo" />
            <p className="company-name">Marketplace</p>
            <p className="last-access">Último acesso em </p>
            <p className="access-date">23.10.2020 às 22:30</p>
          </ServiceType>
        </Row> */}
      </Card>
    </Container>
  );
};

export default ServicesPage;
