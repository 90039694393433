import styled from 'styled-components';
import Card from '../../../components/Card';
import * as color from '../../../styles/colors';

export const Container = styled(Card)`
  margin-top: 30px;
`;

interface TextProps {
  primary?: boolean;
  secondary?: boolean;
}

interface ValidatePixContainerProps {
  has_pix_key?: boolean;
}

export const Title = styled.p<TextProps>`
  font-size: '30px !important';
  /* font-weight: ${({ secondary }) => (secondary ? '600' : 'bold')}; */
  font-weight: bold;
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding-top:20px;
  margin-top:20px !important;
  border-top:1px solid #ccc;
  width: 100%;
  color: ${({ primary, secondary }) =>
    primary ? color.orange : secondary ? color.darkgray : '#2E2E2E'};
  margin: 0;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 10px;

  .switch-input {
    margin-right: 16px;
  }

  .switch-label {
    margin-top: 10px;
    margin-right: 10px;
  }
`;

export const PixInfoBanner = styled.div`
  width: 100%;
  
  .orange {
    color: ${color.orange} !important;
    background: #FFEEE1;
    border: 1px solid #FF6D00;
  }

  .green {
    color: ${color.brightgreen} !important;
    background: #F2FFF7;
    border: 1px solid #24EE75;
  }

  .red {
    color: ${color.red} !important;
    color: #FFF0F0;
    background: #FFF0F0;
    border: 1px solid #FF0000;
  }

  div {
    margin: 0 20px 10px 20px;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 12px;
  }

  
  p {
    font-family: Mulish;
    font-style: normal;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */
  }
`;

export const ValidatePixContainer = styled.div<ValidatePixContainerProps>`
  p {
    margin: 2px 0 6px 8px; 
    align-items: center;
    display: flex;
    gap: 5px
  }

  .label {
    color: ${({ has_pix_key }) => has_pix_key ? color.brightgreen : color.darkgray};
  }
  

`;

export const LastVerification = styled.div`
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  /* identical to box height, or 16px */
  color: #8E8E8E; 
  margin-left: 20px
`;
