import styled from 'styled-components';
import Cep from 'react-cep-promise';

interface AddressProps {
  show?: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  .btn-continue {
    margin: 30px 0 0 0;
  }

  .goback-button {
    margin-top: 10px;
  }

  @media (min-width: 576px) {
    width: 420px;
    padding: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CepInput = styled(Cep)`
  width: 100%;
  border: 0;
  border-bottom: 1px solid #2e2e2e;
  outline: 0;
  font-size: 1em;
  padding: 9px 0 4px 0;
  background: #ffffff;
  transition: border-color 0.2s;
  color: #2e2e2e;
  font-family: 'Roboto';
  font-weight: 400;

  ::placeholder {
    color: transparent;
  }

  :placeholder-shown ~ .form__label {
    font-size: 0.8em;
    cursor: text;
    top: 18px;
    color: #bcbcbc;
  }

  :focus ~ .form__label {
    color: #2e2e2e;
    position: absolute;
    top: -3px;
    display: block;
    transition: 0.2s;
    font-size: 12px;
  }

  :placeholder-shown {
    border-bottom: 1px solid #2e2e2e;
  }

  :focus {
    border-bottom: 2px solid #2e2e2e;
  }
`;

export const TextLabel = styled.label`
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-weight: normal;
  font-size: 12px;
  color: #2e2e2e;
  font-family: 'Poppins';
`;

export const TextWrapper = styled.div`
  position: relative;
  padding: 8px 0 0;
  width: 100%;
  height: 20px;
  margin-bottom: 20px;
`;

export const HideContainer = styled.div<AddressProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const GoBackContainer = styled.div<AddressProps>`
  margin-top: ${({ show }) => (show ? '0' : '0px')};
`;
