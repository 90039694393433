import styled from 'styled-components';
import * as color from '../../styles/colors';

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  margin-top: 30px;

  .btn-signin {
    margin: 60px 0 33px;
  }

  .btn-forgot-password {
    text-decoration: none;
    color: #bcbcbc;

    :hover {
      color: gray;
    }

    /* background: transparent;
    border: none;
    color: #bcbcbc;
    width: auto;
    height: auto;
    padding: 5px 10px; */
  }

  .signup-link {
    text-decoration: none;
    color: ${color.orange};
    margin-top: 18px;
  }

  @media (min-width: 576px) {
    width: 80%;
    padding: 10px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;
