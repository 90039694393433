import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface WarningBarProps {
  type: 'warning' | 'danger';
  close?: boolean;
}

export const Container = styled.div<WarningBarProps>`
  height: 60px;
  width: inherit;
  margin: 0 -20px 0 -20px;

  position: fixed;
  top: 0;
  z-index: 102;

  .bar-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;

    .bar-close-button {
      position: absolute;
      right: 30px;
      margin-left: 20px;
    }
  }

  @media (max-width: 767px) {
    height: 90px;

    .row {
      flex-direction: column;
      padding: 0 90px 0 30px;
      align-items: flex-start !important;

      .bar-message {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .bar-button {
        margin-left: 0;
      }
    }
  }

  ${({ type }) =>
    type === 'warning' &&
    css`
      background: rgba(255, 193, 7, 0.9);

      .bar-message {
        font-size: 20px;
        color: #000;
      }

      .bar-button {
        margin-left: 20px;
        padding: 8px 10px;
        background: #ffd042;
        border: #ffd042;
        color: #000;
        border-radius: 5px;

        &:hover {
          background: ${shade(0.2, '#ffd042')};
        }
      }
    `}

  ${({ type }) =>
    type === 'danger' &&
    css`
      background: rgb(255, 18, 7, 0.8);

      .bar-message {
        font-size: 20px;
        color: #fff;
      }

      .bar-button {
        margin-left: 20px;
        padding: 8px 10px;
        background: #e20a00;
        border: #e20a00;
        color: #fff;
        border-radius: 5px;

        &:hover {
          background: ${shade(0.1, '#e20a00')};
        }
      }
    `}
`;
