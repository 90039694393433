import styled from 'styled-components';
import Form from '../../../components/Form';
import * as color from '../../../styles/colors';

export const Container = styled.div`
  position: relative;  
  .goback-button {
    justify-content:left;
    margin-bottom: 20px;
    p {
      color: ${color.orange};
    }
  }
`;

export const Content = styled.div`
    max-width: 714px;
    margin:0 auto;
`;

export const ContainerForm = styled(Form)`
  margin-bottom: 30px;

  .content-container {
    overflow-x: hidden;
    
    max-height: 320px;    
    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${color.orange};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #e5e5e5;
      background-color: #f5f5f5;
      border-radius: 4px;
    }
  }

  .column {
    width: 50%;
  }

  @media (max-width: 576px) {
    .column {
      width: 100%;
    }
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 10px;

  .switch-input {
    margin-right: 16px;
  }

  .switch-label {
    margin-right: 10px;
  }
`;

export const BlockPermission = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom:1px solid #f3f3f3;
  padding:5px 0px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  padding-bottom:5px;
  border-bottom:2px solid #f3f3f3;
`;
export const Col10 = styled.div`
  flex:0.1;  
`;
export const Col40 = styled.div`
  flex:0.4;
  @media (max-width: 769px) {
    flex:1.3;
  }
`;
export const Col25 = styled.div`
  flex:0.25;
  @media (max-width: 769px) {
    max-width: 15ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;