import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import PageTopbar from '../../../../components/PageTopbar';
import GobackButton from '../../../../components/GoBackButton';
import Card from '../../../../components/Card';
import Form from '../../../../components/Form';
// import Select from '../../../../components/AdminSelectInput';
import Input from '../../../../components/Input';
import api from '../../../../services/api';

const CompanyCreateCNPJ: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState<number>(0);
  const [companyName, setCompanyName] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { register, setValue, handleSubmit, errors, getValues } = useForm();

  const createPessoaJuridica = useCallback(
    async (data) => {
      setLoading(1);

      try {
        const response = await api.post('/companies', {
          ...data,
          fantasy_name: companyName,
          company_type: '2',
          country: 'brasil',
        });
        const company = response.data.data;
        setLoading(0);
        history.push(`/companies/company-detail/${company.id_code}`);
      } catch (error) {
        setLoading(0);
        setErrorMessage(error.response.data.errors.company_document);
      }
    },
    [companyName, history],
  );

  const formatCNPJ = useCallback(() => {
    const currentCNPJ = getValues('company_document');
    const formatedCNPJ = currentCNPJ
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

    setValue('company_document', formatedCNPJ);
  }, [getValues, setValue]);

  const handleChange = useCallback(
    async (e) => {
      const cnpj = e.target.value;
      const cnpjFormat = cnpj.replace(/\D/g, '');

      if (cnpjFormat.length === 14) {
        setErrorMessage('');
        setLoading(1);
        const formData = new FormData();
        formData.append('cnpj', cnpjFormat);
        try {
          const response = await api.post('/companies/consult-cnpj', formData);
          const { name } = response.data;

          setValue('fantasy_name', name);
          setCompanyName(name);
          setLoading(0);
        } catch (error) {
          setLoading(0);
          if (error.response.data.errors) {
            setErrorMessage(error.response.data.errors.cnpj);
          } else {
            setErrorMessage(error.response.data.message);
          }
        }
      }
    },
    [setValue],
  );

  return (
    <Container>
      <PageTopbar
        title="Criar empresa pessoa jurídica"
        subTitle="Gerencie as empresas relacionadas com seus produtos CloudFox"
      />
      <GobackButton
        onClick={() => history.goBack()}
        className="goback-button"
      />
      <Card cardTitle="Criar empresa" loading={loading}>
        <Form submitButton onSubmit={handleSubmit(createPessoaJuridica)}>
          {/* <Row>
            <Select label="País da empresa" className="col-md-5">
              <option>Brasil</option>
            </Select>
          </Row> */}
          <div className="row">
            <Input
              label="CNPJ"
              id="cnpj"
              name="company_document"
              mask="99.999.999/9999-99"
              type="cnpj"
              placeholder="CNPJ"
              className="col-md-4 col-xs-12"
              autoComplete="off"
              onKeyPress={formatCNPJ}
              // onBlur={formatCNPJ}
              register={register({
                required: { value: true, message: 'Campo obrigatório' },
                minLength: 14,
              })}
              maxLength={18}
              errorMessage={errors.company_document?.message}
              onChange={handleChange}
            />
            <Input
              label="Nome da empresa"
              id="company-name"
              name="fantasy_name"
              mask=""
              placeholder="Nome da empresa"
              className="col-md-8 col-xs-12"
              register={register}
              errorMessage={errorMessage}
              disabled
            />
          </div>
        </Form>
      </Card>
    </Container>
  );
};

export default CompanyCreateCNPJ;
