// Colors
export const orange = '#FF4E05';
export const lightorange = '#FFEFE9';
export const green = '#429586';
export const brightgreen = '#24EE75';
export const lightgreen = '#61D6C1';
export const blue = '#2F6BB2';
export const red = '#dd2f14';
export const gray = '#5B5B5B';
export const darkgray = '#56607e';
export const brightgray = '#E9E9E9';
export const lightgray = '#999999';
