/* eslint-disable eqeqeq */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Container, CompanyBox, Content } from './styles';
import Card from '../../../../../components/Card';
import Button from '../../../../../components/Button';
import api from '../../../../../services/api';
import { ICompany } from '../../../../../entities/Company';
import { AccountsContext as Context } from '../../../../../context/AccountsContext';

const SiriusCompanies: React.FC = () => {
  const { setSiriusLoadingSync } = useContext(Context);
  const [companies, setCompanies] = useState<[]>();
  const [taxType, setTaxType] = useState<string | Blob>('');
  const [loading, setLoading] = useState<number>(0);
  const [noCompaniesMessage, setNoCompaniesMessage] = useState<string>('');

  const handleChange = useCallback((e) => {
    setTaxType(e.currentTarget.value);
  }, []);

  const updateTax = useCallback(
    async (companyId) => {
      setLoading(1);

      try {
        await api.put(`companies/${companyId}/update-tax`, {
          gateway_release_payment: taxType,
        });
        setLoading(0);
        toast.success('Tarifas e prazos salvo com sucesso');
      } catch (error) {
        setLoading(0);
        toast.error('Ops! Algo saiu errado, tente novamente');
      }
    },
    [taxType],
  );

  const defaultSelectValue = (moneyDays: number | undefined) => {
    let plan;
    moneyDays == 2 && (plan = 'plan-2');
    moneyDays == 15 && (plan = 'plan-15');
    moneyDays == 30 && (plan = 'plan-30');

    return plan;
  };

  useEffect(() => {
    (async () => {
      setLoading(1);
      setSiriusLoadingSync(1);
      try {
        const response = await api.get('/companies');
        const companiesList = response.data.data;

        const newCompanies = companiesList.filter((c: ICompany) => {
          return c.get_net_status == true;
        });

        setCompanies(newCompanies);
        setLoading(0);
        setSiriusLoadingSync(0);

        if (newCompanies.length === 0) {
          setNoCompaniesMessage('Nenhuma empresa está aprovada no momento');
        }
      } catch (error) {
        setLoading(0);
        setSiriusLoadingSync(0);
      }
    })();
  }, [setSiriusLoadingSync]);

  return (
    <Container>
      <Card
        className="companies-list"
        cardTitle="Prazos e tarifas"
        loading={loading}
      >
        {companies && companies?.length !== 0 ? (
          <>
            <div className="top-tax-info">
              <p>
                Selecione abaixo as tarifas e prazos que mais se encaixam no
                modelo do seu negócio.
              </p>
              <br />
              <p>
              Em <b>produtos físicos</b> se aplicam o plano escolhido + envio do <b>código de rastreio válido.</b> Nós usamos este código para comprovar a legitimidade da transação e informar ao cliente cada passo da sua compra.
              </p>
              <br />
              <p>
              Ex.: Plano D15, o dinheiro estará disponível depois de 15 dias caso o código de rastreio esteja válido, caso contrário, a transação ficará pendente até o código ser reconhecido pelo sistema de rastreio.
              </p>
              <br />
              <p>
              Em <b>produtos digitais</b> existe a garantia de 7 dias exigidos pelo CDC (Código de Defesa do Consumidor) para o direito de arrependimento e o valor estará disponível para saque após este período no plano D2. No caso do plano D15, a garantia do cliente já terá expirado e você poderá sacar o valor imediatamente no 15º dia.
              </p>
            </div>
            {companies &&
              companies?.length !== 0 &&
              companies.map((c: ICompany) => (
                <CompanyBox key={c.id_code}>
                  <Content>
                    <div className="row">
                      <h5 className="header company-name col-sm-8 col-xs-12">
                        {c.fantasy_name}
                      </h5>

                      <h5 className="header company-document col-sm-4 col-xs-12">
                        {c.company_document}
                      </h5>
                    </div>
                    <div className="service-status row">
                      <select
                        name="tax"
                        className="col-md-12 tax-type"
                        onChange={handleChange}
                        defaultValue={defaultSelectValue(
                          c?.gateway_release_money_days,
                        )}
                      >
                        <option value="plan-2">
                          {' '}
                          2 dias (taxa de{' '}
                          {c.gateway_release_money_days == 2
                            ? c.gateway_tax
                            : '6.9'}
                          %)
                        </option>
                        <option value="plan-15">
                          15 dias (taxa de{' '}
                          {c.gateway_release_money_days == 15
                            ? c.gateway_tax
                            : '6.5'}
                          %)
                        </option>
                        <option value="plan-30">
                          30 dias (taxa de{' '}
                          {c.gateway_release_money_days == 30
                            ? c.gateway_tax
                            : '5.9'}
                          %)
                        </option>
                      </select>
                    </div>
                    <div className="company-footer-row row">
                      <div className="credit-card-tax col-sm-9 col-xs-12">
                        <p className="credit-card-tax-rate">
                          Taxa de parcelamento no cartão de crédito de{' '}
                          {c.installment_tax}% ao mês.
                        </p>
                      </div>
                      <div className="company-update-button col-sm-3 col-xs-12">
                        <Button
                          primaryPill
                          onClick={() => updateTax(c.id_code)}
                        >
                          Atualizar
                        </Button>
                      </div>
                    </div>
                  </Content>
                </CompanyBox>
                // }
              ))}

            <div className="bottom-tax-info">
              <p>Taxa fixa de R$ 1.00 por transação.</p>
              <p>
                Em boletos com o valor menor de R$ 40,00 a taxa cobrada será de
                R$ 3,00.
              </p>
            </div>
          </>
        ) : (
          <p className="no-companies-message">{noCompaniesMessage}</p>
        )}
      </Card>
    </Container>
  );
};

export default SiriusCompanies;
