import styled from 'styled-components';
import InputMask from 'react-input-mask';
import eyeIcon from '../../../../assets/eye.svg';
import * as color from '../../../../styles/colors';

interface InputProps {
  error?: string;
}

interface TextWrapperProps {
  split?: boolean;
}

export const TextWrapper = styled.div<TextWrapperProps>`
  position: relative;
  padding: 8px 0 0;
  margin-top: 35px;
  width: ${({ split }) => (split ? '45%' : '100%')};
  height: 20px;
  margin-bottom:15px
`;

export const Input = styled(InputMask)<InputProps>`
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${({ error }) => (error ? color.red : '#2e2e2e')};
  outline: 0;
  font-size: 1em;
  padding: 9px 0 4px 0;
  background: #ffffff;
  transition: border-color 0.2s;
  color: #2e2e2e;
  font-family: 'Roboto';
  font-weight: 400;

  ::placeholder {
    color: transparent;
  }

  :placeholder-shown ~ .form__label {
    font-size: 0.8em;
    cursor: text;
    top: 18px;
    color: ${({ error }) => (error ? color.red : '#bcbcbc')};
  }

  :focus ~ .form__label {
    color: ${({ error }) => (error ? color.red : '#2e2e2e')};
    position: absolute;
    top: 0px;
    display: block;
    transition: 0.2s;
    font-size: 12px;
  }

  :placeholder-shown {
    border-bottom: 1px solid ${({ error }) => (error ? color.red : '#2e2e2e')};
  }

  :focus {
    border-bottom: 2px solid ${({ error }) => (error ? color.red : '#2e2e2e')};
  }
`;

export const TextLabel = styled.label<InputProps>`
  position: absolute;
  top: 4.0px;
  display: block;
  transition: 0.2s;
  font-weight: normal;
  font-size: 12px;
  color: ${({ error }) => (error ? color.red : '#2e2e2e')};
  font-family: 'Roboto';
  opacity: 0.8;
`;

export const ShowPasswordButton = styled.button`
  width: 20px;
  height: 20px;
  background-image: url(${eyeIcon});
  background-color: transparent;
  background-size: 20px;
  border: none;
  cursor: pointer;
  top: 12px;
  right: 0px;
  position: absolute;
  filter: opacity(0.5);
`;
