import styled from 'styled-components';
import ContentBox from '../../../components/ContentBox';
import * as color from '../../../styles/colors';

export const Container = styled.div`
  position: relative;

  .coming-soon-service {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eaeaea;
  }

  a {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .vega {
    background: #eaeaea;
    img {
      filter: grayscale(100%);
    }
  }
`;

export const ServiceType = styled(ContentBox)`
  height: 154px;
  width: 203px;
  padding: 20px;
  transition: transform 0.3s;
  /* margin-right: 20px; */

  &:not(:last-child) {
    margin-right: 20px;
  }

  .logo {
    margin-bottom: 22px;
  }

  .sirius-logo,
  .vega-logo {
    width: 85px;
  }

  .mockup {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-bottom: 10px;
  }

  .company-name {
    margin-bottom: 8px;
  }

  .last-access,
  .access-date {
    font-size: 10px;
    line-height: 16px;
  }

  &:hover {
    transform: scale(1.07);
  }

  @media (max-width: 546px) {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0px !important;
  }
`;

export const SectionTitle = styled.h3`
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 40px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
`;

export const AddButton = styled.button`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  right: 10px;
  top: 10px;
  border-radius: 63px;
  background: ${color.orange};
  border: 1px solid ${color.orange};
  box-shadow: 0px 6px 12px rgba(14, 40, 93, 0.09);

  img {
    width: 14px;
    height: 14px;
  }
`;
