import React, { useEffect, useState, useContext, useCallback } from 'react';
import Button from '../../../components/Button';
import CheckboxInput from '../../../components/CheckboxInput';
import ContainerPage from '../../../components/ContainerPage';
import Tag from '../../../components/Tag';
import TextInput from '../SignupComponents/TextInput';
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import {Container} from './styles'
import GoBackButton from '../../../components/GoBackButton';
import arrowRight  from '../../../assets/arrow-right.svg'
import useLocalStorage from '../../../hooks/useLocalStorage';

const AccountLinksView: React.FC = () => {

  const {
    nextStep,
    storeLink,
    setStoreLink,
    gatewayName,
    setGatewayName,
    currentEcommerce,
    setCurrentEcommerce,
    howMeet,
    setHowMeet,
    previousStep,
  } = useContext(Context);

  const [ shopLinkError, setStoreLinkError ] = useState<string>('');
  const [ gatewayNameError, setGatewayNameError ] = useState<string>('');

  const [ shopifyCheck, setShopifyCheck ] = useState<boolean>(false);
  const [ integratedStoreCheck, setIntegratedStoreCheck ] = useState<boolean>(false);
  const [ wixCheck, setWixCheck ] = useState<boolean>(false);
  const [ wooCommerceCheck, setWooCommerceCheck ] = useState<boolean>(false);
  const [ pageLandCheck, setPageLandCheck ] = useState<boolean>(false);
  const [ otherEcommerceCheck, setOtherEcommerceCheck ] = useState(false);
  const [ otherEcommerceName, setOtherEcommerceName ] = useState<string>('')

  const [ facebookCheck, setFacebookCheck ] = useState<boolean>(false);
  const [ adCheck, setAdCheck ] = useState<boolean>(false);
  const [ youtubeCheck, setYoutubeCheck ] = useState<boolean>(false);
  const [ linkedinCheck, setLinkedinCheck ] = useState<boolean>(false);
  const [ emailCheck, setEmailCheck ] = useState<boolean>(false);
  const [ instagramCheck, setInstagramCheck ] = useState<boolean>(false);
  const [ recommendationCheck, setRecommendationCheck ] = useState<boolean>(false);
  const [ orthersCheck, setOrthersCheck ] = useState<boolean>(false);

  const [ disableStoreLink, setDisableStoreLink ] = useLocalStorage("hasStoreLink", false);
  const [ disableGatewayName, setDisableGatewayName ] = useLocalStorage("hasGatewayName", false);

  const [ disableProgress, setDisableProgress ] = useState<boolean>(true);

  useEffect(() => {    
    var currentEcommerceJSON = JSON.parse(currentEcommerce);
    setShopifyCheck(currentEcommerceJSON.shopify === 1? true : false);
    setIntegratedStoreCheck(currentEcommerceJSON.integratedStore === 1? true : false)
    setWixCheck(currentEcommerceJSON.wix === 1? true : false)
    setWooCommerceCheck(currentEcommerceJSON.wooCommerce === 1? true : false)
    setPageLandCheck(currentEcommerceJSON.pageLand === 1? true : false)
    setOtherEcommerceCheck(currentEcommerceJSON.otherEcommerce === 1? true : false)
    setOtherEcommerceName(currentEcommerceJSON.otherEcommerceName !== ""? currentEcommerceJSON.otherEcommerceName : "");

    var howMeetJSON = JSON.parse(howMeet);
    setFacebookCheck(howMeetJSON.facebook === 1? true : false);
    setAdCheck(howMeetJSON.ad === 1? true : false);
    setYoutubeCheck(howMeetJSON.youtube === 1? true : false);
    setLinkedinCheck(howMeetJSON.linkedin === 1? true : false);
    setEmailCheck(howMeetJSON.email === 1? true : false);
    setInstagramCheck(howMeetJSON.instagram === 1? true : false);
    setRecommendationCheck(howMeetJSON.recomendation === 1? true : false);
    setOrthersCheck(howMeetJSON.other === 1? true : false);

    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    var ecommercesJSON = {
      shopify: shopifyCheck? 1 : 0,
      integratedStore: integratedStoreCheck? 1 : 0,
      wix: wixCheck? 1 : 0,
      wooCommerce: wooCommerceCheck? 1 : 0,
      pageLand: pageLandCheck? 1 : 0,
      otherEcommerce: otherEcommerceCheck? 1 : 0,
      otherEcommerceName: otherEcommerceName,
    }

    var howMeetJSON = {
      facebook: facebookCheck? 1 : 0,
      ad: adCheck? 1 : 0,
      youtube: youtubeCheck? 1 : 0,
      linkedin:  linkedinCheck? 1 : 0,
      email:  emailCheck? 1 : 0,
      instagram:  instagramCheck? 1 : 0,
      recomendation:  recommendationCheck? 1 : 0,
      other: orthersCheck? 1 : 0,
    }

    setCurrentEcommerce(JSON.stringify(ecommercesJSON))
    setHowMeet(JSON.stringify(howMeetJSON))

  }, [ shopifyCheck, integratedStoreCheck, wixCheck, wooCommerceCheck, otherEcommerceCheck, 
       otherEcommerceName, facebookCheck, adCheck, youtubeCheck, linkedinCheck, emailCheck, 
       instagramCheck, recommendationCheck, orthersCheck, pageLandCheck, setCurrentEcommerce, setHowMeet ])

  useEffect(() => {
    otherEcommerceCheck === false && setOtherEcommerceName("")
  }, [otherEcommerceCheck])

  const handleDisableStoreLink = useCallback(() => {
    setDisableStoreLink(!disableStoreLink);
    localStorage.removeItem('storeLink');
    setStoreLink("");
    setStoreLinkError("");
  }, [disableStoreLink, setDisableStoreLink, setStoreLink, setStoreLinkError]);

  const handleDisableGateway = useCallback(() => {
    setDisableGatewayName(!disableGatewayName);
    localStorage.removeItem('gatewayName');
    setGatewayName("");
    setGatewayNameError("");
  }, [disableGatewayName, setDisableGatewayName, setGatewayName]);
  
  const validateInputs = useCallback(() => {
    if(disableStoreLink === false && (!storeLink || storeLink === ' ')){
      setStoreLinkError('Por favor, preencha esse campo com um link.')
      return
    }

    if(( !gatewayName || gatewayName === '' )&& !disableGatewayName){
      setGatewayNameError('Por favor, preencha esse campo com o nome do gateway.')
      return
    }

    nextStep();
  
  }, [ disableStoreLink, storeLink, disableGatewayName, gatewayName, nextStep ]);


  useEffect(() => {
    if( (disableStoreLink === false && (!storeLink || storeLink === ' ')) || (( !gatewayName || gatewayName === '' )&& !disableGatewayName) ){
      setDisableProgress(true)
    }else{
      setDisableProgress(false)
    }

  }, [disableStoreLink, storeLink, gatewayName, disableGatewayName, setDisableProgress])

  return (
    <ContainerPage randomBkg showLogo>
      <Container>
        <p>Link para seu site de vendas</p>
        <TextInput
          id="store-link"
          className="shop-link input"
          name="shop-link"
          mask=""
          type="text"
          placeholder="Cole aqui o link para seu site."
          value={storeLink}
          error={shopLinkError}
          disabled={disableStoreLink}
          onChange={(e) => {
            setStoreLink(e.target.value)
            setStoreLinkError('')
          }}
        />

        <CheckboxInput
          id="has-shop-link"
          name="has-shop-link"
          className="checkbox"
          label='Não tenho um site de vendas'
          checked={disableStoreLink}
          onChange={handleDisableStoreLink}
        /> 

        <p>Qual gateway você utiliza hoje?</p>
        <TextInput
          id="gateway-name"
          className="gateway-name input"
          name="gateway-name"
          mask=""
          type="text"
          placeholder="Digite o nome do gateway"
          value={gatewayName}
          error={gatewayNameError}
          disabled={disableGatewayName}
          onChange={(e) => {
            setGatewayName(
              e.currentTarget.value.replace(/(^\w|\s\w)/g, (m: any) =>
                m.toUpperCase(),
              ),
            )
            setGatewayNameError('')
          }}
        />
        
        <CheckboxInput
          id="has-shop-link"
          className="checkbox"
          name="has-shop-link"
          checked={disableGatewayName}
          onChange={handleDisableGateway}
          label='Não utilizo nenhum gateway'
        /> 

        <p>Qual eCommerce você usa hoje?</p>
        <div className="tags tag-ecommerce">
          <Tag checked={shopifyCheck} setChecked={setShopifyCheck}>
            Shopify
          </Tag>

          <Tag checked={integratedStoreCheck} setChecked={setIntegratedStoreCheck}>
            Loja Integrada
          </Tag>

          <Tag checked={wixCheck} setChecked={setWixCheck}>
            Wix
          </Tag>

          <Tag checked={wooCommerceCheck} setChecked={setWooCommerceCheck}>
            WooCommerce
          </Tag>

          <Tag checked={pageLandCheck} setChecked={setPageLandCheck}>
            Landing Page
          </Tag>

          <Tag checked={otherEcommerceCheck} setChecked={setOtherEcommerceCheck}>
            Outro
          </Tag>

          { otherEcommerceCheck ? (
            <TextInput
            id="other-ecommerce"
            className="other-ecommerce input"
            name="other-ecommerce"
            mask=""
            type="text"
            placeholder="Digite o nome"
            value={otherEcommerceName}
            onChange={(e) =>
              setOtherEcommerceName(
                e.currentTarget.value.replace(/(^\w|\s\w)/g, (m: any) =>
                  m.toUpperCase(),
                ),
              )}
            />
          ) : (<div> </div>)}
          
        </div>

        <p>Como você ficou sabendo da Cloudfox?</p>
        <div className="tags">
          <Tag checked={facebookCheck} setChecked={setFacebookCheck}>
            Facebook
          </Tag>

          <Tag checked={adCheck} setChecked={setAdCheck}>
            Anúncios
          </Tag>

          <Tag checked={youtubeCheck} setChecked={setYoutubeCheck}>
            Youtube
          </Tag>

          <Tag checked={linkedinCheck} setChecked={setLinkedinCheck}>
            Linkedin
          </Tag>

          <Tag checked={emailCheck} setChecked={setEmailCheck}>
            E-mail
          </Tag>

          <Tag checked={instagramCheck} setChecked={setInstagramCheck}>
            Instagram
          </Tag>

          <Tag checked={recommendationCheck} setChecked={setRecommendationCheck}>
            Indicação
          </Tag>

          <Tag checked={orthersCheck} setChecked={setOrthersCheck}>
            Outros
          </Tag>
        </div>
        
        <div className="buttons-container">
          <Button
            primary
            type="button"
            className="btn-continue"
            textTransform
            onClick={validateInputs}
            disabled={disableProgress}
          >
            Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
          </Button>
          
          <GoBackButton className='goback-button' onClick={() => previousStep()} />
        </div>
      </Container>
    </ContainerPage>
  )
}

export default AccountLinksView;