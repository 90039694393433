import styled from 'styled-components';
import Select from 'react-select';
// import arrowDownIcon from '../../assets/chevron-down.svg';
import * as color from '../../../../styles/colors';

interface SelectProps {
  error?: string;
  split?: boolean;
  selectType?: string;
}

export const Container = styled.div<SelectProps>`
  position: relative;
  width: ${({ split }) => (split ? '45%' : '100%')};
  padding: 8px 0 0;
  margin-top: 27px;
  height: 20px;
`;

export const SelectInput = styled(Select)`
  .register {
    &__control {
      display: flex;
      border: none;
      border-bottom: 1px solid ${({ error }) => (error ? color.red : '#2e2e2e')};
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &--is-focused {
        border-color: #2e2e2e;
        outline: 0 !important;
        box-shadow: none;
        :hover {
          border-color: #2e2e2e;
        }
      }

      ::placeholder {
      }
    }

    &__placeholder {
      /* color: transparent !important; */
      top: 55%;
    }

    &__value-container {
      align-self: flex-end;
      height: 25px;
      color: #2e2e2e;
      font-size: 14px;
      font-weight: 400;
    }

    &__indicator-separator {
      display: none;
    }

    &__indicator {
      svg {
        stroke: #2e2e2e;
        fill: #2e2e2e;
      }
    }

    &__single-value {
      top: 55%;
      width: 100%;
    }
  }
`;

export const TextLabel = styled.label<SelectProps>`
  position: absolute;
  top: 8px;
  display: block;
  transition: 0.2s;
  font-weight: normal;
  font-size: 12px;
  color: ${({ error }) => (error ? color.red : '#2e2e2e')};
  font-family: 'Roboto';
  opacity: 0.8;
`;
