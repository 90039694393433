import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .input {
    margin-top: 0px;
    margin-bottom: 10px
  }

  .tags {
    display: flex;
    flex-wrap: wrap;    
    margin-bottom: 30px;

    .input {
      width: 78px;
    }
  }

  #other-ecommerce {
    padding: 9px 0 0 0;    
  }

  .checkbox {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .goback-button {
    margin-top: 10px;
  }

  @media (min-width: 576px) {
    width: 420px;
    padding: 10px;
`;