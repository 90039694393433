import styled from 'styled-components';
import * as color from '../../../styles/colors';

interface HomeProps {
  avatarImg?: string;
}

export const Container = styled.div`
  position: relative;
  display: block;
  padding-top: 110px;

  .card {
    margin: 10px;

    @media (max-width: 765px) {
      margin: 10px 0;
    }
  }

  .service-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .sirius-img {
      height: 40px;
      margin-bottom: 30px;
    }

    .vega-img {
      height: 40px;
      margin-bottom: 30px;
      filter: grayscale(100%);
    }
  }

  .vega {
    background: #eaeaea;
    cursor: default;

    .card-footer {
      background: #eaeaea;
      cursor: default;

      > p {
        color: #56607e !important;
      }
    }
  }

  @media (max-width: 769px) {
    padding-top: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 765px) {
    display: block;
  }
`;

export const TopbarContainer = styled.div`
  position: absolute;
  top: -130px;
  left: -20px;
  right: -20px;
  background: ${color.orange};
`;

export const Title = styled.h3`
  font-size: 24px;
  color: #fff !important;
  line-height: 30px;
  margin-bottom: 12px;
`;

export const SubTitle = styled.p`
  font-size: 14px;
  color: #fff !important;
  line-height: 17px;
`;

export const ContentText = styled.div`
  /* display: flex;
  justify-content: center; */
  text-align: center;
  padding: 25px 20px 30px;
  margin-left: 270px;
  display: block;
  margin: auto;

  @media (max-width: 992px) {
    margin-left: 0;
    display: none;
  }
`;

export const Avatar = styled.div`
  width: 90px;
  height: 90px;
  border-radius: 45px;

  background-size: cover;
  margin: 0 auto 15px;

  .photo {
    min-width: 90px;
    height: 90px;
    border: 4px solid #ffc294;
    border-radius: 50px;
  }
`;
