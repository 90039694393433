import React, { ReactNode } from 'react';
import { Container } from './styles'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

interface CarouselProps {
  children: ReactNode;
  dots: boolean;
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  slideToScroll: number;
  beforeChange?: (current:number, next:number) => void;
  fade?: boolean;
  autoplay?: boolean;
  autoplaySpeed?: number;
}

function NextArrow(props:any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "#ff4e05" }}
      onClick={onClick}
    ></div>
  );
}

function PrevArrow(props:any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "#ff4e05" }}
      onClick={onClick}
    ></div>
  );
}

const Carousel:React.FC<CarouselProps> = ({
  children,
  dots,
  infinite,
  speed,
  slidesToShow,
  slideToScroll,
  beforeChange,
  fade,
  autoplay,
  autoplaySpeed,
}) => {  
  return(
    <Container>
      <Slider 
      className='center'
      dots={dots}
      infinite={infinite}
      speed={speed}
      slidesToShow={slidesToShow}
      slidesToScroll={slideToScroll}
      nextArrow={<NextArrow/>}
      prevArrow={<PrevArrow/>}
      fade={fade}
      beforeChange={beforeChange}
      autoplay={autoplay}
      autoplaySpeed={autoplaySpeed}
      >
        {children}
      </Slider>
    </Container>   
  )
}

export default Carousel;