import React from 'react';
import { Container } from './styles'

interface ScrollContainerProps {
  height?: string|number,
  width?: string|number,
  className?: string,
  fadeEfect?: boolean,
}

const ScrollContainer:React.FC<ScrollContainerProps> = ({
  children,
  height,
  width,
  className,
  fadeEfect,
}
) => {
  return (
    <Container className={className} height={height || 'autp'} width={width || 'auto'}>
      {fadeEfect && (
        <div className="transparent-effect top"></div>
      )}
      {children}
      {fadeEfect && (
        <div className="transparent-effect botton"></div>
      )}
    </Container>
  )
}

export default ScrollContainer;