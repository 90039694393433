import styled from 'styled-components';
import { shade } from 'polished';
import * as color from '../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  .goback-button {
    margin-top: 10px;
  }

  .verification-code-input {
    width: 100% !important;
    margin: 20px 0;

    > div {
      display: flex;
      justify-content: space-between;

      input {
        /* width: 68px !important;
        height: 91px !important; */
        border: 1px solid #434343;
        border-radius: 10px;
        font-size: 40px;
        font-family: Roboto;
      }
    }
  }

  @media (min-width: 576px) {
    width: 420px;
    padding: 10px;
  }
`;

export const ExpiringCodeText = styled.div`
  font-size: 12px;
  text-align: center;
  color: #595959;
  margin-top: 20px;
  margin-bottom: 17px;
`;

export const SendCodeText = styled.button`
  text-align: center;
  color: ${color.orange};
  border: 0;
  background: transparent;

  cursor: pointer;

  &:hover {
    color: ${shade(0.2, color.orange)};
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
`;
