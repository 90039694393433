import styled, { keyframes } from 'styled-components';
import Cep from 'react-cep-promise';
import * as color from '../../../styles/colors';

interface AddressProps {
  show?: boolean;
  error?: string;
}

interface FadeProps {
  show?: boolean;
  seconds?: string;
}

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  > p {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .btn-continue {
    margin: 35px 0 0 0;
  }

  @media (min-width: 576px) {
    width: 420px;
    padding: 10px;
  }
`;

export const HideContainer = styled.div<AddressProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

export const GoBackContainer = styled.div<AddressProps>`
  margin-top: ${({ show }) => (show ? '0' : '0px')};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CepInput = styled(Cep)<AddressProps>`
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${({ error }) => (error ? color.red : '#2e2e2e')};
  outline: 0;
  font-size: 1em;
  padding: 9px 0 4px 0;
  background: #ffffff;
  transition: border-color 0.2s;
  color: #2e2e2e;
  font-family: 'Roboto';
  font-weight: 400;

  ::placeholder {
    color: transparent;
  }

  :placeholder-shown ~ .form__label {
    font-size: 1em;
    cursor: text;
    top: 18px;
    color: ${({ error }) => (error ? color.red : '#bcbcbc')};
  }

  :focus ~ .form__label {
    color: ${({ error }) => (error ? color.red : '#2e2e2e')};
    position: absolute;
    top: -3px;
    display: block;
    transition: 0.2s;
    font-size: 12px;
  }

  :placeholder-shown {
    border-bottom: 1px solid ${({ error }) => (error ? color.red : '#2e2e2e')};
  }

  :focus {
    border-bottom: 2px solid ${({ error }) => (error ? color.red : '#2e2e2e')};
  }
`;

export const TextLabel = styled.label`
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-weight: normal;
  font-size: 12px;
  color: #2e2e2e;
  font-family: 'Poppins';
  opacity: 0.8;
`;

export const TextWrapper = styled.div`
  position: relative;
  padding: 8px 0 0;
  width: 100%;
  height: 20px;
  margin-bottom: 20px;
`;

const fadeIn = keyframes`
  0% {
    display: block;
    opacity: 0;
  }
  99% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
    display: none;
  }
`

export const FadeContainer = styled.div<FadeProps>`

  ${({ show }) => show === true ? (
    'display: block;'
  ) : (
    'display: none;'
  ) }

  animation: ${({seconds}) => ( seconds? seconds : 0.5)}s ${({ show }) => (show === true ? fadeIn : fadeOut )};
  animation-fill-mode: forwards; 
`

