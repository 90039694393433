import styled from 'styled-components';
import * as color from '../../styles/colors';
interface SwitchInputProps {
  disable?:boolean;
}

export const Container = styled.div<SwitchInputProps>`
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  input:checked + .slider {
    background-color: #FF6D00;
    opacity: ${ props => props.disable ? '0.5' : '1'}
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #00be9b;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  input:checked + .switch-pix {
    background: ${color.brightgreen} !important;
  }
`;
