import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Container } from './styles';
import FormWrapper from '../../../../../components/Form';
import Input from '../../../../../components/Input';
import InputCep from '../../../../../components/InputCep';
import Button from '../../../../../components/Button';
import { ICompany } from '../../../../../entities/Company';
import api from '../../../../../services/api';
import { AccountsContext as Context } from '../../../../../context/AccountsContext';

interface CompanyProfileProps {
  company: ICompany | undefined;
}

type FormData = {
  city: string;
  state: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  zip_code: string;
};

const CompanyAddressForm: React.FC<CompanyProfileProps> = ({ company }) => {
  const { setDisableUploadCompanyAddressProveDocuments } = useContext(Context);
  const [loading, setLoading] = useState<number>(0);

  const { register, reset, setValue, handleSubmit, errors } = useForm<FormData>(
    {
      defaultValues: {
        ...company,
      },
    },
  );

  useEffect(() => {
    reset({
      ...company,
    });

    if (
      company?.zip_code === '' ||
      company?.zip_code === null ||
      company?.street === '' ||
      company?.street === null ||
      company?.city === '' ||
      company?.city === null ||
      company?.state === '' ||
      company?.state === null ||
      company?.neighborhood === '' ||
      company?.neighborhood === null
    ) {
      setDisableUploadCompanyAddressProveDocuments(true);
    } else {
      setDisableUploadCompanyAddressProveDocuments(false);
    }
  }, [company, reset, setDisableUploadCompanyAddressProveDocuments]);

  const onSubmit = useCallback(
    async (data: FormData) => {
      setLoading(1);
      try {
        await api.put(`/companies/address/${company?.id_code}`, {
          ...data,
          country: 'brazil',
        });
        setLoading(0);
        toast.success('Endereço atualizado com sucesso!');
        setDisableUploadCompanyAddressProveDocuments(false);
      } catch (error) {
        setLoading(0);
      }
    },
    [company, setDisableUploadCompanyAddressProveDocuments],
  );

  return (
    <Container cardTitle="Endereço" loading={loading}>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <InputCep
            label="CEP"
            id="zipcode"
            name="zip_code"
            className="col-xs-12 col-sm-3"
            type="text"
            mask="99999-999"
            value={company?.zip_code}
            setValue={setValue}
            setLoading={setLoading}
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            disabled={!company?.can_edit_address_information}
            errorMessage={errors.zip_code?.message}
          />
          <Input
            mask=""
            label="Rua"
            id="street"
            name="street"
            className="col-xs-12 col-sm-7"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
              minLength: {
                value: 3,
                message: 'Obrigatorio pelo menos 3 caracteres',
              },
            })}
            disabled={!company?.can_edit_address_information}
            errorMessage={errors.street?.message}
          />
          <Input
            mask=""
            label="Número"
            id="number"
            name="number"
            className="col-xs-6 col-sm-2"
            register={register}
            disabled={!company?.can_edit_address_information}
          />
          <Input
            mask=""
            label="Complemento"
            id="complement"
            name="complement"
            className="col-xs-6 col-sm-5"
            register={register}
            disabled={!company?.can_edit_address_information}
          />
          <Input
            mask=""
            label="Cidade"
            id="city"
            name="city"
            className="col-xs-6 col-sm-5"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            disabled={!company?.can_edit_address_information}
            errorMessage={errors.city?.message}
          />
          <Input
            mask=""
            label="Estado"
            id="state"
            name="state"
            className="col-xs-6 col-sm-2"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            disabled={!company?.can_edit_address_information}
            errorMessage={errors.state?.message}
          />
          <Input
            label="Bairro"
            id="neighborhood"
            name="neighborhood"
            className="col-xs-6 col-sm-5"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            disabled={!company?.can_edit_address_information}
            errorMessage={errors.neighborhood?.message}
          />
          {/* Has to be a SELECT */}
          {/* <Input
            mask=""
            label="País"
            id="country"
            name="country"
            className="col-xs-6 col-sm-5"
          /> */}
        </div>
        {company?.can_edit_address_information && (
          <div
            className="row"
            style={{ margin: '20px 0 0 0', justifyContent: 'flex-end' }}
          >
            <Button primaryPill>Salvar</Button>
          </div>
        )}
      </FormWrapper>
    </Container>
  );
};

export default CompanyAddressForm;
