/* eslint-disable eqeqeq */
import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Container, SectionTitle } from './styles';
import FormWrapper from '../../../../components/Form';
import UploadBox from '../../../../components/UploadBox';
import Badge from '../../../../components/Badge';
import Button from '../../../../components/Button';
import ErrorMessage from '../../../../components/ErrorMessage';
import Modal from '../../../../components/ModalAdmin';
import { IUser } from '../../../../entities/User';
import useFileUpload from '../../../../hooks/useFileUpload';
import { isValidFileType } from '../../../../utils/functions.js';
import useToggle from '../../../../hooks/useToggle';
import UploadTable from '../../../../components/UploadTable';
import { AccountsContext as Context } from '../../../../context/AccountsContext';

interface ResidenceFormProps {
  user: IUser | undefined;
  setUser: () => void;
}

const errorFileTypeMessage =
  'Por favor, confira se seu arquivo é do tipo .jpeg, .jpg, .png, .pdf e ele deve possuir no máximo 10MB de tamanho.';

const DocumentsForm: React.FC<ResidenceFormProps> = ({ user }) => {
  const {
    disableUploadPersonalDocuments,
    disableUploadResidenceProveDocuments,
  } = useContext(Context);
  const [documentErrorMsg, setDocumentErrorMsg] = useState('');
  const [addressErrorMsg, setAddressErrorMsg] = useState('');
  const { loadingStatus, uploadFile, uploadStatus } = useFileUpload();
  const [isModalOpen, setIsModalOpen] = useToggle(false);
  const [modalTitle, setModalTitle] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const toScrollRef = useRef() as MutableRefObject<HTMLDivElement>;
  const location = useLocation();

  const handleUserModal = useCallback(
    (title, endpointRoute) => {
      setModalTitle(title);
      setEndpoint(endpointRoute);
      setIsModalOpen();
    },
    [setIsModalOpen],
  );

  const userModal = (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={setIsModalOpen}
      closeModal={setIsModalOpen}
      headerLabel={modalTitle}
    >
      <UploadTable endpoint={endpoint} />
    </Modal>
  );

  const uploadUserDocuments = useCallback(
    (file, type) => {
      if (isValidFileType(file)) {
        uploadFile(file, type, 'profile/upload-documents');
      } else {
        type === 'USUARIO_DOCUMENTO' &&
          setDocumentErrorMsg(errorFileTypeMessage);
        type === 'USUARIO_RESIDENCIA' &&
          setAddressErrorMsg(errorFileTypeMessage);
      }
    },
    [uploadFile],
  );

  const tooltipUserInfo = (
    <ReactTooltip
      effect="solid"
      place="top"
      id="tooltip-user"
      aria-haspopup="true"
    >
      <p style={{ color: '#fff' }}>
        Por favor, verifique se o seu nome está preenchido no campo acima
      </p>
      <p style={{ color: '#fff' }}>
        O campo de upload só será liberado após o preenchimento dos seus nome.
      </p>
    </ReactTooltip>
  );

  const tooltipUserAddress = (
    <ReactTooltip
      effect="solid"
      place="top"
      id="tooltip-address"
      aria-haspopup="true"
    >
      <p style={{ color: '#fff' }}>
        Por favor, verifique se os dados residenciais estão devidamente
        preenchidos nos campos acima
      </p>
      <p style={{ color: '#fff' }}>
        O campo de upload só será liberado após o preenchimento dos seus dados
        residenciais.
      </p>
    </ReactTooltip>
  );

  const scrollToElement = useCallback(() => {
    toScrollRef.current &&
      toScrollRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [toScrollRef]);

  useEffect(() => {
    location.hash == '#documents' && scrollToElement();
  }, [
    disableUploadPersonalDocuments,
    disableUploadResidenceProveDocuments,
    location.hash,
    scrollToElement,
  ]);

  return (
    <Container cardTitle="Documentos pessoais" loading={loadingStatus}>
      {userModal}
      <FormWrapper>
        <p className="subtitle">
          Para manter a nossa comunidade segura, precisamos comprovar alguns
          dados através dos seus documentos pessoais.
        </p>
        <div className="row" style={{ margin: '0 -5px' }} ref={toScrollRef}>
          <div className="col col-md-12" style={{ marginBottom: '50px' }}>
            <div className="section-title-badge row">
              <SectionTitle className="col col-sm-5">
                Documento de identidade
              </SectionTitle>
              <div className="col col-sm-3 col-xs-6">
                <Badge
                  status={user?.personal_document_translate}
                  className="badge"
                />
              </div>
              <div className="col col-sm-4 col-xs-6">
                <Button
                  type="button"
                  className="view-uploads-button"
                  onClick={() =>
                    handleUserModal(
                      'Documento de identidade',
                      '/profile/get-documents?document_type=USUARIO_DOCUMENTO',
                    )
                  }
                >
                  Ver uploads
                </Button>
              </div>
            </div>

            {user?.personal_document_translate != 'Aprovado' && (
              <>
                <p style={{ marginBottom: '15px' }}>
                  Qualquer documento no nome do usuário com foto, dentro da
                  validade, que também conste o CPF (pode ser via digital ou
                  foto):
                </p>
                <ul style={{ marginLeft: '30px' }}>
                  <div className="row" style={{ margin: '0' }}>
                    <li className="col-xs-12 col-md-7">
                      RG (Os 2 lados, FRENTE e VERSO)
                    </li>
                    <li className="col-xs-12 col-md-5">CNH</li>
                    <li className="col-xs-12 col-md-7">Carteira de trabalho</li>
                    <li className="col-xs-12 col-md-5">
                      Carteira funcional (OAB, CRF, CRC, CRM etc).
                    </li>
                  </div>
                </ul>
                <div data-for="tooltip-user" data-tip>
                  <UploadBox
                    id="uploadPersonalDocument"
                    className="uploadbox"
                    wholeFile={(file: File) =>
                      uploadUserDocuments(file, 'USUARIO_DOCUMENTO')}
                    disabled={disableUploadPersonalDocuments}
                    uploadStatus={uploadStatus}
                  />
                </div>
                {documentErrorMsg && (
                  <ErrorMessage>{documentErrorMsg}</ErrorMessage>
                )}
                {disableUploadPersonalDocuments && tooltipUserInfo}
              </>
            )}
          </div>
          <div className="col col-md-12">
            <div className="section-title-badge row">
              <SectionTitle className="col col-sm-5">
                Comprovante de residência
              </SectionTitle>
              <div className="col col-sm-3 col-xs-6">
                <Badge
                  status={user?.address_document_translate}
                  className="badge"
                />
              </div>
              <div className="col col-sm-4 col-xs-6">
                <Button
                  type="button"
                  className="view-uploads-button"
                  onClick={() =>
                    handleUserModal(
                      'Comprovante de residência',
                      '/profile/get-documents?document_type=USUARIO_RESIDENCIA',
                    )}
                >
                  Ver uploads
                </Button>
              </div>
            </div>

            {user?.address_document_translate != 'Aprovado' && (
              <>
                <p style={{ marginBottom: '15px' }}>
                  O Comprovante de endereço deve ser no nome do usuário e estar
                  dentro do prazo de 90 dias de vencimento. São aceitos como
                  comprovantes de residência as vias digitais ou fotos das
                  contas de:
                </p>
                <ul style={{ marginLeft: '30px' }}>
                  <div className="row" style={{ margin: '0' }}>
                    <li className="col-xs-12 col-md-7">Água</li>
                    <li className="col-xs-12 col-md-5">Luz</li>
                    <li className="col-xs-12 col-md-7">Internet</li>
                    <li className="col-xs-12 col-md-5">
                      Telefone fixo ou móvel
                    </li>
                    <li className="col-xs-12 col-md-5">Gás encanado</li>
                  </div>
                </ul>
                <p style={{ marginTop: '15px', fontSize: '11px' }}>
                  OBS: Caso o comprovante esteja em nome de terceiros, é
                  necessário enviar uma declaração de residência assinada pelo
                  titular da conta e uma foto do mesmo.
                </p>
                <div data-for="tooltip" data-tip>
                  <UploadBox
                    id="uploadResidenceProve"
                    className="uploadbox"
                    wholeFile={(file: File) =>
                      uploadUserDocuments(file, 'USUARIO_RESIDENCIA')}
                    disabled={disableUploadResidenceProveDocuments}
                    uploadStatus={uploadStatus}
                  />
                </div>
                {addressErrorMsg && (
                  <ErrorMessage>{addressErrorMsg}</ErrorMessage>
                )}
                {disableUploadResidenceProveDocuments && tooltipUserAddress}
              </>
            )}
          </div>
        </div>
      </FormWrapper>
      {(disableUploadResidenceProveDocuments ||
        disableUploadPersonalDocuments) && (
        <ReactTooltip
          effect="solid"
          place="top"
          id="tooltip"
          aria-haspopup="true"
        >
          <p style={{ color: '#fff' }}>
            Por favor, verifique se suas informações pessoais e seus
          </p>
          <p style={{ color: '#fff' }}>
            dados residenciais estão corretamente preenchidos.
          </p>
          <p style={{ color: '#fff' }}>
            Os campos de upload serão liberados após o preenchimento dos seus
            dados.
          </p>
        </ReactTooltip>
      )}
    </Container>
  );
};

export default DocumentsForm;
