import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ContainerPage from '../../components/ContainerPage';
import TextInput from '../Signup/SignupComponents/TextInput';
import Title from '../../components/Title';
import Button from '../../components/Button';

import api from '../../services/api';

import { Container, Row } from './styles';

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const sendEmail = async () => {
    if (email) {
      setLoading(1);
      const formData = new FormData();

      formData.append('email', email);

      try {
        const res = await api.post('/password/email', formData);
        setLoading(0);
        toast.success(res.data.message);
        setEmail('');
      } catch (error) {
        console.log(error);
        
        toast.error(error.response.data.message)
        setLoading(0);
        error && setErrorMsg(error.response.data.error);
      }
    } else {
      setErrorMsg('Precisamos do seu email para redefinir sua nova senha');
    }
  };

  return (
    <ContainerPage emailBkg showLogo loading={loading}>
      <Container>
        <Title>Esqueceu a senha?</Title>
        <p>
          Não tem problema! Só precisamos do email que você usou ao criar seu
          cadastro na Cloudfox.
        </p>
        <TextInput
          id="email"
          name="email"
          mask=""
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
          error={errorMsg}
        />
        <Button
          primary
          type="button"
          className="btn-signin"
          textTransform
          onClick={sendEmail}
        >
          Enviar
        </Button>
        <Row>
          <Link to="/" className="signup-link">
            <p>Fazer login</p>
          </Link>
        </Row>
      </Container>
    </ContainerPage>
  );
};

export default ForgotPassword;
