import React, { useContext, useCallback, useState, useEffect } from 'react';
import { Container } from './styles';
import ContainerPage from '../../../components/ContainerPage';
import Button from '../../../components/Button';
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import { toast } from 'react-toastify';

import globalIconWhite from '../../../assets/global-icon-white.svg';
import globalIconOrange from '../../../assets/global-icon-orange.svg';
import physicProductWhite from '../../../assets/bag-shop-icon-white.svg';
import physicProductOrange from '../../../assets/bag-shop-icon-orange.svg';
import computerIconWhite from '../../../assets/computer-icon-white.svg';
import computerIconOrange from '../../../assets/computer-icon-orange.svg';
import camIconWhite from '../../../assets/cam-icon-white.svg';
import camIconOrange from '../../../assets/cam-icon-orange.svg';
import subscriptionsIconWhite from '../../../assets/subscriptions-icon-white.svg';
import subscriptionsIconOrange from '../../../assets/subscriptions-icon-orange.svg';
import othersIconWhite from '../../../assets/others-icon-white.svg';
import othersIconOrange from '../../../assets/others-icon-orange.svg';
import SliderInput from '../../../components/SliderInput';
import CategoryButton from '../../../components/CategoryButton';
import GoBackButton from '../../../components/GoBackButton';
import CheckboxInput from '../../../components/CheckboxInput';
import { HideContainer } from '../AddressView/styles';
import arrowRight  from '../../../assets/arrow-right.svg'
  
const AverageBillingView: React.FC = () => {
  const {
    fullname,
    nextStep,
    averageBilling,
    setAverageBilling,
    categories,
    setCategories,
    hasMonthlyIncoming,
    setHasMonthlyIncoming,
    previousStep,
  } = useContext(Context);

  const [ isSelected, setIsSelected ] = useState<boolean>(false);
  const [ dropshippingImportCheck, setDropshippingImportCheck ] = useState<boolean>(false);
  const [ physicalProductCheck, setPhysicalProductCheck ] = useState<boolean>(false);
  const [ digitalProductCheck, setDigitalProductCheck ] = useState<boolean>(false);
  const [ classesCheck, setClassesCheck ] = useState<boolean>(false);
  const [ subscriptionsCheck, setSubscriptionsCheck ] = useState<boolean>(false);
  const [ othersCheck, setOthersCheck ] = useState<boolean>(false);
  const [ formatAverage, setFormatAverage ] = useState<string>('');

  const [ disableProgress, setDisableProgress ] = useState<boolean>(true);

  useEffect(() => {
    averageBilling === 0 && setAverageBilling(500000)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    var categoriesJSON = JSON.parse(categories);    
    setDropshippingImportCheck(categoriesJSON.dropshippingImport === 1? true : false);
    setPhysicalProductCheck(categoriesJSON.physicalProduct === 1? true : false);
    setDigitalProductCheck(categoriesJSON.digitalProduct === 1? true : false);
    setClassesCheck(categoriesJSON.classes === 1? true : false);
    setSubscriptionsCheck(categoriesJSON.subscriptions === 1? true : false);
    setOthersCheck(categoriesJSON.others === 1? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const averageMin = averageBilling/1000
    if(averageMin <= 5) setFormatAverage("Até R$ 5 mil");
    if(averageMin > 5 && averageMin <= 999) setFormatAverage("R$ " + averageMin + " mil");
    if(averageMin >= 999) setFormatAverage("Mais de R$ 1 milhão");
  }, [averageBilling])

  useEffect(() => {
    var selectedCategories = {
      dropshippingImport:  dropshippingImportCheck? 1 : 0,
      physicalProduct:  physicalProductCheck? 1 : 0,
      digitalProduct:  digitalProductCheck? 1 : 0,
      classes:  classesCheck? 1 : 0,
      subscriptions:  subscriptionsCheck? 1 : 0,
      others:  othersCheck? 1 : 0,
    }
    if( dropshippingImportCheck || physicalProductCheck || classesCheck || digitalProductCheck || subscriptionsCheck || othersCheck ){
      setIsSelected(true);
    }else{
      setIsSelected(false);
    }    
    setCategories(JSON.stringify(selectedCategories))
  }, [dropshippingImportCheck, physicalProductCheck, digitalProductCheck, classesCheck, subscriptionsCheck, othersCheck, setCategories]);


  const validateInputs = useCallback(() => {
    if(isSelected){
      nextStep();
    }else{
      toast.error('Por favor, selecione ao menos uma categoria.');
      return;
    }  
  }, [isSelected, nextStep]);

  useEffect(() => {
    if(isSelected){
      setDisableProgress(false);
    }else{
      setDisableProgress(true);
    }
  }, [isSelected, setDisableProgress])

  return (
    <ContainerPage randomBkg showLogo>
      <Container>
        <h1 className="title"> Oi, <b>{fullname.split(' ')[0]}!</b><br/> Queremos te conhecer melhor: </h1>
        <br/>
        <p className="question">Qual seu faturamento mensal médio?</p>
        <p className="observation">Modifique o valor arrastando a bolinha para os lados</p>

        <br/>

        <HideContainer show={!hasMonthlyIncoming}>
          <div className="slider">
            <p className="slider-value">{formatAverage}</p>
            <SliderInput
              step={5000}
              min={5000}
              max={1000000}
              slideValue={averageBilling}
              setSlideValue={setAverageBilling}
            />
          </div>
        </HideContainer>

        <CheckboxInput
          id="same-address"
          name="same-address"
          checked={hasMonthlyIncoming}
          onChange={e => {
              setHasMonthlyIncoming(e.target.checked);
              !e.target.checked ? setAverageBilling(500000) : setAverageBilling(-1);
          }}
          label='Não tenho faturamento atualmente'
        /> 
        
        <br/>

        <p className="question">Em qual nicho você se encaixa hoje?</p>
        <p className="observation">Você pode selecionar mais de um, viu?</p>

        <div className="category-container">
          <CategoryButton 
            checked={dropshippingImportCheck} 
            setCheck={setDropshippingImportCheck} 
            defaultImg={globalIconOrange}
            selectedImg={globalIconWhite}
          >
            Dropshipping e importação
          </CategoryButton>

          <CategoryButton 
            checked={physicalProductCheck} 
            setCheck={setPhysicalProductCheck} 
            defaultImg={physicProductOrange}
            selectedImg={physicProductWhite}
          >
            Produto Físico
          </CategoryButton>

          <CategoryButton 
            checked={digitalProductCheck} 
            setCheck={setDigitalProductCheck} 
            defaultImg={computerIconOrange}
            selectedImg={computerIconWhite}
          >
            Produto Digital
          </CategoryButton>


          <CategoryButton 
            checked={classesCheck} 
            setCheck={setClassesCheck} 
            defaultImg={camIconOrange}
            selectedImg={camIconWhite}
          >
            Cursos
          </CategoryButton>

          <CategoryButton 
            checked={subscriptionsCheck} 
            setCheck={setSubscriptionsCheck} 
            defaultImg={subscriptionsIconOrange}
            selectedImg={subscriptionsIconWhite}
          >
            Assinaturas
          </CategoryButton>

          <CategoryButton 
            checked={othersCheck} 
            setCheck={setOthersCheck} 
            defaultImg={othersIconOrange}
            selectedImg={othersIconWhite}
          >
            Outros
          </CategoryButton>
        </div>

      
        <Button
          primary
          type="button"
          className="btn-continue"
          textTransform
          onClick={validateInputs}
          disabled={disableProgress}
        >
          Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
        </Button>

        <GoBackButton className='goback-button' onClick={() => previousStep(4)} />
        
      </Container>
    </ContainerPage>
  );
};

export default AverageBillingView;
