import React, { ReactNode, useCallback, useEffect } from 'react';
import {
  Container,
  Content,
  PageContainer,
  ComputerBackground,
  UsernameBackground,
  PhoneBackground,
  SafeboxBackground,
  CpfBackground,
  ZipcodeBackground,
  ResidenceDocBackground,
  IdDocBackground,
  SelfieBackground,
  BankAccountTypeBackground,
  WalletBackground,
  ReportBackground,
  AgreementBackground,
  OfficeBackground,
  VisualDataBackground,
  EmailBackground,
  TutorialBackground,
  RandomBackground1,
  RandomBackground2,
  RandomBackground3,
  RandomBackground4,
  RandomBackground5,
  RandomBackground6,
  RandomBackground7,
  RandomBackground8,
  RandomBackground9,
  RandomBackground10,
} from './styles';
import Topbar from '../Topbar';
import Loader from '../Loader';
import cloudfoxLogo from '../../assets/logos/2021/svg/logo-secundary.svg';
import ScrollContainer from '../ScrollContainer';

interface ContainerProps {
  children: ReactNode;
  showLogo?: boolean;
  topbar?: boolean;
  loading?: number;
  loadingText?: string;
  usernameBkg?: boolean;
  computerBkg?: boolean;
  phoneBkg?: boolean;
  safeboxBkg?: boolean;
  cpfBkg?: boolean;
  zipcodeBkg?: boolean;
  residenceBkg?: boolean;
  docBkg?: boolean;
  selfieBkg?: boolean;
  accountTypeBkg?: boolean;
  bankInfoBkg?: boolean;
  bankStatementBkg?: boolean;
  bankTermsBkg?: boolean;
  cnpjBkg?: boolean;
  cnpjDataBkg?: boolean;
  cnpjAdditionalBkg?: boolean;
  randomBkg?: boolean;
  emailBkg?: boolean;
  tutorialBkg?: boolean;
}

const ContainerPage: React.FC<ContainerProps> = ({
  children,
  showLogo,
  topbar,
  loading,
  loadingText,
  usernameBkg,
  computerBkg,
  phoneBkg,
  safeboxBkg,
  cpfBkg,
  zipcodeBkg,
  residenceBkg,
  docBkg,
  selfieBkg,
  accountTypeBkg,
  bankInfoBkg,
  bankStatementBkg,
  bankTermsBkg,
  cnpjBkg,
  cnpjDataBkg,
  randomBkg,
  emailBkg,
  tutorialBkg,
}) => {
  const a = <RandomBackground1 className="page-bkg" />;
  const b = <RandomBackground1 className="page-bkg" />;
  const c = <RandomBackground2 className="page-bkg" />;
  const d = <RandomBackground3 className="page-bkg" />;
  const e = <RandomBackground4 className="page-bkg" />;
  const f = <RandomBackground5 className="page-bkg" />;
  const g = <RandomBackground6 className="page-bkg" />;
  const h = <RandomBackground7 className="page-bkg" />;
  const i = <RandomBackground8 className="page-bkg" />;
  const j = <RandomBackground9 className="page-bkg" />;
  const k = <RandomBackground10 className="page-bkg" />;
  const imageArray = [a, b, c, d, e, f, g, h, i, j, k];
  const size = imageArray.length;
  const image = Math.floor(size * Math.random());
  const randomBkgFunc = useCallback(() => {
    switch (image) {
      case 0:
        return a;
      case 1:
        return b;
      case 2:
        return c;
      case 3:
        return d;
      case 4:
        return e;
      case 5:
        return f;
      case 6:
        return g;
      case 7:
        return h;
      case 8:
        return i;
      case 9:
        return j;
      case 10:
        return k;
      default:
        break;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    randomBkgFunc();
  });

  return (
    <Container topbar={topbar}>
      <Topbar />
      <PageContainer topbar={topbar}>
          {computerBkg && <ComputerBackground className="page-bkg" />}
          {usernameBkg && <UsernameBackground className="page-bkg" />}
          {phoneBkg && <PhoneBackground className="page-bkg" />}
          {safeboxBkg && <SafeboxBackground className="page-bkg" />}
          {cpfBkg && <CpfBackground className="page-bkg" />}
          {zipcodeBkg && <ZipcodeBackground className="page-bkg" />}
          {residenceBkg && <ResidenceDocBackground className="page-bkg" />}
          {docBkg && <IdDocBackground className="page-bkg" />}
          {selfieBkg && <SelfieBackground className="page-bkg" />}
          {accountTypeBkg && <BankAccountTypeBackground className="page-bkg" />}
          {bankInfoBkg && <WalletBackground className="page-bkg" />}
          {bankStatementBkg && <ReportBackground className="page-bkg" />}
          {bankTermsBkg && <AgreementBackground className="page-bkg" />}
          {cnpjBkg && <OfficeBackground className="page-bkg" />}
          {cnpjDataBkg && <VisualDataBackground className="page-bkg" />}
          {randomBkg && randomBkgFunc()}
          {emailBkg && <EmailBackground className="page-bkg" />}
          {tutorialBkg && <TutorialBackground className="page-bkg" />}    

          <Content showLogo={showLogo} topbar={topbar}>
            <ScrollContainer className="scroll-container" height="100%" width="700px" fadeEfect={false}>
              <Loader loading={loading} loadingText={loadingText} />
              <img className="content-logo" src={cloudfoxLogo} alt="" />
              {children}
            </ScrollContainer>      
          </Content>
        
      </PageContainer>
    </Container>
  );
};

export default ContainerPage;
