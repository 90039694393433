import React, { useEffect, useState } from 'react'
import { Container } from './styles'

interface TagProps {
  children: string;
  checked: boolean;
  setChecked: (value: boolean) => void;
}

const Tag: React.FC<TagProps> = ({
  children,
  checked,
  setChecked
}) => {
  const [ buttonClass, setButtonClass] = useState<string>('default')

  const handleCheck = () => {
    setChecked(!checked);
  }

  useEffect(() => {
    setButtonClass(!checked? 'default' : 'selected');
  }, [checked])

  return(
      <Container>
        <button 
          className={buttonClass}
          onClick={handleCheck}>
          {children}
        </button>
      </Container>    
  )
}

export default Tag;