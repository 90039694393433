export const permissionsList = [
    {    
        value:'dashboard_main',
        label:'Dashboard',
        permissions:{
            toView:
            {
                value:'dashboard',                
                checked:false,
                show:true,
                partial:false
            },
            manage:{
                value:null,
                checked:false,
                show:false,
                partial:false
            }
        },        
        items:[],
        at_least_one:false
    },
    {
        value:'sales_main',
        label:'Vendas',      
        permissions:{
            toView:
            {
                value:'sale_main_view_all',
                checked:false,
                show:true,
                partial:false
            },
            manage:{
                value:'sale_main_manage_all',
                checked:false,
                show:true,
                partial:false
            }
        },
        items:[
            {
                label:'Visão Geral',
                permissions:{
                    toView:
                    {
                        value:'sales',                
                        checked:false,
                        show:true
                    },
                    manage:{
                        value:'sales_manage',
                        checked:false,
                        show:true
                    }
                }
            },
            {
                label:'Recuperação',
                permissions:{
                    toView:
                    {
                        value:'recovery',                
                        checked:false,
                        show:true
                    },
                    manage:{
                        value:'',
                        checked:false,
                        show:false
                    }
                }
            },
            {
                label:'Rastreamento',
                permissions:{
                    toView:
                    {
                        value:'trackings',                
                        checked:false,
                        show:true
                    },
                    manage:{
                        value:'trackings_manage',
                        checked:false,
                        show:true
                    }
                }
            },
            {
                label:'Contestações',
                permissions:{
                    toView:
                    {
                        value:'contestations',                
                        checked:false,
                        show:true
                    },
                    manage:{
                        value:'contestations_manage',
                        checked:false,
                        show:true
                    }
                }
            }
        ],
        at_least_one:false        
    },    
    {
        value:'projects_main',
        label:'Projetos',
        permissions:{
            toView:
            {
                value:'projects',                
                checked:false,
                show:true,
                partial:false
            },
            manage:{
                value:'projects_manage',
                checked:false,
                show:true,
                partial:false
            }
        },          
        items:[],
        at_least_one:false
    },    
    {
        value:'produts_main',
        label:'Produtos',
        permissions:{
            toView:
            {
                value:'products',                
                checked:false,
                show:true,
                partial:false
            },
            manage:{
                value:'products_manage',
                checked:false,
                show:true,
                partial:false
            }
        },          
        items:[],
        at_least_one:false
    },    
    {
        value:'attendance_main',
        label:'Atendimento',
        permissions:{
            toView:
            {
                value:'attendance',                
                checked:false,
                show:true,
                partial:false
            },
            manage:{
                value:'attendance_manage',
                checked:false,
                show:true,
                partial:false
            }
        },          
        items:[],
        at_least_one:false
    },    
    {
        value:'finances_main',
        label:'Finanças',
        permissions:{
            toView:
            {
                value:'finances',                
                checked:false,
                show:true,
                partial:false
            },
            manage:{
                value:'finances_manage',
                checked:false,
                show:true,
                partial:false
            }
        },          
        items:[],
        at_least_one:false
    },    
    {
        value:'reports_main',
        label:'Relatórios',
        permissions:{
            toView:
            {
                value:'reports_main_view_all',
                checked:false,
                show:true,
                partial:false
            },
            manage:{
                value:'reports_main_manage_all',
                checked:false,
                show:false,
                partial:false
            }
        },  
        
        items:[
            {
                label:'Relatório de Vendas',
                permissions:{
                    toView:
                    {
                        value:'report_sales',                
                        checked:false,
                        show:true
                    },
                    manage:{
                        value:null,
                        checked:false,
                        show:false
                    }
                }
            },
            {
                label:'Relatório de Acessos',
                permissions:{
                    toView:
                    {
                        value:'report_checkouts',                
                        checked:false,
                        show:true
                    },
                    manage:{
                        value:null,
                        checked:false,
                        show:false
                    }
                }
            },
            {
                label:'Relatório de Descontos',
                permissions:{
                    toView:
                    {
                        value:'report_coupons',
                        checked:false,
                        show:true
                    },
                    manage:{
                        value:null,
                        checked:false,
                        show:false
                    }
                }
            },
            {
                label:'Relatório de Saldo Pendente',
                permissions:{
                    toView:
                    {
                        value:'report_pending',
                        checked:false,
                        show:true
                    },
                    manage:{
                        value:null,
                        checked:false,
                        show:false
                    }
                }
            },
            {
                label:'Relatório de Saldo Bloqueado',
                permissions:{
                    toView:
                    {
                        value:'report_blockedbalance',
                        checked:false,
                        show:true
                    },
                    manage:{
                        value:null,
                        checked:false,
                        show:false
                    }
                }
            }
        ],
        at_least_one:false
    },    
    {
        value:'affiliates_main',
        label:'Afiliados',
        permissions:{
            toView:
            {
                value:'affiliates',
                checked:false,
                show:true,
                partial:false
            },
            manage:{
                value:'affiliates_manage',
                checked:false,
                show:true,
                partial:false
            }
        },          
        items:[],
        at_least_one:false
    },    
    {
        value:'apps_main',
        label:'Aplicativos',
        permissions:{
            toView:
            {
                value:'apps',
                checked:false,
                show:true,
                partial:false
            },
            manage:{
                value:'apps_manage',
                checked:false,
                show:true,
                partial:false
            }
        },          
        items:[],
        at_least_one:false
    },    
    {
        value:'invitations_main',
        label:'Convites',
        permissions:{
            toView:
            {
                value:'invitations',
                checked:false,
                show:true,
                partial:false
            },
            manage:{
                value:'invitations_manage',
                checked:false,
                show:true,
                partial:false
            }
        },          
        items:[],
        at_least_one:false
    }
    
];