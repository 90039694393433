/* eslint-disable eqeqeq */
import React, { useCallback, useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Container, SwitchContainer } from './styles';
import Card from '../../../../../components/Card';
import SwitchInput from '../../../../../components/SwitchInput';
import api from '../../../../../services/api';
import { AccountsContext as Context } from '../../../../../context/AccountsContext';

const SiriusNotifications: React.FC = () => {
  const { user, setUser, siriusLoadingSync } = useContext(Context);

  const [loading, setLoading] = useState(0);

  const handleChange = useCallback(
    async (e) => {
      const { name, checked } = e.currentTarget;

      let number;
      checked == true ? (number = '1') : (number = '0');

      const formData = new FormData();
      formData.append('column', name);
      formData.append('value', number);

      try {
        setLoading(1);
        const res = await api.post('profile/updatenotification', formData);

        setUser(res.data.data);
        setLoading(0);
        toast.success('Notificações foram salvas com sucesso');
      } catch (error) {
        setLoading(0);
        toast.error('Ops! Algo saiu errado, tente novamente');
      }
    },
    [setUser],
  );

  useEffect(() => {
    siriusLoadingSync ? setLoading(1) : setLoading(0);
  }, [siriusLoadingSync]);

  return (
    <Container>
      <Card cardTitle="Notificações" className="notification" loading={loading}>
        {siriusLoadingSync === 0 && (
          <>
            <p>Defina quais notificações deseja receber</p>
            {user && (
              <div className="switch-row row">
                <SwitchContainer className="col-sm-4 col-xs-6">
                  <p className="switch-label">Boleto gerado</p>
                  <SwitchInput
                    className="switch-input"
                    id="billet_generated"
                    name="billet_generated"
                    onChange={handleChange}
                    defaultValue={
                      user?.notifications.billet_generated
                        ? Boolean(user?.notifications.billet_generated)
                        : false
                    }
                  />
                </SwitchContainer>
                <SwitchContainer className="col-sm-4 col-xs-6">
                  <p className="switch-label">Boleto compensado</p>
                  <SwitchInput
                    className="switch-input"
                    id="boleto-compensado"
                    name="boleto_compensated"
                    onChange={handleChange}
                    defaultValue={
                      user?.notifications.boleto_compensated
                        ? Boolean(user?.notifications.boleto_compensated)
                        : false
                    }
                  />
                </SwitchContainer>
                <SwitchContainer className="col-sm-4 col-xs-6">
                  <p className="switch-label">Venda aprovada</p>
                  <SwitchInput
                    className="switch-input"
                    id="venda-aprovada"
                    name="sale_approved"
                    onChange={handleChange}
                    defaultValue={
                      user?.notifications.sale_approved
                        ? Boolean(user?.notifications.sale_approved)
                        : false
                    }
                  />
                </SwitchContainer>
                <SwitchContainer className="col-sm-4 col-xs-6">
                  <p className="switch-label">Domínio aprovado</p>
                  <SwitchInput
                    className="switch-input"
                    id="dominio-aprovado"
                    name="domain_approved"
                    onChange={handleChange}
                    defaultValue={
                      user?.notifications.domain_approved
                        ? Boolean(user?.notifications.domain_approved)
                        : false
                    }
                  />
                </SwitchContainer>
                <SwitchContainer className="col-sm-4 col-xs-6">
                  <p className="switch-label">Shopify</p>
                  <SwitchInput
                    className="switch-input"
                    id="shopify"
                    name="shopify"
                    onChange={handleChange}
                    defaultValue={
                      user?.notifications.shopify
                        ? Boolean(user?.notifications.shopify)
                        : false
                    }
                  />
                </SwitchContainer>
                <SwitchContainer className="col-sm-4 col-xs-6">
                  <p className="switch-label">Afiliação</p>
                  <SwitchInput
                    className="switch-input"
                    id="afiliacao"
                    name="affiliation"
                    onChange={handleChange}
                    defaultValue={
                      user?.notifications.affiliation
                        ? Boolean(user?.notifications.affiliation)
                        : false
                    }
                  />
                </SwitchContainer>
              </div>
            )}
          </>
        )}
      </Card>
    </Container>
  );
};

export default SiriusNotifications;
