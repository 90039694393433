import React, { useContext, useState, useCallback, useEffect } from 'react';
import { PreRegisterContext as Context } from '../../../../context/PreRegisterContext';
import { Container, Row } from './styles';
import Button from '../../../../components/Button';
import GoBackButton from '../../../../components/GoBackButton';
import UploadBox from '../../../../components/UploadBox';
import Title from '../../../../components/Title';
import ContainerPage from '../../../../components/ContainerPage';
import Modal from '../../../../components/Modal';
import ErrorMessage from '../../../../components/ErrorMessage';

import api from '../../../../services/api';
import CheckboxInput from '../../../../components/CheckboxInput';
import { HideContainer } from '../../AddressView/styles';
import arrowRight  from '../../../../assets/arrow-right.svg'

const CompanyDocument: React.FC = () => {
  const {
    setCompanyContractDoc,
    setCompanyAddressReceipt,
    cpf,
    nextStep,
    previousStep,
    companyContractName,
    setCompanyContractName,
    companyContractType,
    setCompanyContractType,
    companyAddressProveName,
    setCompanyAddressProveName,
    companyAddressProveType,
    setCompanyAddressProveType,
    isSameAddress,
    setIsSameAddress,
  } = useContext(Context) || {};

  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const removeContractFile = useCallback(() => {
    localStorage.removeItem('companyContractName');
    localStorage.removeItem('companyContractType');
    setCompanyContractName();
    setCompanyContractType();
    setCompanyContractDoc([]);
  }, [setCompanyContractDoc, setCompanyContractName, setCompanyContractType]);

  const removeAddressProveFile = useCallback(() => {
    localStorage.removeItem('companyAddressProveName');
    localStorage.removeItem('companyAddressProveType');
    setCompanyAddressProveName();
    setCompanyAddressProveType();
    setCompanyAddressReceipt([]);
  }, [
    setCompanyAddressProveName,
    setCompanyAddressProveType,
    setCompanyAddressReceipt,
  ]);

  const getCompanyContractDoc = useCallback(
    async (file) => {
      if (
        (file.type === 'image/png' && file.size < 10000000) ||
        (file.type === 'image/jpeg' && file.size < 10000000) ||
        (file.type === 'application/pdf' && file.size < 10000000)
      ) {
        setLoading(0);
      } else {
        setLoading(0);
        setErrorMsg(
          'Por favor, confira se seu arquivo é do tipo .jpeg, .jpg, .png, .pdf e se ele possui menos de 10MB de tamanho.',
        );
        return;
      }

      setLoading(1);
      const formData = new FormData();
      formData.append('file_to_upload', file);
      formData.append('document_type', 'EMPRESA_CCMEI');
      formData.append('document', cpf);

      try {
        const res = await api.post('register/upload-documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setCompanyContractName(res.data.fileName);
        setCompanyContractType(res.data.fileType);
        setCompanyContractDoc([res.data.path]);
        setErrorMsg('')
        setLoading(0);
      } catch (error) {
        setLoading(0);
        setErrorMsg(error.response.data.errors.file_to_upload);
      }
    },
    [
      cpf,
      setCompanyContractDoc,
      setCompanyContractName,
      setCompanyContractType,
    ],
  );

  const getCompanyAddressReceipt = useCallback(
    async (file) => {
      setLoading(1);
      const formData = new FormData();
      formData.append('file_to_upload', file);
      formData.append('document_type', 'EMPRESA_RESIDENCIA');
      formData.append('document', cpf);

      try{
        const res = await api.post('register/upload-documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setCompanyAddressProveName(res.data.fileName);
        setCompanyAddressProveType(res.data.fileType);
        setCompanyAddressReceipt([res.data.path]);
        setErrorMsg('')
        setLoading(0);
      }catch(error) {
        setLoading(0);
        setErrorMsg(error.response.data.errors.file_to_upload);
      }
      
    },
    [
      cpf,
      setCompanyAddressProveName,
      setCompanyAddressProveType,
      setCompanyAddressReceipt,
    ],
  );

  const validateCompanyDocuments = useCallback(() => {
    if ((companyAddressProveName  || isSameAddress) && companyContractName) {
      nextStep();
    } else {
      setErrorMsg('Precisamos dos documentos acima para completar o cadastro.');
    }
  }, [companyAddressProveName, companyContractName, isSameAddress, nextStep]);

  const toggleModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const toggleProveModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen]);

  const companyContractDocument = (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      closeModal={toggleModal}
      headerLabel="Contrato social"
    >
      <p>
        Se for MEI, o documento é o certificado de condição de MEI. Se for ME é
        o requerimento de empresário ou documento assinado pela junta comercial
        do estado.
      </p>
    </Modal>
  );

  const companyAddressProve = (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={toggleProveModal}
      closeModal={toggleProveModal}
      headerLabel="Comprovante de endereço empresarial"
    >
      <p>
        O Comprovante de endereço precisa estar no nome do usuário ou da empresa
        e precisa estar dentro do prazo de 90 dias de vencimento. São aceitos
        como comprovante empresarial as contas de: água, luz, internet, telefone
        fixo, telefone móvel, gás encanado.
        Pode ser via digital ou foto.
      </p>
    </Modal>
  );
  
  const [ disableProgress, setDisableProgress ] = useState<boolean>(true);

  useEffect(() => {
    if((companyAddressProveName  || isSameAddress) && companyContractName){
      setDisableProgress(false)
    }else{
      setDisableProgress(true)
    }
    
  }, [ setDisableProgress, companyAddressProveName, isSameAddress, companyContractName])


  useEffect(()=>{
    return window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [])

  return (
    <ContainerPage showLogo docBkg loading={loading}>
      {companyContractDocument}
      {companyAddressProve}
      <Container>
        <Title primary>Estamos quase lá!</Title>
        <p className="subtitle">
          Envie seu Certificado de cadastro de Micro Empresário Individual
          (CCMEI) ou o contrato social
        </p>
        <p style={{ fontSize: '11px' }}>
          Se for MEI, o documento é o certificado de condição de MEI. Se for ME
          é o requerimento de empresário ou documento assinado pela junta
          comercial do estado.
        </p>
        
        <UploadBox
          wholeFile={getCompanyContractDoc}
          removeFile={removeContractFile}
          fileName={companyContractName}
          fileType={companyContractType}
          id="companyContract"
        >
          <p>
            Arraste e solte o arquivo aqui ou clique para{' '}
            <span>fazer upload</span>
          </p>
        </UploadBox>
        
        <p className="address-prove-text subtitle">
          Comprovante de endereço da empresa
        </p>
        <p style={{ fontSize: '11px' }}>
          O Comprovante de endereço precisa estar no nome do usuário ou da
          empresa e precisa estar dentro do prazo de 90 dias de vencimento. São
          aceitos como comprovante empresarial as contas de: água, luz,
          internet, telefone fixo, telefone móvel, gás encanado. Pode ser via digital ou foto.
        </p>
        
        <HideContainer show={!isSameAddress}>
          <UploadBox
            wholeFile={getCompanyAddressReceipt}
            removeFile={removeAddressProveFile}
            fileName={companyAddressProveName}
            fileType={companyAddressProveType}
            id="companyAddressProve"
          >
            <p>
              Arraste e solte o arquivo aqui ou clique para{' '}
              <span>fazer upload</span>
            </p>
          </UploadBox>
        </HideContainer>

        <CheckboxInput
          id="same-address"
          name="same-address"
          checked={isSameAddress}
          onChange={e => {
            setIsSameAddress(e.target.checked)
          }}
          label='Utilizar o mesmo do endereço pessoal'
        />

        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}

        <Button
          primary
          type="button"
          className="btn-continue"
          textTransform
          onClick={validateCompanyDocuments}
          disabled={disableProgress}
        >
          Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
        </Button>
        <Row spaceAround>
          <GoBackButton className='goback-button' onClick={previousStep} />
          {/* <JumpStepLink>Pular esta etapa</JumpStepLink> */}
        </Row>
      </Container>
    </ContainerPage>
  );
};

export default CompanyDocument;
