import styled from 'styled-components';
import * as color from '../../styles/colors';

export const Container = styled.div`
  .verification-code-input {
    width: 100% !important;
    margin: 20px 0;

    > div {
      display: flex;
      justify-content: center;

      input {
        border: none;
        border-bottom: 3px solid #9ea8c5;
        border-bottom-right-radius: none;
        font-size: 40px;
        font-family: Roboto;
        margin: 0 5px;
        color: ${color.darkgray};

        &:first-child {
          border-bottom-left-radius: 0;
        }

        &:last-child {
          border-bottom-right-radius: 0;
        }
      }
    }
  }
`;

export const Text = styled.p`
  margin-bottom: 30px;
`;
