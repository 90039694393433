import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  .btn-reset-password {
    margin: 60px 0 33px;
  }

  .confirm-password {
    margin-top: 60px;
  }

  @media (min-width: 576px) {
    width: 60%;
    padding: 10px;
  }
`;
