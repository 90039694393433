import styled, { css } from 'styled-components';
import * as color from '../../styles/colors';
import trashIcon from '../../assets/trash.svg';

interface UploadBoxProps {
  isDragAccept?: boolean;
  isDragReject?: boolean;
  isDragActive?: boolean;
  disabled?: boolean;
}

export const Container = styled.div<UploadBoxProps>`
  opacity: ${({ disabled }) => (disabled ? '0.5' : 'none')};
`;

export const UploadBoxContainer = styled.div<UploadBoxProps>`
  height: 90px;
  font-size: 12px;
  font-family: 'Roboto';
  border: 2px dashed #acacac;
  border-radius: 4px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  padding: 10px 25px;
  color: #656565;

  ${({ isDragAccept }) =>
    isDragAccept &&
    css`
      border-color: #00e676;
    `}

  ${({ isDragReject }) =>
    isDragReject &&
    css`
      border-color: #ff1744;
    `}

  ${({ isDragActive }) =>
    isDragActive &&
    css`
      border-color: #2196f3;
    `}

  span {
    font-weight: bold;
    color: ${color.orange};
    cursor: pointer;
  }

  .upload-blocker {
  }

  @media (min-width: 376px) {
    padding: 10px 75px;
  }
`;

export const FileUploadedBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 20px 24px;
  margin: 18px 0;

  p {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 768px) {
    p {
      width: 130px;
    }
  }
  
`;

export const TrashButton = styled.button`
  height: 24px;
  width: 24px;
  background: url(${trashIcon}) ${color.orange} no-repeat center/16px 16px;
  border: ${color.orange};
  border-radius: 25px;
  align-self: center;
`;

export const FileName = styled.div`
  display: flex;
  font-size: 14px;
  font-family: 'Roboto';
  line-height: 14px;
  align-items: center;
  max-width: 380px;

  img {
    margin-right: 20px;
  }
`;

export const FileListBox = styled.div`
  border: 1px solid #ccd0dc;
  border-radius: 5px;
  padding: 6px 10px;
  margin-top: 10px;

  > p {
    font-weight: 600;
  }
`;
