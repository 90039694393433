import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Container } from './styles';
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import Button from '../../../components/Button';
import GoBackButton from '../../../components/GoBackButton';
import PhoneInput from '../SignupComponents/PhoneInput';
import ContainerPage from '../../../components/ContainerPage';
import api from '../../../services/api';
import arrowRight  from '../../../assets/arrow-right.svg'

const MobilePhoneView: React.FC = () => {
  const { phone, setPhone, nextStep, previousStep, fullname } =
    useContext(Context) || {};
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(0);
  const [ disableProgress, setDisableProgress] = useState(true);

  const invalidPhoneNumbers = [ '5511111111111', '5522222222222', '5533333333333', '5544444444444', '5555555555555', '5566666666666', '5577777777777', '5588888888888', '5599999999999', '5500000000000' ];

  const validatePhone = useCallback(() => {
    if (!phone) {
      setErrorMsg('Por favor, preencha o campo para o número de celular.');
      return;
    }
    if (phone.length <= 11) {
      setErrorMsg('Por favor, verifique se seu número de celular está correto.');
      return;
    }
    if (phone.length <= 11) {
      setErrorMsg('Por favor, verifique se seu número de celular está correto.');
      return;
    }
    if (invalidPhoneNumbers.includes(phone)){
      setErrorMsg('Por favor, digite um número de celular válido.');
      return;
    }
    setLoading(1);

    api
      .get('/register/send-cellphone-code', {
        params: {
          cellphone: phone,
        },
      })
      .then((res) => {
        setLoading(0);
        nextStep();
        return true;
      })
      .catch((error) => {
        setLoading(0);

        if (error.response.status === 409) {
          nextStep(6);
        }

        setErrorMsg(error.response.data.message);
      });
  }, [nextStep, phone, invalidPhoneNumbers]);

  useEffect(() => {
    if(phone.length >=13){
      setDisableProgress(false)
    }else{
      setDisableProgress(true)
    }
  },  [phone])

  return (
    <ContainerPage showLogo phoneBkg loading={loading}>
      <Container>
        <p>
          {fullname}, precisamos de um número de celular atrelado a sua conta
        </p>
        <PhoneInput
          value={phone}
          onChange={(e) => setPhone(e)}
          error={errorMsg}
        />
        <Button
          primary
          type="button"
          className="btn-continue"
          textTransform
          onClick={validatePhone}
          disabled={disableProgress}
        >
          Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
        </Button>
        <GoBackButton className='goback-button' onClick={() => previousStep(2)} />
      </Container>
    </ContainerPage>
  );
};

export default MobilePhoneView;
