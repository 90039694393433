import React, { useContext, useCallback, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import CodeInput from 'react-verification-code-input';
import { Container, ExpiringCodeText, SendCodeText, Row } from './styles';
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import ContainerPage from '../../../components/ContainerPage';
import GoBackButton from '../../../components/GoBackButton';
import ErrorMessage from '../../../components/ErrorMessage';
import api from '../../../services/api';

const PhoneTokenView: React.FC = () => {
  const { previousStep, nextStep, phone } = useContext(Context) || {};
  const [phoneToken, setPhoneToken] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | null>('');
  const [loading, setLoading] = useState<number | undefined>(0);
  const inputRef = useRef<any>(null);

  const sendPhoneCode = useCallback(async () => {
    setLoading(1);
    try {
      await api.get('/register/send-cellphone-code', {
        params: {
          cellphone: phone,
        },
      });
      setLoading(0);

      toast.success('Seu código foi enviado com sucesso!');
    } catch (error) {
      setLoading(0);
      toast.error('Algo não saiu como esperado. Tente novamente.');
      console.error(error);
    }
  }, [phone]);

  const tokenComplete = useCallback(
    (e) => {
      setLoading(1);
      api
        .post('/register/match-cellphone-verify-code', {
          cellphone: phone,
          token: e,
        })
        .then(() => {
          setPhoneToken([e]);
          setLoading(0);
          nextStep();
        })
        .catch((error) => {
          inputRef?.current?.__clearvalues__();
          setLoading(0);
          if (error.response) {
            setErrorMsg(error.response.data.message);
          }
        });
    },
    [nextStep, phone],
  );

  return (
    <ContainerPage showLogo phoneBkg loading={loading}>
      <Container>
        <p>
          Para sua segurança você recebeu no seu celular um token para
          validação. Informe abaixo:
        </p>
        <CodeInput
          fields={4}
          className="verification-code-input"
          fieldWidth={68}
          fieldHeight={91}
          onComplete={tokenComplete}
          onChange={() => setErrorMsg('')}
          values={phoneToken}
          ref={inputRef}
        />
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}

        <ExpiringCodeText>O código expira em 10 minutos.</ExpiringCodeText>
        <Row>
          <SendCodeText onClick={sendPhoneCode}>
            Enviar código novamente.
          </SendCodeText>
        </Row>
        <GoBackButton className='goback-button' onClick={previousStep} />
      </Container>
      <ToastContainer />
    </ContainerPage>
  );
};

export default PhoneTokenView;
