import styled from 'styled-components';
// import * as color from '../../../styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  color: #5B5B5B;
  text-align: center;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;
    

  .logo {
    max-width: 200px;
    margin: 20px 0 10px 0;
  }

  h1 {
    font-size: 22px;
    margin-top: 20px;
  }

  p {
    font-size: 16px;
    margin: 10px 0 10px 0;
  }

  .calendly-inline-widget {
    height: 700px;
    width: 100%;    
  }  

  @media (max-width: 768px) {
    height: auto;

    h1 {
      font-size: 16px;
      margin-top: 10px;
    }

    p {
      font-size: 12px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .calendly-inline-widget {
      height: 1060px;
      width: 100%;    

      overflow: hidden;
    }  
  }

`;
