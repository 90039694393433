import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  .btn-continue {
    margin-top: 30px;
  }

  .fullname,
  .cpf {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .cpf-question {
    padding-top: 10px;
  }

  .recaptcha {
    padding-top: 10px;
    width: 100%;
  }

  .goback {
    margin-top: 10px;
  }

  @media (min-width: 576px) {
    width: 420px;
    padding: 10px;
  }
`;
