import styled from 'styled-components';
import * as color from '../../styles/colors';

interface ErrorMessageProps {
  marginTop?: string;
}

export const Container = styled.div<ErrorMessageProps>`
  /* font-style: italic; */
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: ${color.red};
  margin-top: ${({ marginTop }) => marginTop || '4px'};
`;
