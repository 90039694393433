import React, { useRef } from 'react';
import { OptionTypeBase } from 'react-select';
import { TextLabel, Container, SelectInput } from './styles';
import ErrorMessage from '../../../../components/ErrorMessage';

interface SelectProps extends OptionTypeBase {
  id?: string;
  name?: string;
  placeholder?: string;
  value?: any;
  onChange?: (arg0: any) => void;
  split?: boolean;
  error?: string;
  inputValue?: string;
  defaultValue?: any;
  getOptionValue?: any;
  onInputChange?: (arg0: any) => void;
}

const Select: React.FC<SelectProps> = ({
  id,
  name,
  value,
  split,
  error,
  placeholder,
  onChange,
  options,
  inputValue,
  defaultValue,
  getOptionValue,
  onInputChange,
  ...rest
}) => {
  const SelectRef = useRef<any>(null);

  return (
    <Container split={split} error={error} selectType="secondary">
      <SelectInput
        {...rest}
        name={name}
        classNamePrefix="register"
        options={options}
        ref={SelectRef}
        onChange={onChange}
        placeholder="Selecionar"
        inputValue={inputValue}
        value={value}
        defaultValue={defaultValue}
        getOptionValue={getOptionValue}
        onInputChange={onInputChange}
      />

      <TextLabel
        onClick={() => SelectRef.current?.focus()}
        className="form__label"
        htmlFor={id}
        error={error}
        // value={value}
      >
        {placeholder}
      </TextLabel>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default Select;
