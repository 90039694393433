import React, { useContext, useCallback, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import CodeInput from 'react-verification-code-input';
import { Container, ExpiringCodeText, SendCodeText, Row } from './styles';
import ContainerPage from '../../../components/ContainerPage';
import GoBackButton from '../../../components/GoBackButton';
import ErrorMessage from '../../../components/ErrorMessage';
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import api from '../../../services/api';

const EmailTokenView: React.FC = () => {
  const { previousStep, nextStep, email, fullname } = useContext(Context) || {};
  const [errorMsg, setErrorMsg] = useState<string | null>('');
  const [loading, setLoading] = useState<number | undefined>(0);
  const inputRef = useRef<any>(null);

  const sendEmailCode = useCallback(async () => {
    setLoading(1);
    try {
      await api.get('/register/send-email-code', {
        params: {
          email,
          firstname: fullname,
        },
      });
      setLoading(0);
      toast.success('Seu código foi enviado com sucesso!');
    } catch (error) {
      setLoading(0);
      toast.error('Algo não saiu como esperado. Tente novamente.');
    }
  }, [email, fullname]);

  const tokenComplete = useCallback(
    (e) => {
      setLoading(1);

      api
        .post('/register/match-email-verify-code', {
          email,
          code: e,
        })
        .then((ess) => {
          setLoading(0);
          nextStep();
        })
        .catch((error) => {
          inputRef?.current?.__clearvalues__();
          setLoading(0);
          if (error.response) {
            setErrorMsg(error.response.data.message);
          }
        });
    },
    [email, nextStep],
  );

  return (
    <ContainerPage showLogo computerBkg loading={loading}>
      <Container>
        <p>
          Para sua segurança você recebeu no seu e-mail um token para validação.
          Informe abaixo:
        </p>
        <CodeInput
          fields={4}
          className="verification-code-input"
          fieldWidth={68}
          fieldHeight={91}
          onChange={() => setErrorMsg('')}
          onComplete={tokenComplete}
          ref={inputRef}
          // onChange={onChange}
        />
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}

        <ExpiringCodeText>O código expira em 10 minutos.</ExpiringCodeText>
        <Row>
          <SendCodeText onClick={sendEmailCode}>
            Enviar código novamente.
          </SendCodeText>
        </Row>

        <GoBackButton className='goback-button' onClick={previousStep} />
      </Container>
      <ToastContainer />
    </ContainerPage>
  );
};

export default EmailTokenView;
