import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { Container, TextLabel } from './styles';
import ErrorMessage from '../../../../components/ErrorMessage';
import * as color from '../../../../styles/colors';

interface InputProps {
  error?: string;
  value?: string;
  onChange?: (arg0: any) => void;
}

const PhoneInputComponent: React.FC<InputProps> = ({
  error,
  value,
  onChange,
}) => {
  const inputStyles = {
    width: '100%',
    border: '0',
    borderBottom: `1px solid ${error ? color.red : '#2e2e2e'}`,
    outline: '0',
    fontSize: '1em',
    transition: 'border-color 0.2s',
    color: '#2e2e2e',
    borderRadius: '0',
  };

  const buttonStyles = {
    backgroundColor: 'transparent',
    border: '0',
    height: '95%',
  };

  return (
    <Container>
      <TextLabel className="form__label" error={error}>
        Celular
      </TextLabel>
      <PhoneInput
        country="br"
        masks={{ br: '(..) .....-....' }}
        inputClass="phone-input"
        inputStyle={inputStyles}
        buttonStyle={buttonStyles}
        placeholder="Celular"
        value={value}
        onChange={onChange}
        inputProps={{ name: 'phone' }}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};

export default PhoneInputComponent;
