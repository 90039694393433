import styled from 'styled-components';
import Card from '../../../../components/Card';

export const Container = styled(Card)`
  margin-top: 30px;

  .verification-code-input {
    width: 100% !important;
    margin: 20px 0;

    > div {
      display: flex;
      justify-content: space-between;

      input {
        /* width: 68px !important;
        height: 91px !important; */
        border: 1px solid #434343;
        border-radius: 10px;
        font-size: 40px;
        font-family: Roboto;
      }
    }
  }
`;
