import { useState, useEffect, useCallback } from 'react';
import api from '../services/api';

interface ListProps {
  name: string;
  code: string;
}

const useBankList = (): [] => {
  const [bankList, setBankList] = useState<ListProps[]>([]);

  const getBanks = useCallback(async () => {
    try {
      const resBanks = await api.get('register/get-banks');
      setBankList(resBanks.data.banks);
    } catch (error) {
    }
  }, []);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  return bankList as [];
};

export default useBankList;
