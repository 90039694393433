import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Container } from './styles';
import CardWrapper from '../Card';
import { useHistory } from 'react-router-dom';
// import profileIcon from '../../assets/profile.svg';
// import companiesIcon from '../../assets/companies.svg';
import logoutIcon from '../../assets/logout.svg';
import styled from 'styled-components';
import api from '../../services/api';

import { logout } from '../../services/auth.js';

const Button = styled.button`
  background-color: white;
  color: black;
  cursor: pointer;
  border: 0;
`;

const InTopbarConfig: React.FC = () => {

  const history = useHistory();

  const logoutHandle = useCallback(async () => {
    try {
      await api.get('/logout');
      logout();
      history.push('/');
    } catch (error) {
      toast.error('Algo não saiu como esperado. Tente novamente.');
    }
  }, [history]);

  return (
    <Container>
      <CardWrapper className="topbar-config">
        <ul>
          <li className="logout">
            <img src={logoutIcon} alt="" />
            <Button onClick={logoutHandle} >
              <span>Logout</span>
            </Button >
          </li>
        </ul>
      </CardWrapper>
    </Container>
  );
};

export default InTopbarConfig;
