import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import GlobalStyle from './styles/global';
import Routes from './routes';
import 'react-phone-input-2/lib/style.css';
import 'react-toastify/dist/ReactToastify.css';
import 'cropperjs/dist/cropper.css';


declare global {
  interface Window {
    Appcues: any;
  }
}

const App: React.FC = () => (
  <Router>
    <Routes />

    <GlobalStyle />
    <ToastContainer />
  </Router>
);

export default App;
