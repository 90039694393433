/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import InputCep from '../../../../components/InputCep';
import { Container } from './styles';
import FormWrapper from '../../../../components/Form';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';
import { IUser } from '../../../../entities/User';

import api from '../../../../services/api';
import { AccountsContext as Context } from '../../../../context/AccountsContext';

interface ResidenceFormProps {
  user: IUser | undefined;
  setUser: any;
}

type FormData = {
  zip_code: string;
  street: string;
  number: string;
  complement: string;
  city: string;
  state: string;
  neighborhood: string;
  country: string;
};

const ResidenceForm: React.FC<ResidenceFormProps> = ({ user, setUser }) => {
  const { setDisableUploadResidenceProveDocuments } = useContext(Context);
  const [generalError, setGeneralError] = useState<string>('');
  const [loading, setLoading] = useState<number>(0);
  const { register, setValue, handleSubmit, errors, reset } = useForm<FormData>(
    {
      defaultValues: {
        ...user,
      },
    },
  );

  useEffect(() => {
    reset({
      ...user,
    });

    if (
      user?.zip_code === '' ||
      user?.zip_code === null ||
      user?.street === '' ||
      user?.street === null ||
      user?.city === '' ||
      user?.city === null ||
      user?.state === '' ||
      user?.state === null ||
      user?.neighborhood === '' ||
      user?.neighborhood === null
    ) {
      setDisableUploadResidenceProveDocuments(true);
    }
  }, [reset, setDisableUploadResidenceProveDocuments, user]);

  const onSubmit = async (data: FormData) => {
    try {
      const response = await api.put('/profile/address', {
        ...data,
        country: 'brazil',
      });
      const userRes: IUser = response.data.data;
      setUser(userRes);
      toast.success('Endereço atualizado com sucesso!');
    } catch (error) {
      /*
      if (errors == 500) {
        setGeneralError('Ocorreu um problema, tente novamente mais tarde.');
      } else {
        const errorData = error.response.data;
        Object.entries(errorData.errors).map((resError: any) => {
          setError(resError[0], {
            type: 'manual',
            message: resError[1][0],
          });
        });

        setGeneralError('');
      }
      */
    }
  };

  return (
    <Container cardTitle="Dados residenciais" loading={loading}>
      <span>{generalError}</span>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <InputCep
            label="CEP"
            id="zip_code"
            name="zip_code"
            className="col-xs-12 col-sm-3"
            type="text"
            mask="99999-999"
            value={user?.zip_code}
            setValue={setValue}
            setLoading={setLoading}
            disabled={!user?.can_edit_address_information}
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            errorMessage={errors.zip_code?.message}
          />

          <Input
            label="Rua"
            id="street"
            name="street"
            mask=""
            type="text"
            disabled={!user?.can_edit_address_information}
            className="col-xs-12 col-sm-7"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
              minLength: {
                value: 3,
                message: 'Obrigatorio pelo menos 3 caracteres',
              },
            })}
            errorMessage={errors.street?.message}
          />
          <Input
            label="Número"
            id="number"
            name="number"
            disabled={!user?.can_edit_address_information}
            className="col-xs-6 col-sm-2"
            register={register}
            errorMessage={errors.number?.message}
          />
          <Input
            label="Complemento"
            id="complement"
            name="complement"
            disabled={!user?.can_edit_address_information}
            className="col-xs-6 col-sm-5"
            register={register}
            errorMessage={errors.complement?.message}
          />
          <Input
            label="Cidade"
            id="city"
            name="city"
            disabled={!user?.can_edit_address_information}
            className="col-xs-6 col-sm-5"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            errorMessage={errors.city?.message}
          />
          <Input
            label="Estado"
            id="state"
            name="state"
            disabled={!user?.can_edit_address_information}
            className="col-xs-6 col-sm-2"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            errorMessage={errors.state?.message}
          />
          <Input
            label="Bairro"
            id="neighborhood"
            name="neighborhood"
            disabled={!user?.can_edit_address_information}
            className="col-xs-6 col-sm-5"
            register={register({
              required: { value: true, message: 'Campo obrigatório' },
            })}
            errorMessage={errors.neighborhood?.message}
          />
        </div>
        {user?.can_edit_address_information && (
          <div
            className="row"
            style={{ margin: '20px 0 0 0', justifyContent: 'flex-end' }}
          >
            <Button primaryPill>Salvar</Button>
          </div>
        )}
      </FormWrapper>
    </Container>
  );
};

export default ResidenceForm;
