import styled from 'styled-components';
import * as color from '../../../styles/colors'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 20px;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  color: ${color.gray};

  .title {
    font-size: 22px;
    line-height: 1.4;
  }

  .question {
    font-size: 16px;
  }

  .observation {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.015em;
    color: ${color.lightgray}
  }

  .slider {
    margin-bottom: 30px;
    min-width: 300px;
  }

  .slider-value {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.015em;
    color: ${color.orange};
  }

  .category-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
    max-width: 400px;
  }

  .container-button {
    align-self: flex-end;
  }

  .error {
    font-size: 12px;
    color: ${color.red}
  }

  @media (min-width: 576px) {
    width: 420px;
    padding: 10px;

    .slider {
      width: 200px;
    }
  }
`;
