import styled, { css } from 'styled-components';
import * as color from '../../../styles/colors';
import checkIcon from '../../../assets/check.svg';

interface PasswordProps {
  goodPassword?: boolean;
}

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  .btn-continue {
    margin: 30px 0 0 0;
  }

  @media (min-width: 576px) {
    width: 420px;
    padding: 10px;
  }
`;

export const ValidationContainer = styled.div`
  margin-top: 40px;
  line-height: 28px;
`;

export const ValidationStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const ValidationText = styled.p<PasswordProps>`
  font-size: 12px;
  color: ${({ goodPassword }) => (goodPassword ? color.green : color.red)};
`;

export const CircleStatus = styled.div<PasswordProps>`
  width: 18px;
  height: 18px;
  margin-right: 10px;
  border-radius: 15px;

  ${({ goodPassword }) =>
    goodPassword
      ? css`
          background: url(${checkIcon}) ${color.lightgreen} no-repeat
            center/15px 14px;
        `
      : css`
          background: #eeeeee;
        `}
`;

export const BoldText = styled.div`
  font-size: 12px;
  font-weight: bold;
`;
