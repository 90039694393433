import React, { createContext } from 'react';

import usePreRegister from '../hooks/usePreRegister';

interface IPreRegisterContextData {
  setStep: any;
  step: number;
  nextStep: any;
  previousStep: any;
  fullname: any;
  setFullname: any;
  email: any;
  setEmail: any;
  phone: any;
  setPhone: any;
  password: any;
  setPassword: any;
  confirmPassword: any;
  setConfirmPassword: any;
  cpf: any;
  setCpf: any;
  cnpj: any;
  setCnpj: any;
  protocol: any;
  setProtocol: any;
  userZipcode: any;
  setUserZipcode: any;
  userCity: any;
  setUserCity: any;
  userState: any;
  setUserState: any;
  userStreet: any;
  setUserStreet: any;
  userStreetNumber: any;
  setUserStreetNumber: any;
  userComplementNumber: any;
  setUserComplementNumber: any;
  companyZipcode: any;
  setCompanyZipcode: any;
  companyCity: any;
  setCompanyCity: any;
  companyState: any;
  setCompanyState: any;
  companyStreet: any;
  setCompanyStreet: any;
  companyStreetNumber: any;
  setCompanyStreetNumber: any;
  companyComplementNumber: any;
  setCompanyComplementNumber: any;
  frontsideDocument: any;
  setFrontsideDocument: any;
  backsideDocument: any;
  setBacksideDocument: any;
  residenceReceipt: any;
  setResidenceReceipt: any;
  isCompanyRegister: any;
  setIsCompanyRegister: any;
  bank: any;
  setBank: any;
  bankAgencyNumber: any;
  setBankAgencyNumber: any;
  bankAgencyDigitNumber: any;
  setBankAgencyDigitNumber: any;
  bankAccountNumber: any;
  setBankAccountNumber: any;
  bankAccountDigitNumber: any;
  setBankAccountDigitNumber: any;
  companyContractDoc: any;
  setCompanyContractDoc: any;
  companyBankStatementDoc: any;
  setCompanyBankStatementDoc: any;
  companyAddressReceipt: any;
  setCompanyAddressReceipt: any;
  bankStatementDoc: any;
  setBankStatementDoc: any;
  useTerms: any;
  setUseTerms: any;
  usePrivacy: any;
  setUsePrivacy: any;
  bankAccountType: any;
  setBankAccountType: any;
  userNeighborhood: any;
  setUserNeighborhood: any;
  companyNeighborhood: any;
  setCompanyNeighborhood: any;
  userCountry: any;
  setUserCountry: any;
  userDocumentName: any;
  setUserDocumentName: any;
  userDocumentType: any;
  setUserDocumentType: any;
  userResidentProveName: any;
  setUserResidentProveName: any;
  userResidentProveType: any;
  setUserResidentProveType: any;
  bankStatementName: any;
  setBankStatementName: any;
  bankStatementType: any;
  setBankStatementType: any;
  companyContractName: any;
  setCompanyContractName: any;
  companyContractType: any;
  setCompanyContractType: any;
  companyAddressProveName: any;
  setCompanyAddressProveName: any;
  companyAddressProveType: any;
  setCompanyAddressProveType: any;
  inviteCode: any;
  setInviteCode: any;
  storeLink: any;
  setStoreLink: any;
  averageBilling: number;
  setAverageBilling: any;
  categories: any;
  setCategories: any;
  gatewayName: any;
  setGatewayName: any;
  currentEcommerce: any;
  setCurrentEcommerce: any;
  howMeet: any;
  setHowMeet: any;
  isSameAddress: any;
  setIsSameAddress: any;
  hasMonthlyIncoming: any;
  setHasMonthlyIncoming: any;
  hasPromotionalTax: any;
  setHasPromotionalTax: any;
}
const PreRegisterContext = createContext<IPreRegisterContextData>(
  {} as IPreRegisterContextData,
);

const PreRegisterProvider: React.FC = ({ children }) => {
  const {
    setStep,
    step,
    nextStep,
    previousStep,
    fullname,
    setFullname,
    setEmail,
    email,
    phone,
    setPhone,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    cpf,
    setCpf,
    cnpj,
    setCnpj,
    protocol,
    setProtocol,
    userZipcode,
    setUserZipcode,
    userCity,
    setUserCity,
    userState,
    setUserState,
    userStreet,
    setUserStreet,
    userStreetNumber,
    setUserStreetNumber,
    userComplementNumber,
    setUserComplementNumber,
    companyZipcode,
    setCompanyZipcode,
    companyCity,
    setCompanyCity,
    companyState,
    setCompanyState,
    companyStreet,
    setCompanyStreet,
    companyStreetNumber,
    setCompanyStreetNumber,
    companyComplementNumber,
    setCompanyComplementNumber,
    frontsideDocument,
    setFrontsideDocument,
    backsideDocument,
    setBacksideDocument,
    residenceReceipt,
    setResidenceReceipt,
    isCompanyRegister,
    setIsCompanyRegister,
    bank,
    setBank,
    bankAccountType,
    setBankAccountType,
    bankAgencyNumber,
    setBankAgencyNumber,
    bankAgencyDigitNumber,
    setBankAgencyDigitNumber,
    bankAccountNumber,
    setBankAccountNumber,
    bankAccountDigitNumber,
    setBankAccountDigitNumber,
    companyContractDoc,
    setCompanyContractDoc,
    companyBankStatementDoc,
    setCompanyBankStatementDoc,
    companyAddressReceipt,
    setCompanyAddressReceipt,
    bankStatementDoc,
    setBankStatementDoc,
    useTerms,
    setUseTerms,
    usePrivacy,
    setUsePrivacy,
    userNeighborhood,
    setUserNeighborhood,
    companyNeighborhood,
    setCompanyNeighborhood,
    userCountry,
    setUserCountry,
    userDocumentName,
    setUserDocumentName,
    userDocumentType,
    setUserDocumentType,
    userResidentProveName,
    setUserResidentProveName,
    userResidentProveType,
    setUserResidentProveType,
    bankStatementName,
    setBankStatementName,
    bankStatementType,
    setBankStatementType,
    companyContractName,
    setCompanyContractName,
    companyContractType,
    setCompanyContractType,
    companyAddressProveName,
    setCompanyAddressProveName,
    companyAddressProveType,
    setCompanyAddressProveType,
    inviteCode,
    setInviteCode,
    storeLink,
    setStoreLink,
    averageBilling,
    setAverageBilling,
    categories,
    setCategories,
    gatewayName,
    setGatewayName,
    currentEcommerce,
    setCurrentEcommerce,
    howMeet,
    setHowMeet,
    isSameAddress,
    setIsSameAddress,
    hasMonthlyIncoming,
    setHasMonthlyIncoming,
    hasPromotionalTax,
    setHasPromotionalTax,
  } = usePreRegister();

  return (
    <PreRegisterContext.Provider
      value={{
        setStep,
        step,
        nextStep,
        previousStep,
        fullname,
        setFullname,
        setEmail,
        email,
        phone,
        setPhone,
        password,
        setPassword,
        confirmPassword,
        setConfirmPassword,
        cpf,
        setCpf,
        cnpj,
        setCnpj,
        protocol,
        setProtocol,
        userZipcode,
        setUserZipcode,
        userCity,
        setUserCity,
        userState,
        setUserState,
        userStreet,
        setUserStreet,
        userStreetNumber,
        setUserStreetNumber,
        userComplementNumber,
        setUserComplementNumber,
        companyZipcode,
        setCompanyZipcode,
        companyCity,
        setCompanyCity,
        companyState,
        setCompanyState,
        companyStreet,
        setCompanyStreet,
        companyStreetNumber,
        setCompanyStreetNumber,
        companyComplementNumber,
        setCompanyComplementNumber,
        frontsideDocument,
        setFrontsideDocument,
        backsideDocument,
        setBacksideDocument,
        residenceReceipt,
        setResidenceReceipt,
        isCompanyRegister,
        setIsCompanyRegister,
        bank,
        setBank,
        bankAccountType,
        setBankAccountType,
        bankAgencyNumber,
        setBankAgencyNumber,
        bankAgencyDigitNumber,
        setBankAgencyDigitNumber,
        bankAccountNumber,
        setBankAccountNumber,
        bankAccountDigitNumber,
        setBankAccountDigitNumber,
        bankStatementDoc,
        setBankStatementDoc,
        useTerms,
        setUseTerms,
        usePrivacy,
        setUsePrivacy,
        companyContractDoc,
        setCompanyContractDoc,
        companyBankStatementDoc,
        setCompanyBankStatementDoc,
        companyAddressReceipt,
        setCompanyAddressReceipt,
        userNeighborhood,
        setUserNeighborhood,
        companyNeighborhood,
        setCompanyNeighborhood,
        userCountry,
        setUserCountry,
        userDocumentName,
        setUserDocumentName,
        userDocumentType,
        setUserDocumentType,
        userResidentProveName,
        setUserResidentProveName,
        userResidentProveType,
        setUserResidentProveType,
        bankStatementName,
        setBankStatementName,
        bankStatementType,
        setBankStatementType,
        companyContractName,
        setCompanyContractName,
        companyContractType,
        setCompanyContractType,
        companyAddressProveName,
        setCompanyAddressProveName,
        companyAddressProveType,
        setCompanyAddressProveType,
        inviteCode,
        setInviteCode,
        storeLink,
        setStoreLink,
        averageBilling,
        setAverageBilling,
        categories,
        setCategories,
        gatewayName,
        setGatewayName,
        currentEcommerce,
        setCurrentEcommerce,
        howMeet,
        setHowMeet,
        isSameAddress,
        setIsSameAddress,
        hasMonthlyIncoming,
        setHasMonthlyIncoming,
        hasPromotionalTax,
        setHasPromotionalTax,
      }}
    >
      {children}
    </PreRegisterContext.Provider>
  );
};

export { PreRegisterContext, PreRegisterProvider };
