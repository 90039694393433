import styled from 'styled-components';
import { shade } from 'polished';
import * as color from '../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  > p {
    margin-top: 10px;
  }

  .btn-continue {
    margin: 30px 0 0 0;
  }

  .goback-button {
    margin-top: 10px;
  }

  .help-btn {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    padding: 2px;
  }

  .help-row {
    justify-content: flex-end;
  }

  @media (min-width: 576px) {
    width: 480px;
    padding: 10px;
  }
`;

export const UploadBox = styled.div`
  height: 90px;
  font-size: 12px;
  font-family: 'Roboto';
  border: 2px dashed #acacac;
  border-radius: 4px;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  padding: 10px 75px;
  color: #656565;

  span {
    font-weight: bold;
    color: ${color.orange};
    cursor: pointer;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const JumpStepLink = styled.div`
  color: ${color.orange};
  cursor: pointer;

  &:hover {
    color: ${shade(0.2, color.orange)};
  }
`;
