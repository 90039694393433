import React from 'react';
import { Container } from '../SwitchInput/styles';

interface SwitchInputProps {
  onChange?: any;
  className?: string;
  defaultValue?: boolean;
  id?: string;
  name?: string;
  disabled?: boolean
}

const SwitchInput: React.FC<SwitchInputProps> = ({
  onChange,
  className,
  id,
  defaultValue,
  name,
  disabled,
}) => {

  return (
    <Container className={className}>
      <label className="switch" htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          checked={defaultValue}
          onChange={e => onChange(e.target.checked)}
          name={name}
          disabled={disabled}
        />
        <span className={"slider round "+className} />
      </label>
    </Container>
  );
};

export default SwitchInput;
