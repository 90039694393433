import React, { useContext, useState, useCallback, useEffect } from 'react';
import { PreRegisterContext as Context } from '../../../../context/PreRegisterContext';
import { Container } from './styles';
import TextInput from '../../SignupComponents/TextInput';
import Button from '../../../../components/Button';
import Title from '../../../../components/Title';
import ContainerPage from '../../../../components/ContainerPage';
import api from '../../../../services/api';
import GoBackButton from '../../../../components/GoBackButton';
import { isValidCNPJ } from '../../../../utils/functions.js';
import arrowRight  from '../../../../assets/arrow-right.svg'

interface CnpjProps {
  onClick?: () => void;
  onChange?: (arg: any) => void;
  goBack?: () => void;
  value?: string;
}

const Cnpj: React.FC<CnpjProps> = () => {
  const { cnpj, setCnpj, nextStep, previousStep, fullname, setProtocol } =
    useContext(Context) || {};
  const [loading, setLoading] = useState<number>(0);
  const [errorMsg, setErrorMsg] = useState('');

  const [ disableProgress, setDisableProgress ] = useState<boolean>(true);

  const validateCNPJ = useCallback(() => {
    setLoading(1);
    if (isValidCNPJ(cnpj)) {
      api
        .get('/register/verify-cnpj', {
          params: {
            company_document: cnpj,
          },
        })
        .then((response) => {
          setProtocol(response.data.protocol);
          setLoading(0);
          nextStep();
        })
        .catch((error) => {
          setLoading(0);
          if (error.response.data.errors) {
            setErrorMsg(error.response.data.errors.company_document);
          } else {
            setErrorMsg(error.response.data.message);
          }
        });
    } else {
      setLoading(0);
      setErrorMsg('CNPJ inválido');
    }
  }, [cnpj, nextStep, setProtocol]);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.currentTarget;

      // removes non-alphanumeric character
      const rawValue = value.replace(/[\W_]/g, '');
      setCnpj(rawValue);
    },
    [setCnpj],
  );

  useEffect(() => {
    if(cnpj.length >= 14) {
      setDisableProgress(false);
    }else{
      setDisableProgress(true);
    }
  }, [cnpj, setDisableProgress]);

  return (
    <ContainerPage showLogo cnpjBkg loading={loading}>
      <Container>
        <Title primary>Qual CNPJ da sua empresa?</Title>
        <p>
          <b>{fullname.split(' ')[0]}</b>, precisamos que você informe seu CNPJ, assim 
          conseguimos puxar todas as informações da sua empresa:
        </p>
        <TextInput
          id="cnpj"
          name="cnpj"
          mask="99.999.999/9999-99"
          type="text"
          placeholder="CNPJ"
          value={cnpj}
          onChange={handleChange}
          error={errorMsg}
        />
        <Button
          primary
          type="button"
          className="btn-continue"
          textTransform
          onClick={validateCNPJ}
          disabled={disableProgress}
        >
          Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
        </Button>

        <GoBackButton className="goback-button" onClick={() => previousStep()}>Voltar</GoBackButton>
      </Container>
    </ContainerPage>
  );
};

export default Cnpj;
