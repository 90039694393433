import styled from "styled-components";
import * as color from '../../styles/colors';

export const Container = styled.div`
  margin-bottom: 30px;

  .slick-prev, .slick-next, .slick-prev:before, .slick-next:before {
    color: ${color.orange} !important;
    font-size: 23px;
    top: 140px;
  }

  .slick-dots li button:before {
    font-size: 10px;
    color: #ff4e05;
  }
  
`;