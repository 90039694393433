import React, { useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import api from '../services/api';
import { login } from '../services/auth.js';

import { AccountsContext as Context } from '../context/AccountsContext';

const Redirect: React.FC = () => {
  const history = useHistory();
  const { setUser } = useContext(Context);

  interface ParamTypes {
    expiration: string;
    user: string;
    urldata: string | undefined;
  }

  const { expiration, user, urldata } = useParams<ParamTypes>();

  useEffect(() => {
    async function getToken() {
      try {
        const response = await api.post('/get-authenticated', {
          expiration,
          user,
        });

        const userRes = response.data.user;
        localStorage.clear();
        setUser(userRes);
        login(response.data.token);

        if (urldata) {
          console.log(urldata);
          history.push(`/${urldata}`);
        } else {
          history.push('/home');
        }
      } catch (error) {
        alert('token inválido');
        history.push('/');
      }
    }

    getToken();
  }, [expiration, history, user, setUser, urldata]);

  return <div />;
};

export default Redirect;
