import React, { useState, useEffect} from 'react';
import {IconContext} from 'react-icons';
import { FiChevronDown, FiChevronUp,FiCheck,FiMinus } from 'react-icons/fi';
import {AccordionSection,AccordionHeader,AccordionContent,
    SwitchContainer,Dropdown,Col10,Col25,Col40} from './styles';
import SwitchInput from '../SwitchInput';
import CheckboxInput from '../CheckboxInput';
import AccordionPermissionChild from '../AccordionPermissionChild';

interface PermissionItem{
    value:string;
    checked:boolean;
    show:boolean;
    partial:boolean;
}
interface Permission{
    toView:PermissionItem;
    manage:PermissionItem;
}
interface AccordionPermissionsProps {
    id?: string;
    label?: string;        
    items?:[];
    checked:boolean;
    permissions?:Permission;
    role:string;
    onChange?: (e:any) => void;
    isModeView?:boolean;
}

  const AccordionPermission: React.FC<AccordionPermissionsProps> = ({
    id,
    permissions,
    label,        
    items,
    checked,
    role,
    onChange,
    isModeView
  }) =>{

    const [activeState, setActiveState] = useState(false);
    const [setHeight, setHeightState] = useState("0px");
    const [permissionsTemp, setPermissionsTemp] = useState(permissions);

    const toogle = () =>{
        setActiveState(!activeState);
        var height = 0;

        if(items && items?.length>0){
            height = 500;            
        }

        setHeightState(
            activeState === true ? "0px" : `${height}px`
        );        
    }

   return (
        <IconContext.Provider value={{color:'#00ffb9',size:'25px'}}>
            
            <AccordionSection >
                <AccordionHeader onClick={()=>toogle()}>
                    <Col40 isView={isModeView}>
                        <SwitchContainer className="column" >
                            {!isModeView ? 
                            <SwitchInput
                                id={id}
                                className="switch-input"
                                defaultValue={checked} 
                                onChange={onChange}
                                disable={role!='custom'||isModeView}
                            /> : null}
                            <p className="switch-label">{label}</p>
                        </SwitchContainer>
                    </Col40>
                    {isModeView ? 
                    <Col25 style={{textAlign:'center'}}>
                        {permissionsTemp && permissionsTemp.toView.show ?
                            permissionsTemp.toView.partial ? 
                                <FiMinus color='#FF6D00' size="14"></FiMinus>:
                                permissionsTemp.toView.checked ? <FiCheck color='#FF6D00' size="14"></FiCheck>:null
                            :null
                        }
                    </Col25>                   
                    :
                    <Col25 style={{alignContent:'center'}}>
                        {permissionsTemp && permissionsTemp.toView.show && 
                        <CheckboxInput                              
                            id={permissionsTemp.toView.value} 
                            checked={permissionsTemp.toView.checked}
                            onChange={onChange} 
                            partial={permissionsTemp.toView.partial}                           
                            disable={role!='custom'||isModeView}
                        />}
                    </Col25>
                    }

                    {isModeView ? 
                    <Col25 style={{textAlign:'center'}}>
                        {permissionsTemp && permissionsTemp.manage.show ?
                            permissionsTemp.manage.partial ? 
                                <FiMinus color='#FF6D00' size="14"></FiMinus>:
                                permissionsTemp.manage.checked ? <FiCheck color='#FF6D00' size="14"></FiCheck>:null
                            :null
                        }
                    </Col25>
                    :
                    <Col25 style={{textAlign:'center'}}>
                        {permissionsTemp && permissionsTemp.manage.show && 
                        <CheckboxInput                              
                            id={permissionsTemp.manage.value}
                            checked={permissionsTemp.manage.checked}
                            onChange={onChange}  
                            disable={!permissionsTemp.toView.checked || role!='custom'||isModeView} 
                            partial={permissionsTemp.manage.partial}                            
                        />}
                    </Col25>
    	            }

                    <Col10 style={{textAlign:'right'}}>
                        {items && items.length>0 ? (
                            <span>{activeState==true ? <FiChevronUp style={{color:'#56607E'}}/>:<FiChevronDown style={{color:'#56607E'}}/>}</span>
                        ):(
                            <span><FiChevronDown style={{color:'#FFFFFF'}}/></span>
                        )}
                    </Col10>
                </AccordionHeader>

                <AccordionContent  style={{ maxHeight: `${setHeight}` }}>
                    {activeState == true &&(
                    <Dropdown>
                        {items && items?.map((item:any,key) => 
                           isModeView && !item.permissions.toView.checked && !item.permissions.manage.checked ? null:(
                            <AccordionPermissionChild 
                                id={id+key.toString()}
                                onChange={onChange}
                                label={item.label} 
                                permissions={item.permissions}
                                role={role}
                                isModeView={isModeView}
                                >                                
                            </AccordionPermissionChild>
                        ))}
                    </Dropdown>)
                    }                    
                </AccordionContent>
                
            </AccordionSection>
                
        </IconContext.Provider>
    );
}

export default AccordionPermission;