import styled from 'styled-components';
import Card from '../../../../../components/Card';

export const Container = styled(Card)`
  .company-name,
  .company-document {
    font-size: 16px;
    font-weight: 600;
    padding-top: 8px;
  }
`;
