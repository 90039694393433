import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 20px;

  > p {
    margin-top: 10px;
  }

  .btn-continue {
    margin: 45px 0 0 0;
  }

  .goback-button {
    margin-top: 10px;
  }

  @media (min-width: 576px) {
    width: 420px;
    padding: 10px;
  }
`;
