import axios from 'axios';
import { getToken } from "./auth";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

api.interceptors.request.use(async config => {
  const token = getToken();
  
  if (token && token.length) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
/**
const resIntercept = api.interceptors.response.use((response) => {
    return response
}, (error) => {
  
   if(error.response.status == 401) {
    alert('você não tem permissão ou token expirado')
    window.location.href = "/";
   }

   return axios(error.config);
})
 */


export const apiTest = axios.create({
  baseURL: 'https://enddpqy117o6e2i.m.pipedream.net'
});

apiTest.interceptors.request.use(async config => {
  const token = getToken();
  
  if (token && token.length) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
