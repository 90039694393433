import styled, { keyframes } from 'styled-components';
import * as color from '../../../styles/colors'

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  
  align-items: center;
  justify-content: space-space-around;
  text-align: center;

  color: ${color.gray};

  .logo {
    flex: 1;
    max-width: 200px;
    margin: 20px 0 20px 0;
  }

  .illustration {
    max-height: 100%;
    max-width: 350px;
  }

  .text {
    flex: 1;
    max-width: 300px;

    h1 {
      font-family: Poppins;
      font-style: normal;
      font-size: 36px;
      line-height: 100%;
      letter-spacing: 0.015em;
      margin-bottom: 10px;
    }

    p {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 120%;
      color: ${color.lightgray}
    }
  }
  

  .social-media-container {
    margin-top: 30px;
    flex: 1;

    >p {
      margin-bottom: 5px;
    }

    .social-media-imgs {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;

      img {
        margin: 7px;
      }
    }
  }

  @media (max-width: 768px) {
    .logo {
      max-width: 150px;
      margin-bottom: 20px;
    }

    .illustration {
      max-width: 80%;
      margin-bottom: 10px;
    }

    h1 {font-size: 18px;}

    p {font-size: 11px;}

    .social-media-container {
      .social-media-imgs {
        height: 30px;

        img {
          margin: 4px;
        }
      }
    }

  }

  .social-media-icon {
    transition: all 0.3s ease;
  }

  .social-media-icon:hover {
    opacity: 0.8;
  }

`;

interface FadeProps {
  show?: boolean;
  seconds?: string;
}

const fadeIn = keyframes`
  0% {
    display: block;
    opacity: 0;
  }
  99% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  99% {
    opacity: 1;
    display: none;
  }
`;

export const FadeContainer = styled.div<FadeProps>`

  ${({ show }) => show === true ? (
    'display: block;'
  ) : (
    'display: none;'
  ) }

  animation: ${({seconds}) => ( seconds? seconds : 0.5)}s ${({ show }) => (show === true ? fadeIn : fadeOut )};
  animation-fill-mode: forwards; 
`;