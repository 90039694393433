import styled from 'styled-components';
import checkIcon from '../../assets/check.svg';
import lineIcon from '../../assets/line.svg';
import * as color from '../../styles/colors';

interface CheckboxInputProps {
  partial?:boolean;
  disable?:boolean;
}
export const Container = styled.div<CheckboxInputProps>`
  position: relative;
  font-family: 'Poppins';
  margin-bottom: 10px;
  margin-top: 10px;

  input {
    position: absolute;
    left: 1px;
    top: 1px;
    width: 17px;
    height: 17px;
    opacity: 0;
  }

  input:checked ~ label::before {
    border-color: ${color.orange};
    background: url(${ props => props.partial ? lineIcon : checkIcon}) ${color.orange} no-repeat center/${ props => props.partial ? '10px 10px' : '15px 15px'};
    opacity: ${ props => props.disable ? '0.5' : '1'}
  }

  label {
    display: flex;
    align-items: center;

    ::before {
      content: '';
      display: inline-block;
      height: 16px;
      min-width: 16px;
      border-radius: 5px;
      border: 1px solid #dddddd;
      margin-right: 8px;
      cursor: pointer;
    }
  }
`;

export const BoldLabel = styled.div`
  font-weight: bold;
`;
