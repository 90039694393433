import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Container } from './styles';
import { PreRegisterContext as Context } from '../../../context/PreRegisterContext';
import TextInput from '../SignupComponents/TextInput';
import Button from '../../../components/Button';
import ContainerPage from '../../../components/ContainerPage';
import GoBackButton from '../../../components/GoBackButton';
import api from '../../../services/api';
import { validateEmail } from '../../../utils/functions';
import arrowRight  from '../../../assets/arrow-right.svg'

const EmailView: React.FC = () => {
  const { email, setEmail, fullname, previousStep, nextStep, setStep } =
    useContext(Context) || {};
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(0);
  const [isInputsValidated, setIsInputsValidated] = useState<boolean>(false);

  const sendEmailCode = useCallback(
    async (email) => {
      try {
        const code = await api.get('/register/send-email-code', {
          params: {
            email,
            firstname: fullname,
          },
        });

        if (code) nextStep();
      } catch (error) {
        if (error.response.status === 409) {
          setStep(4);
        }
        setErrorMsg(error.response.data.message);
      }
      setLoading(0);
    },
    [fullname, nextStep, setStep],
  );

  const verifyEmail = useCallback(() => {
    if(validateEmail(email)){
      setLoading(1);
      api
        .get('/register/verify-email', {
          params: {
            email,
          },
        })
        .then(() => {
          sendEmailCode(email);
        })
        .catch((error) => {
          setLoading(0);
          setErrorMsg(error.response.data.errors.email);
        });
    }else{
      setErrorMsg('Email inválido')
    }
    
  }, [email, sendEmailCode]);


  useEffect(() => {
    if(validateEmail(email)) setIsInputsValidated(true);
  }, [email, setEmail])

  return (
    <ContainerPage showLogo randomBkg loading={loading}>
      <Container>
        <p style={{ textAlign: 'center' }}>Maravilha, {fullname.split(' ')[0]}! <br/> Agora precisamos que informe seu e-mail.</p>
        <TextInput
          id="email"
          name="email"
          mask=""
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => {
            setEmail(e.currentTarget.value)
            validateEmail(e.currentTarget.value) ? setIsInputsValidated(true) : setIsInputsValidated(false);
          }}
          error={errorMsg}
        />
        <Button
          primary
          type="button"
          className="btn-continue"
          textTransform
          onClick={verifyEmail}
          disabled={!isInputsValidated}
        >
          Prosseguir <img style={{ marginLeft: '10px'}} src={arrowRight} alt='Prosseguir'/>
        </Button>

        <GoBackButton className='goback-button' onClick={() => previousStep()}>Voltar</GoBackButton>
      </Container>
    </ContainerPage>
  );
};

export default EmailView;
