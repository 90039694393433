import React, { useState } from 'react';
import { Container } from './styles';
import PageTopbar from '../../../../components/PageTopbar';
import GobackLink from '../../../../components/GoBackLink';
import Card from '../../../../components/Card';
import Form from '../../../../components/Form';
import Select from '../../../../components/AdminSelectInput';
import Input from '../../../../components/AdminInput';
import useBankList from '../../../../hooks/useBankList';

interface BankProps {
  name: string;
  code: string;
}

const CompanyCreateCPF: React.FC = () => {
  const [bank, setBank] = useState<string>('');

  const bankList = useBankList();

  return (
    <Container>
      <PageTopbar
        title="Criar empresa pessoa física"
        subTitle="Gerencie as empresas relacionadas com seus produtos CloudFox"
      />
      <GobackLink to="/companies/company-type" className="goback-button" />
      <Card cardTitle="Criar empresa">
        <Form saveButton>
          <div className="row">
            <Select label="País da empresa" className="col-md-5">
              <option>Brasil</option>
              {/* <option>Portugal</option>
              <option>Estados Unidos</option>
              <option>Alemanha</option>
              <option>Espanha</option>
              <option>França</option>
              <option>Itália</option>
              <option>Reino Unido</option> */}
            </Select>
          </div>
          <div className="row">
            <Input
              label="CPF"
              id="cpf"
              name="cpf"
              mask="999.999.999-99"
              type="cpf"
              placeholder="CPF"
              className="col-md-6 col-xs-12"
            />
            <Input
              label="Nome da empresa"
              id="company-name"
              name="company-name"
              mask=""
              placeholder="Nome da empresa"
              className="col-md-6 col-xs-12"
            />
          </div>
        </Form>
      </Card>
      <Card cardTitle="Conta bancária" className="bank-account">
        <Form saveButton>
          <div className="row">
            <Select
              label="Banco"
              onChange={(e) => setBank(e.currentTarget.value)}
              value={bank}
              className="col-md-5"
            >
              <option>Selecione um banco</option>
              {bankList.map((s: BankProps) => {
                return (
                  <option key={s.code} value={s.code}>
                    {s.name}
                  </option>
                );
              })}
            </Select>
          </div>
          <div className="row">
            <Input
              label="Agência"
              id="bank-agency"
              name="bank-agency"
              mask=""
              placeholder="Agência"
              className="col-md-7 col-xs-12"
            />
            <Input
              label="Digito"
              id="bank-agency-digit"
              name="bank-agency-digit"
              mask=""
              placeholder="Digito"
              className="col-md-5 col-xs-12"
            />
          </div>
          <div className="row">
            <Input
              label="Conta"
              id="bank-account"
              name="bank-account"
              mask=""
              placeholder="Conta"
              className="col-md-7 col-xs-12"
            />
            <Input
              label="Digito"
              id="bank-account-digit"
              name="bank-account-digit"
              mask=""
              placeholder="Digito"
              className="col-md-5 col-xs-12"
            />
          </div>
        </Form>
      </Card>
    </Container>
  );
};

export default CompanyCreateCPF;
