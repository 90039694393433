import { useState, useCallback, useEffect } from 'react';

// const localStep = JSON.parse(localStorage.getItem('step') as string);

export default function usePreRegister() {

  // LEAD | COMERCIAL
  const [ storeLink, setStoreLink ] = useState<string | null>('');
  const [ averageBilling, setAverageBilling ] = useState<number>(0);
  const [ categories, setCategories ] = useState<string | null>('{"dropshippingImport":0, "physicalProduct":0, "digitalProduct":0, "classes":0, "subscriptions":0, "others":0}');
  const [ gatewayName, setGatewayName ] = useState<string | null>('');
  const [ currentEcommerce, setCurrentEcommerce ] = useState<string | null>('{"shopify":0,"integratedStore":0,"wix":0,"wooCommerce":0,"pageLand":0,"otherEcommerce":0,"otherEcommerceName":""}');
  const [ howMeet, setHowMeet ] = useState<string | null>('{"facebook":0, "ad":0, "youtube":0, "linkedin":0, "email":0, "instagram":0, "recomendation":0, "other":0}');
  const [ isSameAddress, setIsSameAddress ] = useState<boolean>(false);
  const [ hasMonthlyIncoming, setHasMonthlyIncoming ] = useState<boolean>(false);
  const [ hasPromotionalTax, setHasPromotionalTax ] = useState<boolean>(false);

  // USER
  const [step, setStep] = useState<number>(1);
  const [fullname, setFullname] = useState<string | null>('');
  const [email, setEmail] = useState<string | null>('');
  const [phone, setPhone] = useState<string | null>('');
  const [password, setPassword] = useState<string | null>('');
  const [confirmPassword, setConfirmPassword] = useState<string | null>('');
  const [cpf, setCpf] = useState<string | null>('');
  const [cnpj, setCnpj] = useState<string | null>('');
  const [protocol, setProtocol] = useState<string | null>('');

  const [userNeighborhood, setUserNeighborhood] = useState<string | null>('');
  const [companyNeighborhood, setCompanyNeighborhood] = useState<string | null>(
    '',
  );
  const [userCountry, setUserCountry] = useState<string | null>('');

  const [userZipcode, setUserZipcode] = useState<string | null>('');
  const [userCity, setUserCity] = useState<string | null>('');
  const [userState, setUserState] = useState<string | null>('');
  const [userStreet, setUserStreet] = useState<string | null>('');
  const [userStreetNumber, setUserStreetNumber] = useState<string | null>('');
  const [userComplementNumber, setUserComplementNumber] = useState<
    string | null
  >('');
  const [residenceReceipt, setResidenceReceipt] = useState<[]>([]);
  const [frontsideDocument, setFrontsideDocument] = useState<[]>([]);
  const [backsideDocument, setBacksideDocument] = useState<[]>([]);

  // COMPANY
  const [companyZipcode, setCompanyZipcode] = useState<string | null>('');
  const [companyCity, setCompanyCity] = useState<string | null>('');
  const [companyState, setCompanyState] = useState<string | null>('');
  const [companyStreet, setCompanyStreet] = useState<string | null>('');
  const [companyStreetNumber, setCompanyStreetNumber] = useState<string | null>(
    '',
  );
  const [companyComplementNumber, setCompanyComplementNumber] = useState<
    string | null
  >('');

  const [isCompanyRegister, setIsCompanyRegister] = useState<string | null>('juridical person');
  const [inviteCode, setInviteCode] = useState<string | null>('');

  // BANK
  const [bank, setBank] = useState<string | null>('');
  const [bankAccountType, setBankAccountType] = useState<string | null>('');
  const [bankAgencyNumber, setBankAgencyNumber] = useState<string | null>('');
  const [bankAgencyDigitNumber, setBankAgencyDigitNumber] = useState<
    string | null
  >('');
  const [bankAccountNumber, setBankAccountNumber] = useState<string | null>('');
  const [bankAccountDigitNumber, setBankAccountDigitNumber] = useState<
    string | null
  >('');
  const [bankStatementDoc, setBankStatementDoc] = useState<[]>([]);

  const [useTerms, setUseTerms] = useState<boolean>(false);
  const [usePrivacy, setUsePrivacy] = useState<boolean>(false);

  const [companyContractDoc, setCompanyContractDoc] = useState([]);
  const [companyBankStatementDoc, setCompanyBankStatementDoc] = useState([]);
  const [companyAddressReceipt, setCompanyAddressReceipt] = useState([]);

  // Document Upload

  const [userDocumentName, setUserDocumentName] = useState<string | null>('');
  const [userDocumentType, setUserDocumentType] = useState<string | null>('');

  const [userResidentProveName, setUserResidentProveName] = useState<
    string | null
  >('');
  const [userResidentProveType, setUserResidentProveType] = useState<
    string | null
  >('');

  const [bankStatementName, setBankStatementName] = useState<string | null>('');
  const [bankStatementType, setBankStatementType] = useState<string | null>('');

  const [companyContractName, setCompanyContractName] = useState<string | null>(
    '',
  );
  const [companyContractType, setCompanyContractType] = useState<string | null>(
    '',
  );

  const [companyAddressProveName, setCompanyAddressProveName] = useState<
    string | null
  >('');
  const [companyAddressProveType, setCompanyAddressProveType] = useState<
    string | null
  >('');

  useEffect(() => {
    const averageBillingString = localStorage.getItem('averageBilling') as string;
    const storeLinkString = localStorage.getItem('storeLink') as string;
    const categoriesString = localStorage.getItem('categories') as string;
    const gatewayNameString = localStorage.getItem('gatewayName') as string;
    const currentEcommerceString = localStorage.getItem('currentEcommerce') as string;
    const howMeetString = localStorage.getItem('howMeet') as string;
    const isSameAddressString = localStorage.getItem('isSameAddress') as string; 
    const hasMonthlyIncomingString = localStorage.getItem('hasMonthlyIncoming') as string; 
    const hasPromotionalTax = localStorage.getItem('hasPromotionalTax') as string; 

    if(storeLinkString) setStoreLink(storeLinkString);
    if(averageBillingString) setAverageBilling(parseInt(averageBillingString));
    if(categoriesString) setCategories(categoriesString);
    if(gatewayNameString) setGatewayName(gatewayNameString);
    if(currentEcommerceString) setCurrentEcommerce(currentEcommerceString);
    if(howMeetString) setHowMeet(howMeetString);
    if(isSameAddressString) setIsSameAddress(isSameAddressString === "true" ? true : false);
    if(hasMonthlyIncomingString) setHasMonthlyIncoming(hasMonthlyIncomingString === "true" ? true : false);
    if(hasPromotionalTax) setHasPromotionalTax(hasPromotionalTax === "true" ? true : false);

    const stepString = localStorage.getItem('step') as string;
    const fullnameString = localStorage.getItem('fullname') as string;
    const emailString = localStorage.getItem('email') as string;
    const phoneString = localStorage.getItem('phone') as string;
    const passwordString = localStorage.getItem('password') as string;
    const confirmPasswordString = localStorage.getItem('confirmPassword') as string;
    const cpfString = localStorage.getItem('cpf') as string;
    const cnpjString = localStorage.getItem('cnpj') as string;
    const protocolString = localStorage.getItem('protocol') as string;
    const userZipcodeString = localStorage.getItem('userZipcode') as string;
    const userCityString = localStorage.getItem('userCity') as string;
    const userStateString = localStorage.getItem('userState') as string;
    const userStreetString = localStorage.getItem('userStreet') as string;
    const userStreetNumberString = localStorage.getItem(
      'userStreetNumber',
    ) as string;
    const userComplementNumberString = localStorage.getItem(
      'userComplementNumber',
    ) as string;
    const companyZipcodeString = localStorage.getItem(
      'companyZipcode',
    ) as string;
    const companyCityString = localStorage.getItem('companyCity') as string;
    const companyStateString = localStorage.getItem('companyState') as string;
    const companyStreetString = localStorage.getItem('companyStreet') as string;
    const companyStreetNumberString = localStorage.getItem(
      'companyStreetNumber',
    ) as string;
    const companyComplementNumberString = localStorage.getItem(
      'companyComplementNumber',
    ) as string;
    const isCompanyRegisterString = localStorage.getItem(
      'isCompanyRegister',
    ) as string;

    const bankString = localStorage.getItem('bank') as string;
    const bankAccountTypeString = localStorage.getItem(
      'bankAccountType',
    ) as string;

    const bankAgencyNumberString = localStorage.getItem(
      'bankAgencyNumber',
    ) as string;
    const bankAgencyDigitNumberString = localStorage.getItem(
      'bankAgencyDigitNumber',
    ) as string;
    const bankAccountNumberString = localStorage.getItem(
      'bankAccountNumber',
    ) as string;
    const bankAccountDigitNumberString = localStorage.getItem(
      'bankAccountDigitNumber',
    ) as string;

    const useTermsString = localStorage.getItem('useTerms') as string;
    const usePrivacyString = localStorage.getItem('usePrivacy') as string;

    const userNeighborhoodString = localStorage.getItem(
      'userNeighborhood',
    ) as string;
    const companyNeighborhoodString = localStorage.getItem(
      'companyNeighborhood',
    ) as string;
    const userCountryString = localStorage.getItem('userCountry') as string;

    const userDocumentNameString = localStorage.getItem(
      'userDocumentName',
    ) as string;
    const userDocumentTypeString = localStorage.getItem(
      'userDocumentType',
    ) as string;

    const userResidentProveNameString = localStorage.getItem(
      'userResidentProveName',
    ) as string;
    const userResidentProveTypeString = localStorage.getItem(
      'userResidentProveType',
    ) as string;

    const bankStatementNameString = localStorage.getItem(
      'bankStatementName',
    ) as string;
    const bankStatementTypeString = localStorage.getItem(
      'bankStatementType',
    ) as string;

    const companyContractNameString = localStorage.getItem(
      'companyContractName',
    ) as string;
    const companyContractTypeString = localStorage.getItem(
      'companyContractType',
    ) as string;

    const companyAddressProveNameString = localStorage.getItem(
      'companyAddressProveName',
    ) as string;
    const companyAddressProveTypeString = localStorage.getItem(
      'companyAddressProveType',
    ) as string;

    if (stepString) setStep(parseInt(stepString));
    if (fullnameString) setFullname(fullnameString);
    if (emailString) setEmail(emailString);
    if (phoneString) setPhone(phoneString);
    if (passwordString) setPassword(passwordString);
    if (confirmPasswordString) setConfirmPassword(confirmPasswordString);
    if (cpfString) setCpf(cpfString);
    if (cnpjString) setCnpj(cnpjString);
    if (protocolString) setProtocol(protocolString);
    if (userZipcodeString) setUserZipcode(userZipcodeString);
    if (userCityString) setUserCity(userCityString);
    if (userStateString) setUserState(userStateString);
    if (userStreetString) setUserStreet(userStreetString);
    if (userStreetNumberString) setUserStreetNumber(userStreetNumberString);
    if (userComplementNumberString)
      setUserComplementNumber(userComplementNumberString);
    if (companyZipcodeString) setCompanyZipcode(companyZipcodeString);
    if (companyCityString) setCompanyCity(companyCityString);
    if (companyStateString) setCompanyState(companyStateString);
    if (companyStreetString) setCompanyStreet(companyStreetString);
    if (companyStreetNumberString)
      setCompanyStreetNumber(companyStreetNumberString);
    if (companyComplementNumberString)
      setUserComplementNumber(companyComplementNumberString);
    if (isCompanyRegisterString) setIsCompanyRegister(isCompanyRegisterString);
    if (bankString) setBank(bankString);
    if (bankAccountTypeString) setBankAccountType(bankAccountTypeString);
    if (bankAgencyNumberString) setBankAgencyNumber(bankAgencyNumberString);
    if (bankAgencyDigitNumberString)
      setBankAgencyDigitNumber(bankAgencyDigitNumberString);
    if (bankAccountNumberString) setBankAccountNumber(bankAccountNumberString);
    if (bankAccountDigitNumberString)
      setBankAccountDigitNumber(bankAccountDigitNumberString);

    if (userNeighborhoodString) setUserNeighborhood(userNeighborhoodString);
    if (companyNeighborhoodString)
      setCompanyNeighborhood(companyNeighborhoodString);
    if (userCountryString) setUserCountry(userCountryString);

    useTermsString === 'true' ? setUseTerms(true) : setUseTerms(false);
    usePrivacyString === 'true' ? setUsePrivacy(true) : setUsePrivacy(false);

    if (userDocumentNameString) setUserDocumentName(userDocumentNameString);
    if (userDocumentTypeString) setUserDocumentType(userDocumentTypeString);

    if (userResidentProveNameString)
      setUserResidentProveName(userResidentProveNameString);
    if (userResidentProveTypeString)
      setUserResidentProveType(userResidentProveTypeString);

    if (bankStatementNameString) setBankStatementName(bankStatementNameString);
    if (bankStatementTypeString) setBankStatementType(bankStatementTypeString);

    if (companyContractNameString)
      setCompanyContractName(companyContractNameString);
    if (companyContractTypeString)
      setCompanyContractType(companyContractTypeString);

    if (companyAddressProveNameString)
      setCompanyAddressProveName(companyAddressProveNameString);
    if (companyAddressProveTypeString)
      setCompanyAddressProveType(companyAddressProveTypeString);
  }, []);

  useEffect(() => {
    if (fullname) localStorage.setItem('fullname', fullname.toString());
    if (email) localStorage.setItem('email', email.toString());
    if (phone) localStorage.setItem('phone', phone.toString());
    if (password) localStorage.setItem('password', password.toString());
    if (confirmPassword) localStorage.setItem('confirmPassword', confirmPassword.toString());
    if (cpf) localStorage.setItem('cpf', cpf.toString());
    if (cnpj) localStorage.setItem('cnpj', cnpj.toString());
    if (protocol) localStorage.setItem('protocol', protocol.toString());
    if (userZipcode)
      localStorage.setItem('userZipcode', userZipcode.toString());
    if (userCity) localStorage.setItem('userCity', userCity.toString());
    if (userState) localStorage.setItem('userState', userState.toString());
    if (userStreet) localStorage.setItem('userStreet', userStreet.toString());
    if (userStreetNumber)
      localStorage.setItem('userStreetNumber', userStreetNumber.toString());
    if (userComplementNumber)
      localStorage.setItem(
        'userComplementNumber',
        userComplementNumber.toString(),
      );
    if (companyZipcode)
      localStorage.setItem('companyZipcode', companyZipcode.toString());
    if (companyCity)
      localStorage.setItem('companyCity', companyCity.toString());
    if (companyState)
      localStorage.setItem('companyState', companyState.toString());
    if (companyStreet)
      localStorage.setItem('companyStreet', companyStreet.toString());
    if (companyStreetNumber)
      localStorage.setItem(
        'companyStreetNumber',
        companyStreetNumber.toString(),
      );
    if (companyComplementNumber)
      localStorage.setItem(
        'companyComplementNumber',
        companyComplementNumber.toString(),
      );
    if (isCompanyRegister)
      localStorage.setItem('isCompanyRegister', isCompanyRegister.toString());
    if (bank) localStorage.setItem('bank', bank.toString());

    if (bankAgencyNumber)
      localStorage.setItem('bankAgencyNumber', bankAgencyNumber.toString());
    if (bankAgencyDigitNumber)
      localStorage.setItem(
        'bankAgencyDigitNumber',
        bankAgencyDigitNumber.toString(),
      );
    if (bankAccountNumber)
      localStorage.setItem('bankAccountNumber', bankAccountNumber.toString());
    if (bankAccountDigitNumber)
      localStorage.setItem(
        'bankAccountDigitNumber',
        bankAccountDigitNumber.toString(),
      );

    localStorage.setItem('useTerms', useTerms.toString());
    localStorage.setItem('usePrivacy', usePrivacy.toString());

    if (userNeighborhood)
      localStorage.setItem('userNeighborhood', userNeighborhood.toString());

    if (companyNeighborhood)
      localStorage.setItem(
        'companyNeighborhood',
        companyNeighborhood.toString(),
      );

    if (userCountry)
      localStorage.setItem('userCountry', userCountry.toString());

    if (userDocumentName)
      localStorage.setItem('userDocumentName', userDocumentName.toString());
    if (userDocumentType)
      localStorage.setItem('userDocumentType', userDocumentType.toString());

    if (userResidentProveName)
      localStorage.setItem(
        'userResidentProveName',
        userResidentProveName.toString(),
      );
    if (userResidentProveType)
      localStorage.setItem(
        'userResidentProveType',
        userResidentProveType.toString(),
      );

    if (bankStatementName)
      localStorage.setItem('bankStatementName', bankStatementName.toString());
    if (bankStatementType)
      localStorage.setItem('bankStatementType', bankStatementType.toString());

    if (companyContractName)
      localStorage.setItem(
        'companyContractName',
        companyContractName.toString(),
      );
    if (companyContractType)
      localStorage.setItem(
        'companyContractType',
        companyContractType.toString(),
      );

    if (companyAddressProveName)
      localStorage.setItem(
        'companyAddressProveName',
        companyAddressProveName.toString(),
      );
    if (companyAddressProveType)
      localStorage.setItem(
        'companyAddressProveType',
        companyAddressProveType.toString(),
      );

    if(averageBilling) 
      localStorage.setItem('averageBilling', averageBilling.toString());
    
    if(storeLink) 
      localStorage.setItem('storeLink', storeLink.toString());

    if(categories) 
      localStorage.setItem('categories', categories);
    
    if(gatewayName) 
      localStorage.setItem('gatewayName', gatewayName.toString());

    if(currentEcommerce) 
      localStorage.setItem('currentEcommerce', currentEcommerce);

    if(howMeet) 
      localStorage.setItem('howMeet', howMeet);

    if(isSameAddress !== null || isSameAddress !== undefined)
      localStorage.setItem('isSameAddress', isSameAddress.valueOf().toString());
  
    if(hasMonthlyIncoming !== null || hasMonthlyIncoming !== undefined)
      localStorage.setItem('hasMonthlyIncoming', hasMonthlyIncoming.valueOf().toString());

    if(hasPromotionalTax !== null || hasPromotionalTax !== undefined)
      localStorage.setItem('hasPromotionalTax', hasPromotionalTax.valueOf().toString());
  }, [
    fullname,
    email,
    phone,
    password,
    confirmPassword,
    cpf,
    cnpj,
    protocol,
    userZipcode,
    userCity,
    userState,
    userStreet,
    userStreetNumber,
    userComplementNumber,
    companyZipcode,
    companyCity,
    companyState,
    companyStreet,
    companyStreetNumber,
    companyComplementNumber,
    isCompanyRegister,
    bank,
    bankAccountType,
    bankAgencyNumber,
    bankAccountNumber,
    bankAccountDigitNumber,
    useTerms,
    usePrivacy,
    userNeighborhood,
    companyNeighborhood,
    userCountry,
    bankAgencyDigitNumber,
    userDocumentName,
    userDocumentType,
    userResidentProveName,
    userResidentProveType,
    bankStatementName,
    bankStatementType,
    companyContractName,
    companyContractType,
    companyAddressProveName,
    companyAddressProveType,
    inviteCode,
    storeLink,
    averageBilling,
    categories,
    gatewayName,
    currentEcommerce,
    howMeet,
    isSameAddress,
    hasMonthlyIncoming,
    hasPromotionalTax
  ]);

  const nextStep = useCallback(
    (param) => {
      let nextStepVal = 0;

      if (typeof param === 'number') {
        nextStepVal = param;
      } else {
        nextStepVal = step !== null ? step + 1 : 1;
      }
      localStorage.setItem('step', nextStepVal.toString());
      setStep(nextStepVal);
    },
    [step],
  );

  const previousStep = useCallback(
    (param) => {
      let previousStepVal = 0;
      if (typeof param === 'number') {
        previousStepVal = param;
      } else {
        previousStepVal = step > 1 ? step - 1 : 1;
      }

      localStorage.setItem('step', previousStepVal.toString());
      setStep(previousStepVal);
    },
    [step],
  );

  return {
    step,
    setStep,
    nextStep,
    previousStep,
    fullname,
    setFullname,
    email,
    setEmail,
    phone,
    setPhone,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    cpf,
    setCpf,
    cnpj,
    protocol,
    setProtocol,
    setCnpj,
    userZipcode,
    setUserZipcode,
    userCity,
    setUserCity,
    userState,
    setUserState,
    userStreet,
    setUserStreet,
    userStreetNumber,
    setUserStreetNumber,
    userComplementNumber,
    setUserComplementNumber,
    companyZipcode,
    setCompanyZipcode,
    companyCity,
    setCompanyCity,
    companyState,
    setCompanyState,
    companyStreet,
    setCompanyStreet,
    companyStreetNumber,
    setCompanyStreetNumber,
    companyComplementNumber,
    setCompanyComplementNumber,
    frontsideDocument,
    setFrontsideDocument,
    backsideDocument,
    setBacksideDocument,
    residenceReceipt,
    setResidenceReceipt,
    isCompanyRegister,
    setIsCompanyRegister,
    bank,
    setBank,
    bankAccountType,
    setBankAccountType,
    bankAgencyNumber,
    setBankAgencyNumber,
    bankAgencyDigitNumber,
    setBankAgencyDigitNumber,
    bankAccountNumber,
    setBankAccountNumber,
    bankAccountDigitNumber,
    setBankAccountDigitNumber,
    companyContractDoc,
    setCompanyContractDoc,
    companyBankStatementDoc,
    setCompanyBankStatementDoc,
    companyAddressReceipt,
    setCompanyAddressReceipt,
    bankStatementDoc,
    setBankStatementDoc,
    useTerms,
    setUseTerms,
    usePrivacy,
    setUsePrivacy,
    userNeighborhood,
    setUserNeighborhood,
    companyNeighborhood,
    setCompanyNeighborhood,
    userCountry,
    setUserCountry,
    userDocumentName,
    setUserDocumentName,
    userDocumentType,
    setUserDocumentType,
    userResidentProveName,
    setUserResidentProveName,
    userResidentProveType,
    setUserResidentProveType,
    bankStatementName,
    setBankStatementName,
    bankStatementType,
    setBankStatementType,
    companyContractName,
    setCompanyContractName,
    companyContractType,
    setCompanyContractType,
    companyAddressProveName,
    setCompanyAddressProveName,
    companyAddressProveType,
    setCompanyAddressProveType,
    inviteCode,
    setInviteCode,
    storeLink,
    setStoreLink,
    averageBilling,
    setAverageBilling,
    categories,
    setCategories,
    gatewayName,
    setGatewayName,
    currentEcommerce,
    setCurrentEcommerce,
    howMeet,
    setHowMeet,
    isSameAddress,
    setIsSameAddress,
    hasMonthlyIncoming,
    setHasMonthlyIncoming,
    hasPromotionalTax,
    setHasPromotionalTax,
  };
}
